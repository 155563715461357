
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class LeadManagement {

    constructor() {

    }


    async getLeadsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/leads/get-leads', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createLeadHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/leads/create-lead', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateLeadHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/leads/update-lead', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteLeadHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/leads/delete-lead', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default LeadManagement;