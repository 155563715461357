


import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import RoleManagementHandler from '../../Handlers/RoleManagement/RoleManagement';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import Default_Roles from './defaultRoles.json'

const AddNewRole = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const roleManagement = new RoleManagementHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--role_managment' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (type == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })
            if (type == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [roleTitle, setRoleTitle] = useState('')
    const [roleAccess, setRoleAccess] = useState([...Default_Roles.roles])

    const HandleUpdateRoleFeature = (role_idx, feature_idx, value, key, has_view_setting = false) => {

        if (
            role_idx == undefined
            || feature_idx == undefined
            || value == undefined
            || !key
        ) return

        let _user_roles = [...roleAccess]



        if (key == '_view' && has_view_setting) {

            _user_roles[role_idx].features[feature_idx][key] = value != 'no_access' ? 1 : 0
            _user_roles[role_idx].features[feature_idx].selected_view_settings_value = value
        } else {
            _user_roles[role_idx].features[feature_idx][key] = value ? 1 : 0
        }

        if (key == '_view' && has_view_setting && value == 'no_access') {

            _user_roles[role_idx].features[feature_idx]._view = 0
            _user_roles[role_idx].features[feature_idx]._add = 0
            _user_roles[role_idx].features[feature_idx]._edit = 0
            _user_roles[role_idx].features[feature_idx]._delete = 0
        }
        else if (key == '_view' && value == 0) {

            _user_roles[role_idx].features[feature_idx]._add = 0
            _user_roles[role_idx].features[feature_idx]._edit = 0
            _user_roles[role_idx].features[feature_idx]._delete = 0
        }
        else if (key == '_add') {

            if (value == 1) _user_roles[role_idx].features[feature_idx]._view = 1
            else {
                _user_roles[role_idx].features[feature_idx]._edit = 0
                _user_roles[role_idx].features[feature_idx]._delete = 0
            }
        }
        else if (key == '_edit') {

            if (value == 1) {
                _user_roles[role_idx].features[feature_idx]._view = 1
                _user_roles[role_idx].features[feature_idx]._add = 1
            }
            else {
                _user_roles[role_idx].features[feature_idx]._delete = 0
            }
        }
        else if (key == '_delete') {

            if (value == 1) {
                _user_roles[role_idx].features[feature_idx]._view = 1
                _user_roles[role_idx].features[feature_idx]._add = 1
                _user_roles[role_idx].features[feature_idx]._edit = 1
            }

        }

        setRoleAccess(_user_roles)
    }

    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {
        e.preventDefault()


        let role_access = []

        roleAccess.forEach(access => {

            let access_key = access.id

            let features = access.features.map(feature => {

                let feature_key = [access_key, feature.id].join('--')

                return {
                    feature_name: feature.label,
                    feature_key: feature_key,

                    has_view_setting: feature.has_view_setting,
                    selected_view_settings_value: feature.selected_view_settings_value,
                    view_settings_options: feature.view_settings_options,

                    has_view: feature.has_view,
                    has_add: feature.has_add,
                    has_edit: feature.has_edit,
                    has_delete: feature.has_delete,
                    _view: feature._view,
                    _create: feature._add,
                    _delete: feature._delete,
                    _edit: feature._edit
                }
            })

            role_access.push(...features)

        })


        let updateta = {
            name: roleTitle,
            role_access: role_access,
            role_category: 0,
            type: 1
        }

        let response = await roleManagement.createRoleHandler(updateta)

        if (response.success) {
            callback()
            close(type)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Creating Role`)
        }


    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <button
                            className={`action-btn action-${type}`}
                            type='submit'
                        >
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.save }}></div>
                            <div className="label">Save</div>
                        </button>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Role Creation",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <form onSubmit={HandlePopupSubmit} className="side-popup-container  role-management-side-popup-container center-popup-container project-roleManagement-main">
                    <Popup_Header />
                    <div className="clients-popup-content">
                        <div className="content-items">
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "clients-name",
                                        value: roleTitle,
                                        placeholder: '',
                                        setValue: (value) => setRoleTitle(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Role Title",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="detials-role-sections">
                            {roleAccess?.map((role_access, idx) => (
                                <div key={`role-access-${role_access.id}`} className="role-section">
                                    <div className="role-section-header">
                                        <div className="header-item header-item-name">{role_access.label}</div>
                                        <div className="header-item header-item-menu">View</div>
                                        <div className="header-item header-item-menu">Add</div>
                                        <div className="header-item header-item-menu">Edit</div>
                                        <div className="header-item header-item-menu">Delete</div>
                                    </div>
                                    <div className="role-section-items">
                                        {role_access?.features.map((feature, idxx) => (

                                            <div key={`role-access-feature-${feature.id}`} className="role-section-item">


                                                <div
                                                    className={`section-item section-item-name ${feature.has_view_setting ? 'section-settings-item-name' : ''}`}
                                                    data-label="Menu:"
                                                >{feature.label}</div>

                                                {feature.has_view_setting ?

                                                    <div className="section-item-dropdown-settings" data-label="View" >
                                                        <SelectInput
                                                            props={{
                                                                id: "role_access-feature-view",
                                                                value: feature.selected_view_settings_value,
                                                                placeholder: 'Select View Setting',
                                                                setValue: (value) => {
                                                                    HandleUpdateRoleFeature(idx, idxx, value, '_view', feature.has_view_setting)
                                                                },
                                                                isIcon: false,
                                                                isLabel: false,
                                                                isRequired: true,
                                                                label: "View",
                                                                options: feature.view_settings_options
                                                            }} />
                                                    </div>
                                                    :

                                                    <div className="section-item section-item-menu" data-label="View">

                                                        {feature.has_view == -1 ? '' :

                                                            <RadioInput props={{
                                                                value: feature._view ? true : false,
                                                                isIcon: false,
                                                                icon: "",
                                                                inputType: "checkbox",
                                                                name: "roleManagement-view-radio",
                                                                setValue: (value) => {
                                                                    HandleUpdateRoleFeature(idx, idxx, value, '_view')
                                                                }
                                                            }} />
                                                        }

                                                    </div>
                                                }
                                                <div className="section-item section-item-menu" data-label="Add">
                                                    {feature.has_add == -1 ? '' :
                                                        <RadioInput props={{
                                                            value: feature._add ? true : false,
                                                            isIcon: false,
                                                            icon: "",
                                                            inputType: "checkbox",
                                                            name: "roleManagement-view-radio",
                                                            setValue: (value) => {
                                                                HandleUpdateRoleFeature(idx, idxx, value, '_add')
                                                            }
                                                        }} />
                                                    }
                                                </div>
                                                <div className="section-item section-item-menu" data-label="Edit">
                                                    {feature.has_edit == -1 ? '' :
                                                        <RadioInput props={{
                                                            value: feature._edit ? true : false,
                                                            isIcon: false,
                                                            icon: "",
                                                            inputType: "checkbox",
                                                            name: "roleManagement-view-radio",
                                                            setValue: (value) => {
                                                                HandleUpdateRoleFeature(idx, idxx, value, '_edit')
                                                            }
                                                        }} />
                                                    }
                                                </div>
                                                <div className="section-item section-item-menu" data-label="Delete">
                                                    {feature.has_delete == -1 ? '' :
                                                        <RadioInput props={{
                                                            value: feature._delete ? true : false,
                                                            isIcon: false,
                                                            icon: "",
                                                            inputType: "checkbox",
                                                            name: "roleManagement-view-radio",
                                                            setValue: (value) => {
                                                                HandleUpdateRoleFeature(idx, idxx, value, '_delete')
                                                            }
                                                        }} />
                                                    }
                                                </div>
                                            </div>

                                        ))}

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Popup_Footer />
                </form>
            </div>
        </>
    )
}

const RoleManagement = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const roleManagement = new RoleManagementHandler()


    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--role_managment' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [selectedRole_Idx, setSelectedRole_Idx] = useState(undefined)
    const [selectedRole, setSelectedRole] = useState(undefined)

    const Default_Role_Detials = [...Default_Roles.roles]

    const [user_roles_Orginal, setUser_roles_Orginal] = useState([])
    const [user_roles, setUser_roles] = useState([])
    const [updated_user_roles, setUpdated_user_roles] = useState([])

    const HandleSelectRole = (role, Idx) => {
        setSelectedRole(role)
        setSelectedRole_Idx(Idx)
    }
    const HandleDeleteRole = async (role, Idx) => {
        if (!has_edit_access || !has_delete_access) return
        let filters = {
            id: role.id
        }

        setIsLoading(true)

        let response = await roleManagement.deleteRoleHandler(filters)

        setIsLoading(false)

        if (response && response.success) {
            getRolesDetials()
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Updating Role`)
        }
    }

    const HandleUpdateRoleFeature = (role_idx, feature_idx, value, key, has_view_setting = false) => {

        if (
            !selectedRole
            || role_idx == undefined
            || feature_idx == undefined
            || value == undefined
            || !key
        ) return

        let _user_roles = [...user_roles]


        if (key == '_view' && has_view_setting) {

            _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx][key] = value != 'no_access' ? 1 : 0
            _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx].selected_view_settings_value = value
        } else {
            _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx][key] = value ? 1 : 0
        }

        if (key == '_view' && has_view_setting && value == 'no_access') {

            _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._view = 0
            _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._add = 0
            _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._edit = 0
            _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._delete = 0
        }
        else if (key == '_view' && value == 0) {

            _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._add = 0
            _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._edit = 0
            _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._delete = 0
        }
        else if (key == '_add') {

            if (value == 1) _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._view = 1
            else {
                _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._edit = 0
                _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._delete = 0
            }
        }
        else if (key == '_edit') {

            if (value == 1) {
                _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._view = 1
                _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._add = 1
            }
            else {
                _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._delete = 0
            }
        }
        else if (key == '_delete') {

            if (value == 1) {
                _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._view = 1
                _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._add = 1
                _user_roles[selectedRole_Idx].access[role_idx].features[feature_idx]._edit = 1
            }

        }



        setUser_roles(_user_roles)

        if (!updated_user_roles.includes(_user_roles[selectedRole_Idx].id)) {
            let _updated_user_roles = [...updated_user_roles]
            _updated_user_roles.push(_user_roles[selectedRole_Idx].id)
            setUpdated_user_roles(_updated_user_roles)
        }

    }

    const HandleSavehandle = async (e) => {
        e.preventDefault()

        // if (!has_edit_access) return

        if (user_roles == user_roles_Orginal) return

        setIsLoading(true)

        let roles = user_roles.filter(role => updated_user_roles.includes(role.id)).map(role => {

            let role_access = []

            role.access.forEach(access => {

                let access_key = access.id

                let features = access.features.map(feature => {

                    let feature_key = [access_key, feature.id].join('--')

                    return {
                        id: feature._id,
                        feature_name: feature.label,
                        feature_key: feature_key,

                        has_view_setting: feature.has_view_setting == '1' ? true : false,
                        selected_view_settings_value: feature.selected_view_settings_value,
                        view_settings_options: feature.view_settings_options,

                        has_view: feature.has_view,
                        has_add: feature.has_add,
                        has_edit: feature.has_edit,
                        has_delete: feature.has_delete,

                        _view: feature._view,
                        _create: feature._add,
                        _delete: feature._delete,
                        _edit: feature._edit
                    }
                })

                role_access.push(...features)
            })

            return {
                id: role.id,
                name: role.label,
                description: role.description,
                role_access: role_access
            }


        })



        let response = await roleManagement.updateRolesHandler({ roles })

        setIsLoading(false)

        if (response && response.success) {
            getRolesDetials()
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Updating Role`)
        }
    }

    const HandleAddRole = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/add-role`)
    }


    const CloseAddNewRole = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()

        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)
    }

    const getRolesDetials = async () => {

        let response = await roleManagement.getRolesHandler()

        if (response && response.success) {

            let { roles } = response.data

            if (!Array.isArray(roles)) return

            let role_detials = roles.map((role, idx) => {


                let role_access = Default_Role_Detials.map((access, idxx) => {

                    let features = role.role_access?.filter(r_access => r_access.feature_key.split('--')[0] == access.id).map(access => {

                        return {
                            _id: access.id,
                            id: access.feature_key.split('--')[1],
                            label: access.feature_name,

                            has_view_setting: access.has_view_setting == '1' ? true : false,
                            selected_view_settings_value: access.selected_view_settings_value,
                            view_settings_options: access.view_settings_options,

                            has_view: access.has_view,
                            has_add: access.has_add,
                            has_edit: access.has_edit,
                            has_delete: access.has_delete,

                            _view: access._view,
                            _add: access._create,
                            _edit: access._edit,
                            _delete: access._delete,
                        }
                    })

                    return {
                        id: access.id,
                        label: access.label,
                        features: features
                    }

                })

                return {
                    id: role.id,
                    label: role.name,
                    description: role.description || "",
                    isDescription: role.description != null,
                    access: role_access
                }

            })

            setUser_roles_Orginal(role_detials)
            setUser_roles(role_detials)
            HandleSelectRole(role_detials[0], 0)

            dispatch(updateState({
                type: "SET_ROLES",
                payload: { role_management_access: role_detials }
            }))

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Fetching Role Detials, Please try agai`)
        }
    }

    useEffect(() => {
        getRolesDetials()
    }, [])

    return (
        <>

            <Routes >
                <Route exact path='/add-role' element={
                    <AddNewRole props={{
                        type: "create",
                        heading: "Add role",
                        callback: getRolesDetials,
                        close: CloseAddNewRole
                    }} />}></Route>
            </Routes>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Role Creation",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-roleManagement-main">
                <div className="roleManagement-panel-content">

                    <div className="panel-content-header">
                        <div className="title">Roles & Permissions</div>
                    </div>
                    <div className="panel-content-sections">
                        <div className="content-roles-toggle-main">
                            {has_add_access ?
                                <div
                                    className="content-roles-button"
                                    onClick={(e) => HandleAddRole()}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_active }}></div>
                                    <div className="label">Add new role</div>
                                </div>
                                : ''}
                            <div className="content-roles-searchbar">
                                <TextInput
                                    props={{
                                        id: "field_settings-name",
                                        value: '',
                                        placeholder: 'Search for a role',
                                        setValue: (value) => { },
                                        isIcon: true,
                                        isLeft: false,
                                        icon: Icons.general.search,
                                        isLabel: false,
                                        isRequired: true,
                                        label: "Name",
                                    }}
                                />
                            </div>
                            <div className="content-roles-toggle-items">
                                {
                                    user_roles?.map((role, idx) => (
                                        <div
                                            key={`role-toggle-${role.id}`}
                                            className={`content-roles-toggle-item ${idx == selectedRole_Idx ? 'content-roles-toggle-item-active' : ''}`}
                                            onClick={(e) => HandleSelectRole(role, idx)}
                                        >
                                            {has_delete_access ?
                                                <div className="content-roles-toggle-delete"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.delete }}
                                                    onClick={(e) => HandleDeleteRole(role, idx)}
                                                ></div>
                                                : ''}
                                            {role.label}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        {selectedRole ?
                            <form
                                className="content-roles-result-main"
                                id={`role-result-${selectedRole.id}`}
                                onSubmit={HandleSavehandle}
                            >
                                <div className="roles-result-detials">
                                    <div className="detials-header">
                                        <div className="title">{selectedRole.label} PERMISSIONS</div>
                                    </div>
                                    <div className="detials-role-sections">
                                        {selectedRole.access.map((role_access, idx) => (
                                            <div key={`role-access-${role_access.id}`} className="role-section">
                                                <div className="role-section-header">
                                                    <div className="header-item header-item-name">{role_access.label}</div>
                                                    <div className="header-item header-item-menu">View</div>
                                                    <div className="header-item header-item-menu">Add</div>
                                                    <div className="header-item header-item-menu">Edit</div>
                                                    <div className="header-item header-item-menu">Delete</div>
                                                </div>
                                                <div className="role-section-items">
                                                    {role_access?.features.map((feature, idxx) => (

                                                        <div key={`role-access-feature-${feature.id}`} className="role-section-item">

                                                            <div
                                                                className={`section-item section-item-name ${feature.has_view_setting ? 'section-settings-item-name' : ''}`}
                                                                data-label="Menu:"
                                                            >{feature.label}</div>

                                                            {feature.has_view_setting ?

                                                                <div className="section-item-dropdown-settings" data-label="View" >
                                                                    <SelectInput
                                                                        props={{
                                                                            id: "role_access-feature-view",
                                                                            value: feature.selected_view_settings_value,
                                                                            placeholder: 'Select View Setting',
                                                                            setValue: (value) => {
                                                                                HandleUpdateRoleFeature(idx, idxx, value, '_view', feature.has_view_setting)
                                                                            },
                                                                            isIcon: false,
                                                                            isLabel: false,
                                                                            isRequired: true,
                                                                            label: "View",
                                                                            options: feature.view_settings_options
                                                                        }} />
                                                                </div>
                                                                :
                                                                <div className="section-item section-item-menu" data-label="View">

                                                                    {feature.has_view == -1 ? '' :

                                                                        <RadioInput props={{
                                                                            value: feature._view ? true : false,
                                                                            isIcon: false,
                                                                            icon: "",
                                                                            inputType: "checkbox",
                                                                            name: "roleManagement-view-radio",
                                                                            setValue: (value) => {
                                                                                HandleUpdateRoleFeature(idx, idxx, value, '_view')
                                                                            }
                                                                        }} />
                                                                    }

                                                                </div>
                                                            }
                                                            <div className="section-item section-item-menu" data-label="Add">
                                                                {feature.has_add == -1 ? '' :
                                                                    <RadioInput props={{
                                                                        id: 'role_access-feature-add',
                                                                        value: feature._add == "1",
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "checkbox",
                                                                        name: "roleManagement-view-radio",
                                                                        setValue: (value) => {
                                                                            HandleUpdateRoleFeature(idx, idxx, value, '_add')
                                                                        }
                                                                    }} />
                                                                }
                                                            </div>
                                                            <div className="section-item section-item-menu" data-label="Edit">
                                                                {feature.has_edit == -1 ? '' :
                                                                    <RadioInput props={{
                                                                        id: 'role_access-feature-edit',
                                                                        value: feature._edit == "1",
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "checkbox",
                                                                        name: "roleManagement-view-radio",
                                                                        setValue: (value) => {
                                                                            HandleUpdateRoleFeature(idx, idxx, value, '_edit')
                                                                        }
                                                                    }} />
                                                                }
                                                            </div>
                                                            <div className="section-item section-item-menu" data-label="Delete">
                                                                {feature.has_delete == -1 ? '' :
                                                                    <RadioInput props={{
                                                                        id: 'role_access-feature-delete',
                                                                        value: feature._delete == "1",
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "checkbox",
                                                                        name: "roleManagement-view-radio",
                                                                        setValue: (value) => {
                                                                            HandleUpdateRoleFeature(idx, idxx, value, '_delete')
                                                                        }
                                                                    }} />
                                                                }
                                                            </div>
                                                        </div>

                                                    ))}

                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                <div className="roles-result-fotter">
                                    <div className="fotter-buttons">
                                        <button type='submit' className="fotter-button-save">
                                            <div
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.save }}
                                            ></div>
                                            <div className="label">Save Changes</div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            : ''}
                    </div>
                </div>
            </div >
        </>
    )
}

export default RoleManagement;