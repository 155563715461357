
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class StockItems {

    constructor() {

    }



    async getSystemNotificationsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/system-notification/get-notifications', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getSystemNotificationPublickKeyHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/system-notification/get-key', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async subscribeSystemNotificationHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/system-notification/subscribe-notification', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }




}

export default StockItems;