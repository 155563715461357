

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../../redux/store'
import StoreHandler from '../../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../../redux/action/userAction'

import { PDFExport, PDFExportProps, savePDF } from '@progress/kendo-react-pdf';
import { PDFViewer, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import ReverseProxy from '../../../config/reverseProxy'
import QuotationHandler from '../../../Handlers/Quotation/Quotation';
import Icons from '../../../assets/Icons'
import Images from '../../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../../components/Inputs'
import Utils from '../../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QuotationPreviewTemplates = ({ props }) => {

    const { quotation_details, catalogue_materials } = props

    //Basic Detials

    const [id, setId] = useState('')
    const [QuotationNo, setQuotationNo] = useState('')
    const [SiteName, setSiteName] = useState('')
    const [SiteLocation, setSiteLocation] = useState('')
    const [Date, setDate] = useState(Utils._getLocalDate())
    const [Client_id, setClient_id] = useState('')
    const [Client_name, setClient_name] = useState('')
    const [Assign_to_id, setAssign_to_id] = useState('')
    const [Assign_to_name, setAssign_to_name] = useState('')
    const [Status, setStatus] = useState('')

    const [Template_id, setTemplate_id] = useState('')
    const [Template_name, setTemplate_name] = useState('')

    const [QuotationMeasurements, setQuotationMeasurements] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [WorkPatternId, setWorkPatternId] = useState("")
    const [QuotationSummaryItems, setQuotationSummaryItems] = useState([])
    const pdfRef = useRef()

    useEffect(() => {
        if (quotation_details) {

            const LoadTemplate = async (quotation_details) => {

                let {
                    sno,
                    quotation_no,
                    site_name,
                    site_location,
                    date,
                    status,
                    client_id,
                    client_name,
                    assign_to_id,
                    assign_to_name,
                    terms_conditions,
                    work_pattern,
                    measurements,
                    summary_items,
                    template_id,
                    template_name,
                } = quotation_details

                setQuotationNo(quotation_no)
                setSiteName(site_name)
                setSiteLocation(site_location)
                setDate(date)
                setStatus(status)
                setClient_id(client_id)
                setClient_name(client_name)
                setAssign_to_id(assign_to_id)
                setAssign_to_name(assign_to_name)
                setTermsConditionId(terms_conditions)
                setWorkPatternId(work_pattern)

                setTemplate_id(template_id)
                setTemplate_name(template_name)

                measurements = measurements.map((section, index) => {

                    let selected_material = catalogue_materials.filter(m => m.section_id == section.section_id && m.quality_id == section.quality_id)

                    section.material_specifications = selected_material || []

                    section.section_unit_total = section.section_units.reduce((prev, unit, index) => {

                        let length = parseFloat(unit.length || 0)
                        let height = parseFloat(unit.height || 0)
                        let depth = parseFloat(unit.depth || 0)
                        let area = parseFloat(unit.area || 0)
                        let amount = parseFloat(unit.amount || 0)

                        return {
                            total_length: prev.total_length + length,
                            total_height: prev.total_height + height,
                            total_depth: prev.total_depth + depth,
                            total_area: prev.total_area + area,
                            total_amount: prev.total_amount + amount
                        }

                    }, {
                        total_length: 0,
                        total_height: 0,
                        total_depth: 0,
                        total_area: 0,
                        total_amount: 0
                    })

                    return section


                })

                console.log(measurements, 'measurements');

                setQuotationMeasurements(measurements)
                setQuotationSummaryItems(summary_items)

            }

            LoadTemplate(quotation_details)
        }
    }, [])

    const [layoutsettingsItems, setLayoutsettingsItems] = useState([
        {
            id: 'material_specifications',
            enabled: true,
            label: "Material Specifications",
            icon: Icons.general.measurement
        },
        {
            id: 'unit_table_length',
            enabled: true,
            label: "Unit Table Length",
            icon: Icons.general.measurement
        },
        {
            id: 'unit_table_height',
            enabled: true,
            label: "Unit Table Height",
            icon: Icons.general.measurement
        },
        {
            id: 'unit_table_depth',
            enabled: true,
            label: "Unit Table Depth",
            icon: Icons.general.measurement
        },
        {
            id: 'unit_table_area',
            enabled: true,
            label: "Unit Table Area",
            icon: Icons.general.measurement
        },

        {
            id: 'unit_table_rate',
            enabled: true,
            label: "Unit Table Rate",
            icon: Icons.general.measurement
        },
        {
            id: 'unit_table_Amount',
            enabled: true,
            label: "Unit Table Amount",
            icon: Icons.general.measurement
        },
        {
            id: 'unit_quotation_notes',
            enabled: true,
            label: "Notes",
            icon: Icons.general.description
        },
        {
            id: 'unit_quotation_work_pattern',
            enabled: true,
            label: "Work Pattern",
            icon: Icons.general.work
        },
        {
            id: 'general_work_pattern',
            enabled: true,
            label: "General Work Pattern",
            icon: Icons.general.work
        },
        {
            id: 'general_quotation_terms_conditions',
            enabled: true,
            label: "Terms & Conditions",
            icon: Icons.general.description
        },


    ])

    const HandleLayoutSettings = (id, value) => {

        let _layoutsettingsItems = [...layoutsettingsItems]

        _layoutsettingsItems = _layoutsettingsItems.map(item => {
            if (item.id == id) item.enabled = value
            return item
        })

        setLayoutsettingsItems(_layoutsettingsItems)
    }

    const HandleDownloadBtn = () => {

        pdfRef.current.save();
        // savePDF(pdfRef.current, { paperSize: "A4" });
    }

    const activeLayoutSettingsIds = layoutsettingsItems.filter(ls => ls.enabled).map(ls => ls.id)


    return (
        <>

            <div className="quotation-preview-main">
                <div className="quotation-preview-settings-main">
                    <div className="settings-header">
                        <div className="title">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.layout_settings }}
                            ></div>
                            <div className="label">Layout Settings</div>
                        </div>
                    </div>
                    <div className="settings-items">
                        {layoutsettingsItems.map((item, index) => (

                            <div
                                className="settings-item"
                                key={`layout-settings-item-${index}`}
                            >
                                <div className="item-header">
                                    <div className="icon"
                                        dangerouslySetInnerHTML={{ __html: item.icon }}
                                    ></div>
                                    <div className="label">{item.label}</div>
                                </div>
                                <div className="item-toggle">
                                    <RadioInput props={{
                                        id: item.id,
                                        value: true,
                                        isIcon: false,
                                        icon: "",
                                        inputType: "toggle",
                                        name: "",
                                        setValue: (value) => HandleLayoutSettings(item.id, value),
                                    }} />
                                </div>
                            </div>

                        ))}

                    </div>
                    <div className="settings-actions">
                        <div
                            className="action-btn"
                            onClick={HandleDownloadBtn}
                        >Download PDF</div>
                    </div>

                </div>

                <div className="quotation-preview-content-main">

                    <PDFExport
                        ref={pdfRef}
                        author='CivilATOR'
                        fileName={`${String(SiteName).split(' ').join('_')}_Quotation_${Utils.getLocalFullDateBYFormat()}`}
                        margin={0}
                        paperSize="A4"
                        scale={0.8}
                        landscape={true}
                        repeatHeaders={true}
                    >
                        <div className="quotation-preview-content-container">
                            <div className="preview-page-header"
                                dangerouslySetInnerHTML={{ __html: Icons.quotation_template_banners.default_top_banner }}
                            ></div>

                            <div className="preview-header">
                                <div className="logo">
                                    <img src={Images.logo_large} />
                                </div>
                                <div className="header-details">
                                    <div className="header-left">
                                        <div className="header-left-item">
                                            <div className="label">CLIENT: </div>
                                            <div className="value">{Client_name}</div>
                                        </div>
                                        <div className="header-left-item">
                                            <div className="label">LOCATION: </div>
                                            <div className="value">{SiteLocation}</div>
                                        </div>
                                    </div>
                                    <div className="header-right">
                                        <div className="header-right-item">
                                            <div className="label">DATE: </div>
                                            <div className="value">{Date}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="header-title">{SiteName}</div>
                            </div>
                            <div className="preview-body">
                                <div className="preview-body-sections">
                                    {QuotationMeasurements.map((section, index) => (
                                        <div
                                            className="preview-body-section"
                                            key={`preview-section-${section.id}`}
                                        >
                                            <div className="preview-section-title">{section.section_name}</div>
                                            {activeLayoutSettingsIds.includes('material_specifications') ?
                                                <div className="preview-section-materials">
                                                    {section.material_specifications.map((material, index) => (

                                                        <div
                                                            className="preview-section-material"
                                                            key={`preview-material-${material.sno}`}
                                                        >
                                                            <div className="title">{material.name}</div>
                                                            <div className="material-items">
                                                                <div className="material-item-labels">
                                                                    {material.material_specifications.map((spec, index) => (
                                                                        <div
                                                                            className="label"
                                                                            key={`material-spec-label-${index}`}
                                                                        >{index + 1}.{spec.label}</div>
                                                                    ))}

                                                                </div>
                                                                <div className="material-item-values">
                                                                    {material.material_specifications.map((spec, index) => (
                                                                        <div
                                                                            className="value"
                                                                            key={`material-spec-value-${index}`}
                                                                        >{spec.value}</div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                                : ''}
                                            <div className="preview-section-units">

                                                {section.section_id == 'accessories' ?

                                                    <table className='section-units-table'>
                                                        <thead className='tabel-header'>
                                                            <tr>
                                                                <th>SI</th>
                                                                <th>Item Image</th>
                                                                <th>Item Name</th>
                                                                <th>Brand</th>
                                                                <th>MRP(<span className='preview-currency-small' dangerouslySetInnerHTML={{ __html: Icons.general.inr }}></span>)</th>
                                                                <th>Quandity</th>
                                                                <th>Discount(%)</th>
                                                                <th>Amount</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabel-body'>
                                                            {section.section_accessories.map((acc, index) => (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        <div className="tabel-td-image">
                                                                            <img src={Utils.loadBase64_Image(acc.attachment)} />
                                                                        </div>
                                                                    </td>
                                                                    <td style={{
                                                                        minWidth: '200px',
                                                                    }}>{`${acc.code}-` || ""}{acc.name}</td>

                                                                    <td>{acc.brand}</td>
                                                                    <td><span className='preview-currency-small' dangerouslySetInnerHTML={{ __html: Icons.general.inr }}></span>{acc.price}</td>
                                                                    <td>{acc.quandity}</td>
                                                                    <td>{acc.discount || 0}%</td>
                                                                    <td><span className='preview-currency-small' dangerouslySetInnerHTML={{ __html: Icons.general.inr }}></span>{acc.amount}</td>
                                                                </tr>
                                                            ))}

                                                            <tr>
                                                                <td></td>
                                                                <td className='section-table-subtotal-label'>SUB-TOTAL</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td className='section-table-subtotal-value'><span className='preview-currency-small' dangerouslySetInnerHTML={{ __html: Icons.general.inr }}></span>{section.total_amount ? parseFloat(section.total_amount || 0).toFixed(2) : ''}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    :

                                                    <table className='section-units-table'>
                                                        <thead className='tabel-header'>
                                                            <tr>
                                                                <th>SI</th>
                                                                <th>PARTICULAR</th>
                                                                {activeLayoutSettingsIds.includes('unit_table_length') ? <th>L</th> : ''}
                                                                {activeLayoutSettingsIds.includes('unit_table_height') ? <th>H</th> : ''}
                                                                {activeLayoutSettingsIds.includes('unit_table_depth') ? <th>D</th> : ''}
                                                                {activeLayoutSettingsIds.includes('unit_table_area') ? <th>AREA</th> : ''}
                                                                {activeLayoutSettingsIds.includes('unit_table_rate') ? <th>RATE</th> : ''}
                                                                {activeLayoutSettingsIds.includes('unit_table_amount') ? <th>AMOUNT</th> : ''}

                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabel-body'>
                                                            {section.section_units.map((unit, index) => (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td style={{
                                                                        minWidth: '200px',
                                                                    }}>{unit.unit_name}</td>
                                                                    {activeLayoutSettingsIds.includes('unit_table_length') ? <td>{unit.length ? parseFloat(unit.length || 0).toFixed(1) : ''}</td> : ''}
                                                                    {activeLayoutSettingsIds.includes('unit_table_height') ? <td>{unit.height ? parseFloat(unit.height || 0).toFixed(1) : ''}</td> : ''}
                                                                    {activeLayoutSettingsIds.includes('unit_table_depth') ? <td>{unit.depth ? parseFloat(unit.depth || 0).toFixed(1) : ''}</td> : ''}
                                                                    {activeLayoutSettingsIds.includes('unit_table_area') ? <td>{unit.area ? parseFloat(unit.area || 0).toFixed(1) : ''}</td> : ''}
                                                                    {activeLayoutSettingsIds.includes('unit_table_rate') ? <td><span className='preview-currency-small' dangerouslySetInnerHTML={{ __html: Icons.general.inr }}></span>{unit.rate ? parseFloat(unit.rate || 0).toFixed(1) : ''}</td> : ''}
                                                                    {activeLayoutSettingsIds.includes('unit_table_amount') ? <td><span className='preview-currency-small' dangerouslySetInnerHTML={{ __html: Icons.general.inr }}></span>{unit.amount ? parseFloat(unit.amount || 0).toFixed(2) : ''}</td> : ''}
                                                                </tr>
                                                            ))}

                                                            <tr>
                                                                <td></td>
                                                                <td className='section-table-subtotal-label'>SUB-TOTAL</td>
                                                                {activeLayoutSettingsIds.includes('unit_table_length') ? <td></td> : ''}
                                                                {activeLayoutSettingsIds.includes('unit_table_height') ? <td></td> : ''}
                                                                {activeLayoutSettingsIds.includes('unit_table_depth') ? <td></td> : ''}

                                                                {activeLayoutSettingsIds.includes('unit_table_area') ?
                                                                    <td className='section-table-subtotal-value'>
                                                                        {
                                                                            section.section_unit_total.total_area ?
                                                                                parseFloat(section.section_unit_total.total_area || 0).toFixed(1)
                                                                                : ''}
                                                                    </td>
                                                                    : ''}
                                                                {activeLayoutSettingsIds.includes('unit_table_rate') ? <td></td> : ''}
                                                                {activeLayoutSettingsIds.includes('unit_table_amount') ?
                                                                    <td className='section-table-subtotal-value'>
                                                                        <span className='preview-currency-small' dangerouslySetInnerHTML={{ __html: Icons.general.inr }}></span>{
                                                                            section.section_unit_total.total_amount ?
                                                                                parseFloat(section.section_unit_total.total_amount || 0).toFixed(2)
                                                                                : ''}
                                                                    </td>
                                                                    : ''}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                            {activeLayoutSettingsIds.includes('unit_quotation_notes') && section.notes ?
                                                <div className="preview-section-description">
                                                    <div className="title">NOTES:</div>
                                                    <div
                                                        className="content"
                                                        dangerouslySetInnerHTML={{ __html: section.notes }}
                                                    ></div>
                                                </div>
                                                : ''}
                                            {activeLayoutSettingsIds.includes('unit_quotation_work_pattern') && section.work_pattern ?
                                                <div className="preview-section-description">
                                                    <div className="title">{section.unit_name} WORK PATTERN:</div>
                                                    <div
                                                        className="content"
                                                        dangerouslySetInnerHTML={{ __html: section.work_pattern }}
                                                    ></div>
                                                </div>
                                                : ''}

                                        </div>

                                    ))}

                                    <div
                                        className="preview-body-section"
                                    >
                                        <div className="preview-section-title">FEW OTHER OPTIONS TO EXCECUTE INTERIOR </div>
                                        <div className="preview-section-units">
                                            <table className='section-units-table'>
                                                <thead className='tabel-header'>
                                                    <tr>
                                                        <th>SI</th>
                                                        <th>OTHER OPTION</th>
                                                        <th>AMOUNT</th>
                                                        <th>% CHANGE</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tabel-body'>
                                                    {QuotationSummaryItems.map((unit, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{unit.name}</td>
                                                            <td><span className='preview-currency-small' dangerouslySetInnerHTML={{ __html: Icons.general.inr }}></span>{unit.amount ? parseFloat(unit.length || 0).toFixed(1) : ''}</td>
                                                            <td>{unit.change ? parseFloat(unit.height || 0).toFixed(1) : ''}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {activeLayoutSettingsIds.includes('general_work_pattern') && WorkPatternId ?
                                            <div className="preview-section-description">
                                                <div className="title">GENERAL WORK PATTERN:</div>
                                                <div
                                                    className="content"
                                                    dangerouslySetInnerHTML={{ __html: WorkPatternId }}
                                                ></div>
                                            </div>
                                            : ''}
                                        {activeLayoutSettingsIds.includes('general_quotation_terms_conditions') && termsConditionId ?
                                            <div className="preview-section-description">
                                                <div className="title">TERMS & CONDITIONS:</div>
                                                <div
                                                    className="content"
                                                    dangerouslySetInnerHTML={{ __html: termsConditionId }}
                                                ></div>
                                            </div>
                                            : ''}

                                    </div>

                                </div>
                            </div>

                            <div className="preview-page-footer"
                                style={{ display: 'none' }}
                                dangerouslySetInnerHTML={{ __html: Icons.quotation_template_banners.default_bottom_banner }}
                            ></div>

                        </div>
                    </PDFExport>
                </div>

            </div>
        </>
    )

}

export default QuotationPreviewTemplates;