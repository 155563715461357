import Images from '../assets/Images'

// sections: {
//     kitchen: Kitchen,
//     accessories: Accessories,
//     living_hall: Living_hall,
//     bed_room: Bed_room,
//     dining_hall: Dining_hall,
//     false_ceiling: False_ceiling,
//     sitout: Sitout,
//     utility: Utility,
// }

const Sections = [
    {
        value: 'kitchen',
        label: 'Kitchen',
        image: Images.sections.kitchen,
    },
    {
        value: 'accessories',
        label: 'Accessories',
        image: Images.sections.accessories,
    },
    {
        value: 'living_hall',
        label: 'Living Hall',
        image: Images.sections.living_hall,
    },
    {
        value: 'bed_room',
        label: 'Bed Room',
        image: Images.sections.bed_room,
    },
    {
        value: 'dining_hall',
        label: 'Dining Hall',
        image: Images.sections.dining_hall,
    },
    {
        value: 'false_ceiling',
        label: 'False Ceiling',
        image: Images.sections.false_ceiling,
    },
    {
        value: 'sitout',
        label: 'Sitout',
        image: Images.sections.sitout,
    },
    {
        value: 'utility',
        label: 'Utility',
        image: Images.sections.utility,
    }
]


export default Sections