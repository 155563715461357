import React, { useEffect, useState } from 'react'

/* Redux Setup*/
import { Link, useNavigate, Route, Routes, BrowserRouter, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import store from '../redux/store'

import ReverseProxy from '../config/reverseProxy'

import Mainsidebar from './sidebar/Mainsidebar'
import Header from './header/header'

import Dashboard from './dashboard/dashboard'
import Quotations from './quotations/quotations'
import CreateQuotation from './quotations/createQuotation'
import QuotationPreview from './quotations/quotationPreview'
import Catalogue from './catalogue/catalogue'
import Clients from './clients/clients'
import LeadManagement from './leadManagement/leadManagement'
import RoleManagement from './roleManagement/roleManagement'
import TeamManagement from './teamManagement/teamManagement'
import Settings from './settings/settings'
import Notification from './notification/notification'
import RequireAuth from '../hooks/RequireAuth'

const Builder = () => {

    const store = useSelector((store) => store)
    const navigate = useNavigate()

    const HandleEvents = () => {

        const url = window.location.pathname

        let page = url.split('/builder/')

        if (page.length > 1) page = page[1] ? page[1] : 'projects'

        if (page == 'projects') navigate(`${ReverseProxy['proxyUrl']}/app/builder/${page}`)

    }

    useEffect(() => {
        HandleEvents()
    }, [])

    return (

        <div className={`project-section-container`}>
            <Mainsidebar />
            <div className='project-content-main'>
                <Header />
                <div className='project-content-container'>
                    <Routes >

                        <Route element={<RequireAuth allowedRoles={['admin']} />}>

                            <Route exact path='/dashboard/*' element={<Dashboard />}></Route>

                            <Route exact path='/quotations/*' element={<Quotations />}></Route>
                            <Route exact path='/quotations/create/*' element={<CreateQuotation props={{ menuType: 'create' }} />}></Route>
                            <Route exact path='/quotations/edit/:quoteId' element={<CreateQuotation props={{ menuType: 'edit' }} />}></Route>
                            <Route exact path='/quotations/preview/:quoteId' element={<QuotationPreview props={{ menuType: 'edit' }} />}></Route>


                            <Route exact path='/catalogue/*' element={<Catalogue />}></Route>
                            <Route exact path='/clients/*' element={<Clients />}></Route>
                            <Route exact path='/leads/*' element={<LeadManagement />}></Route>

                            <Route exact path='/role-management/*' element={<RoleManagement />}></Route>
                            <Route exact path='/team-management/*' element={<TeamManagement />}></Route>

                            <Route exact path='/settings/*' element={<Settings />}></Route>
                            <Route exact path={`/notifications/*`} element={<Notification />}></Route>
                        </Route>
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default Builder;
