
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Company {

    constructor() {

    }

    async getCompanyProfileHandler() {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/company/get-profile', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateCompanyProfileHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/company/update-profile-detials', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isdefault: false, isToken: true }),
                body: params
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateSystemSettingsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/company/update-system-settings', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateLicense_InsuranceHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/company/update-license-insurance', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isdefault: false, isToken: true }),
                body: params
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }
    }


}

export default Company;