

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import QuotationHandler from '../../Handlers/Quotation/Quotation';
import CatalogueHandler from '../../Handlers/Catalogue/Catalogue';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import QuotationTemplate_Default from './templates/Template_Default'


const QuotationPreview = ({ props }) => {

    const { quoteId } = useParams();

    const { menuType = 'create' } = props

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const quotationHandler = new QuotationHandler()
    const catalogueHandler = new CatalogueHandler()


    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--vendors' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    const [CatalogueMaterials, setCatalogueMaterials] = useState([])

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (menuType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/projects`, { state: { from: location }, replace: true })
            if (menuType == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const storeHandler = new StoreHandler({
        dataStack: ['clients', 'team_users', 'terms_conditions'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const [QuotationDetails, setQuotationDetails] = useState(undefined)

    const HandleBackBtn = () => {

        return navigate('/app/builder/quotations')
    }

    const getCatalougeMaterials = async () => {

        let response = await catalogueHandler.getMaterialsHandler()

        if (response && response.success) {

            let materials = response.data || []

            setCatalogueMaterials(materials)
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Materials, Please try again!`)
        }
    }


    useEffect(() => {
        getCatalougeMaterials()
        if (menuType == 'edit' && quoteId) {

            const LoadTemplate = async (quoteId) => {

                let response = await quotationHandler.getQuotationsHandler({
                    sno: quoteId
                })

                if (response && response.success && Array.isArray(response.data) && response.data.length) {
                    setQuotationDetails(response.data[0])
                }
                else {
                    setWarningAlert(true)
                    setWarningAlertType('error')
                    setApiFailedMessage(`Error in Quotation, Please try again!`)
                }

            }

            LoadTemplate(quoteId)
        }

        LoadStoreData()

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { clients, team_users, settings } = store.user
        }

    }, [store])


    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Vendors",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-projects-main">
                <div className="projects-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleBackBtn()}
                            ></div>
                            <div className="title">Preview Quotation</div>
                        </div>
                    </div>
                    <div className="panel-content-sections">
                        {QuotationDetails ?
                            <QuotationTemplate_Default props={{
                                quotation_details: QuotationDetails,
                                catalogue_materials: CatalogueMaterials
                            }} />
                            : ''}
                    </div>
                </div>
            </div>
        </>
    )

}

export default QuotationPreview;