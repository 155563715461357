import React, { useEffect, useState } from 'react'

/* Redux Setup*/
import { Route, Routes, BrowserRouter, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './assets/css/index.css'

import AppIndex from './AppIndex'

import ReverseProxy from './config/reverseProxy'
import SystemNotification from './Handlers/SystemNotification/SystemNotification'

import Utils from './utils'

const App = () => {

  const store = useSelector((store) => store)

  const systemNotification = new SystemNotification()

  const getSystemNotificationPublicKey = async () => {

    let response = await systemNotification.getSystemNotificationPublickKeyHandler()

    if (response.success) {


      let { publicKey } = response.data

      return publicKey || undefined
    }

    return undefined

  }
  const subscribeSystemNotification = async (subscription) => {

    let update_data = {
      subscription
    }

    let response = await systemNotification.subscribeSystemNotificationHandler(update_data)

    if (response.success) {
    }
    else {
    }

  }

  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;

  }


  const SubscriptionToNotificationManager = async () => {
    if ('Notification' in window && 'serviceWorker' in navigator) {

      Notification.requestPermission().then(async permission => {
        if (permission === 'granted') {

          try {

            const registration = await navigator.serviceWorker.register('/service-worker.js');

            const yourPublicKey = await getSystemNotificationPublicKey();

            if (!yourPublicKey) return console.error('Vapid public key not found');

            const subscribeOptions = {
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(yourPublicKey),
            };

            let existing_subscription = await registration.pushManager.getSubscription()

            if (existing_subscription) {
              await existing_subscription.unsubscribe()
            }

            const subscription = await registration.pushManager.subscribe(subscribeOptions);

            subscribeSystemNotification(subscription)
          } catch (error) {
            console.error('Failed to subscribe:', error.message);
          }
        }
      });
    } else {
      console.error('Push notifications not supported in this browser.');
    }
  }

  useEffect(() => {

    SubscriptionToNotificationManager()

  }, [])

  return (

    <>
      <BrowserRouter>
        <Routes >
          <Route exact path={`${ReverseProxy['proxyUrl']}/app/*`} element={<AppIndex />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;
