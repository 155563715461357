



import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import Builder from '../../Handlers/Builder/Builder';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'


import Profile from './profile'
import Company from './company'
import System from './system'
import NotificationSettings from './notificationSettings'
import License_insurance from './license_insurance'
import Terms_conditions from './terms_conditions'
import Payment_schedule from './payment_schedule'
import Field_settings from './field_settings'
import Finance from './finance'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const Settings = () => {

    const currentURL = window.location.pathname;
    const selectSection = currentURL ? currentURL.split('/settings/')[1] : undefined;

    const { isAdmin, roles_permissions } = useAuth()


    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [selectedMenu, setSelectedMenu] = useState('')

    const SIDE_MENUS = [
        {
            id: 'myprofile',
            key: 'myprofile',
            icon: Icons.general.user,
            label: 'My Profile',
            component: Profile
        },
        {
            id: 'company',
            key: 'company_profile',
            icon: Icons.general.settings,
            label: 'Company',
            component: Company
        },
        {
            id: 'terms_conditions',
            key: 'terms_conditions',
            icon: Icons.general.board_plus,
            label: 'Terms and Conditions',
            component: Terms_conditions
        },
        {
            id: 'payment_schedule',
            key: 'payment_schedule',
            icon: Icons.general.board_plus,
            label: 'Payment Schedule',
            component: Payment_schedule
        },
        {
            id: 'field_settings',
            key: 'field_settings',
            icon: Icons.general.circle_money,
            label: 'Field Settings',
            component: Field_settings
        },
        {
            id: 'finance',
            key: 'finance_settings',
            icon: Icons.general.circle_money,
            label: 'Finance',
            component: Finance
        },


    ]

    const [panel_data, setPanelData] = useState([])

    const LoadSetup = () => {

        if (selectSection) setSelectedMenu(selectSection)
        else if (Array.isArray(panel_data) && panel_data.length) {
            setSelectedMenu(panel_data[0].id)
            navigate(`/app/builder/settings/${panel_data[0].id}`)
        }

    }
    useEffect(() => {

        LoadSetup()
    }, [])

    useEffect(() => {
        LoadSetup()
    }, [panel_data])

    useEffect(() => {
        if (isAdmin) return setPanelData(SIDE_MENUS)

        else if (roles_permissions && Array.isArray(roles_permissions)) {

            let sidebar_items = []

            SIDE_MENUS.forEach((_item, i) => {

                let item = { ..._item }

                if (['myprofile'].includes(_item.key)) sidebar_items.push(item)

                else if (item.isSubMenus && Array.isArray(item.submenus)) {

                    let submenus = []

                    item.submenus.forEach((subitem, j) => {

                        let isAllow = roles_permissions.some((role) => {

                            let feature_key = String(role.feature_key).split('--')[1]

                            return feature_key == subitem.key && role._view == 1
                        })


                        if (isAllow) submenus.push(subitem)

                    })

                    if (submenus.length) {
                        item.submenus = submenus
                        sidebar_items.push(item)
                    }
                }

                else {


                    let isAllow = roles_permissions.some((role) => {

                        let feature_key = String(role.feature_key).split('--')[1]

                        return feature_key == item.key && role._view == 1
                    })

                    if (isAllow) sidebar_items.push(item)
                }



            })

            setPanelData(sidebar_items)
        }



    }, [store.user?.roles_permissions])

    return (
        <div className="project-settings-main">

            <div className="settings-content-main">
                <div className="settings-sidebar-main">
                    <div className="settings-header">
                        <div className="settings-header-title">Settings</div>
                    </div>
                    <div className="settings-sidebar-items">

                        {panel_data.map((item, i) => (

                            <div
                                className={`settings-sidebar-item ${selectedMenu == item.id ? 'settings-sidebar-item-active' : ''}`}
                                key={`setting-menu-${item.id}`}
                                onClick={() => {
                                    setSelectedMenu(item.id)
                                    navigate(`/app/builder/settings/${item.id}`)
                                }}
                            >
                                <div className="icon" dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                                <div className="label">{item.label}</div>
                            </div>
                        ))}

                    </div>
                </div>
                <div className="settings-panel-main">
                    <Routes>

                        {panel_data.map((item, i) => (

                            <Route key={`setting-menu-res-${item.id}`} exact path={`/${item.id}/*`} element={<item.component />}></Route>
                        ))}


                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default Settings;