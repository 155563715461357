
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Clients {

    constructor() {

    }


    async getClientsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/clients/get-clients', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createClientHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/clients/create-client', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateClientHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/clients/update-client', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteClientHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/clients/delete-client', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default Clients;