import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import StoreHandler from '../../redux/StoreHandler'
import SystemNotificationHandler from '../../Handlers/SystemNotification/SystemNotification'
import ReverseProxy from '../../config/reverseProxy'

// import User_Handler from '../../Handlers/Users/User'
import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import { Link, useNavigate } from 'react-router-dom'
import Image from '../../assets/Images'
import Icons from '../../assets/Icons'
import Utils from '../../utils'


const Feedback = () => {


    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in GRN')

    const navigate = useNavigate();

    const systemNotificationHandler = new SystemNotificationHandler()

    const [Notifications, setNotifications] = useState([])

    const getNotificationIcon = (type) => {

        if (type == 'task') return Icons.general.tasks
        else if (type == 'vendor') return Icons.general.users_group
        else if (type == 'team') return Icons.general.users
        else if (type == 'project') return Icons.general.takeoff
        else if (type == 'proposal') return Icons.general.users_group
        else if (type == 'client_invoice') return Icons.general.invoice
        else if (type == 'change_order') return Icons.general.change_orders
        else if (type == 'client') return Icons.general.user
        else if (type == 'client') return Icons.general.user
        else if (type == 'role') return Icons.general.user_role
        else if (type == 'purchase_order') return Icons.general.purchase_order
        else if (type == 'grn') return Icons.general.grn
        else if (type == 'bill') return Icons.general.bills
        else if (type == 'indent') return Icons.general.indent
        else if (type == 'material_issue') return Icons.general.material_issue
        else if (type == 'material_delivery') return Icons.general.delivery
        else if (type == 'issue') return Icons.general.issues


        return Icons.general.notification
    }

    const HandleOpenNotification = (notification) => {
        navigate(notification.link);

    }
    const [SearchFilter, setSearchFilter] = useState('')
    const [SearchDebounce, setSearchDebounce] = useState(null)
    const SearchDebounceDelay = 200

    const HandleSearchFilter = (search) => {

        setSearchFilter(search)
        if (SearchDebounce) clearTimeout(SearchDebounce)

        setSearchDebounce(setTimeout(() => {

            let notifications = [...Notifications]

            notifications = notifications.map(n => {
                if (!n.message.toLowerCase().includes(search.toLowerCase())) n.disabled = true
                else n.disabled = false
                return n
            })

            setNotifications(notifications)


        }, SearchDebounceDelay))
    }

    const [SelectedTag, setSelectedTag] = useState('all')
    const [TagFilterOptions, setTagFilterOptions] = useState([
        {
            label: 'All',
            value: 'all',
            active: true,
        },
        {
            label: 'Unread',
            value: 'unread',
            active: false,
        },
        {
            label: 'Task Updates',
            value: 'task',
            active: false,
        },
        {
            label: 'New',
            value: 'create',
            active: false,
        },
        {
            label: 'Updated',
            value: 'update',
            active: false,
        },
        {
            label: 'Deleted',
            value: 'delete',
            active: false,
        },
    ])

    const HandleTagFilter = (tag) => {

        setSelectedTag(tag)

        let notifications = [...Notifications]

        notifications = notifications.map(n => {
            if (tag == 'all') n.disabled = false
            else if (tag == 'unread') n.disabled = n.status != '0'
            else if (tag == 'task') n.disabled = n.menu_type != 'task'
            else {
                n.disabled = n.type != tag
            }
            return n
        })

        setNotifications(notifications)
    }

    const LoadNotification = async () => {

        setIsLoading(true)
        let response = await systemNotificationHandler.getSystemNotificationsHandler()

        setIsLoading(false)

        if (response.success) {

            let notifications = response.data

            if (!Array.isArray(notifications)) return

            notifications = notifications.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at)
            })

            setNotifications(notifications)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Failed to get Notifications`)
        }
    }

    useEffect(() => {
        LoadNotification()
    }, [])


    return (
        <>
            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Notifications",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />


                : null}

            <div className="project-notification-main">
                <div className="notification-content-main">
                    <div className="notification-filters">
                        <div className="filters-title">Filter by</div>

                        <div className="filters-items">

                            <div className="search-bar-filter">
                                <input
                                    type="text"
                                    value={SearchFilter}
                                    placeholder='Search...'
                                    onInput={(e) => HandleSearchFilter(e.target.value)}
                                />
                                <div className="search-icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.search }}
                                ></div>
                            </div>
                            <div className="tag-option-filter">
                                <div className="tag-option-filter-options">

                                    {
                                        TagFilterOptions.map((tag, index) => (

                                            <div
                                                key={`tag-filter-opt-${index}`}
                                                className={`tag-filter-opt ${tag.value == SelectedTag ? 'tag-filter-opt-active' : ''}`}
                                                onClick={() => HandleTagFilter(tag.value)}
                                            >
                                                <div className="label">{tag.label}</div>
                                            </div>

                                        ))
                                    }
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="notification-items">
                        {
                            Notifications.filter(n => !n.disabled).map((notification, index) => (

                                <div
                                    className="notification-item"
                                    key={`notification-item-${index}`}
                                >
                                    <div className="item-left">
                                        <div className="item-type-icon"
                                            dangerouslySetInnerHTML={{ __html: getNotificationIcon(notification.menu_type) }}
                                        >
                                        </div>
                                        <div className="item-details">
                                            <div className="title">{notification.message}</div>
                                            {notification.project_id ? <div className="desc">Project: {notification.project_id || ""}</div> : ''}
                                            <div className="desc">{Utils._getLocalFullDate(notification.created_at)}</div>
                                        </div>
                                    </div>
                                    <div className="item-right">
                                        <div className="navigate-button"
                                            onClick={() => HandleOpenNotification(notification)}
                                        >
                                            <div className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.arrow_right }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                            ))
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default Feedback;