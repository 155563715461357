

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import DashboardHandler from '../../Handlers/Dashboard/Dashboard';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Chart from "react-apexcharts";


import SmarkTopCards from '../../components/SmarkTopCards'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const Dashboard = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const dashboardHandler = new DashboardHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Clients')

    const [DashboardDetials, setDashboardDetials] = useState({})

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--clients', feature_key: 'clients' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (data) => {


        let total = data.quotation.total
        let total_active = data.quotation.completed
        let total_inactive = data.quotation.pending

        let smark_cards = [
            {
                id: 'lm-smart-top-cards-total',
                label: 'Total Quotations',
                value: total || "0",
                icon: Icons.general.quotation,
                unit_name: '',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },

            {
                id: 'lm-smart-top-cards-outstanding_balance',
                label: 'Pending Quotations',
                value: total_inactive || "0",
                icon: Icons.general.quotation,
                unit_name: '',
                style: {
                    color: '#ff5b5b',
                    bg_linear_colors: {
                        start: '#ffeded',
                        end: '#fff9f9'
                    },
                    border_color: '#dcd1d1',
                },
            },
            {
                id: 'lm-smart-top-cards-total_active',
                label: 'Completed Quotations',
                value: total_active || "0",
                icon: Icons.general.quotation,
                unit_name: '',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
        ]
        setSmarkCard_Items(smark_cards)

    }

    const [LeadsOverviewChart, setLeadsOverviewChart] = useState({
        heading: 'Leads Overview',
        height: '35vh',
        series: [44, 55, 67, 83],

        options: {
            chart: {
                width: 300,
                type: 'donut',
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            colors: ['#8b5fce', '#4ab25b', '#d2a800', '#ff5b5b'],
            plotOptions: {
                pie: {
                    donut: {
                        size: '70%',
                        roundedCorners: true, // true
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '22px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: undefined,
                                offsetY: -10
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: undefined,
                                offsetY: 10,
                                formatter: function (val) {
                                    return val
                                }
                            },
                            total: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: 'black',
                                offsetY: 10,
                                label: 'Total',
                                formatter: function (w) {
                                    return 561
                                },
                                showAlways: true
                            }
                        }
                    }
                }
            },
            labels: ['Total Leads', 'Site Visited', 'Excetuition', 'Completed'],
        },
        has_details: true,
        details_direction: 'row',
        details: [
            {
                name: 'Total Leads',
                value: 44,
                style: {
                    color: '#8b5fce',
                    border_color: '#c3bad0',
                    bg_color: '#f2eaff',
                    value_bg: '#dac5fa'
                }
            },
            {
                name: 'Site Visited',
                value: 55,
                style: {
                    color: '#4ab25b',
                    border_color: '#c6dccc',
                    bg_color: '#ddffe3',
                    value_bg: '#c3f6d2'
                }
            },
            {
                name: 'Excetuition',
                value: 67,
                style: {
                    color: '#ff4b4b',
                    border_color: '#d6cfdc',
                    bg_color: '#fff0f0',
                    value_bg: '#ffe1e1'
                }
            },
            {
                name: 'Completed',
                value: 83,
                style: {
                    color: '#d2a800',
                    border_color: '#dcd8c6',
                    bg_color: '#fffae6',
                    value_bg: '#fff4c8'
                }
            }
        ]
    })
    const [LeadsSourcesChart, setLeadsSourcesChart] = useState({
        heading: 'Lead Sources',
        height: '35vh',
        series: [{
            data: []
        }],

        options: {
            chart: {
                width: '100%',
                height: 250,
                type: 'bar',
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            colors: ['#ff5b5b', '#4ab25b', '#ff4b4b', '#d2a800', '#8b5fce', '#ff5b5b', '#4ab25b', '#ff4b4b', '#d2a800', '#8b5fce'],
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            xaxis: {
                categories: [],
                labels: {
                    style: {
                        colors: ['#ff5b5b', '#4ab25b', '#ff4b4b', '#d2a800', '#8b5fce', '#ff5b5b', '#4ab25b', '#ff4b4b', '#d2a800', '#8b5fce'],
                        fontSize: '12px'
                    }
                }
            }

        },
        has_details: false,
        details_direction: 'row',
        details: [
            {
                name: 'Total Leads',
                value: 44,
                style: {
                    color: '#8b5fce',
                    border_color: '#c3bad0',
                    bg_color: '#f2eaff',
                    value_bg: '#dac5fa'
                }
            },
            {
                name: 'Site Visited',
                value: 55,
                style: {
                    color: '#4ab25b',
                    border_color: '#c6dccc',
                    bg_color: '#ddffe3',
                    value_bg: '#c3f6d2'
                }
            },
            {
                name: 'Excetuition',
                value: 67,
                style: {
                    color: '#ff4b4b',
                    border_color: '#d6cfdc',
                    bg_color: '#fff0f0',
                    value_bg: '#ffe1e1'
                }
            },
            {
                name: 'Completed',
                value: 83,
                style: {
                    color: '#d2a800',
                    border_color: '#dcd8c6',
                    bg_color: '#fffae6',
                    value_bg: '#fff4c8'
                }
            }
        ]
    })

    const [LeadsSiteVisitsList, setLeadsSiteVisitsList] = useState({
        heading: 'Site Visits',
        height: '35vh',
        details: [
            {
                sno: '1',
                name: 'John Doe',
                phone: '1234567890',
                site_type: 'Kitchen Interior',
                date: '2021-10-10',
                site_visit_date: '2021-10-10',
                status: 1,
                assign_site_visit_name: 'John Doe',
                duration: '2 Days',
            },

        ]
    })

    const [QuotationTypesCharts, setQuotationTypesCharts] = useState({
        heading: 'Quotation Types',
        height: '35vh',
        series: [{
            data: []
        }],

        options: {
            chart: {
                width: '100%',
                height: 250,
                type: 'bar',
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            colors: ['#ff5b5b', '#4ab25b', '#ff4b4b', '#d2a800', '#8b5fce', '#ff5b5b', '#4ab25b', '#ff4b4b', '#d2a800', '#8b5fce'],
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            xaxis: {
                categories: [],
                labels: {
                    style: {
                        colors: ['#ff5b5b', '#4ab25b', '#ff4b4b', '#d2a800', '#8b5fce', '#ff5b5b', '#4ab25b', '#ff4b4b', '#d2a800', '#8b5fce'],
                        fontSize: '12px'
                    }
                }
            }

        },
        has_details: false,
        details_direction: 'row',
        details: [
            {
                name: 'Total Leads',
                value: 44,
                style: {
                    color: '#8b5fce',
                    border_color: '#c3bad0',
                    bg_color: '#f2eaff',
                    value_bg: '#dac5fa'
                }
            },
            {
                name: 'Site Visited',
                value: 55,
                style: {
                    color: '#4ab25b',
                    border_color: '#c6dccc',
                    bg_color: '#ddffe3',
                    value_bg: '#c3f6d2'
                }
            },
            {
                name: 'Excetuition',
                value: 67,
                style: {
                    color: '#ff4b4b',
                    border_color: '#d6cfdc',
                    bg_color: '#fff0f0',
                    value_bg: '#ffe1e1'
                }
            },
            {
                name: 'Completed',
                value: 83,
                style: {
                    color: '#d2a800',
                    border_color: '#dcd8c6',
                    bg_color: '#fffae6',
                    value_bg: '#fff4c8'
                }
            }
        ]
    })
    const [QuotationOverviewChart, setQuotationOverviewChart] = useState({
        heading: 'Quotations Overview',
        height: '35vh',
        series: [0, 0, 0],

        options: {
            chart: {
                width: 300,
                type: 'donut',
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            colors: ['#8b5fce', '#4ab25b', '#d2a800'],

            plotOptions: {
                pie: {
                    donut: {
                        size: '70%',
                        roundedCorners: true, // true
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '22px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: undefined,
                                offsetY: -10
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: undefined,
                                offsetY: 10,
                                formatter: function (val) {
                                    return val
                                }
                            },
                            total: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: 'black',
                                offsetY: 10,
                                label: 'Total',
                                formatter: function (w) {
                                    return 561
                                },
                                showAlways: true
                            }
                        }
                    }
                }
            },
            labels: ['Total Quotations', 'Active Quotations', 'Completed'],
        },
        has_details: true,
        details_direction: 'row',
        details: [
            {
                name: 'Total Quotations',
                value: 0,
                style: {
                    color: '#8b5fce',
                    border_color: '#c3bad0',
                    bg_color: '#f2eaff',
                    value_bg: '#dac5fa'
                }
            },
            {
                name: 'Active Quotations',
                value: 0,
                style: {
                    color: '#4ab25b',
                    border_color: '#c6dccc',
                    bg_color: '#ddffe3',
                    value_bg: '#c3f6d2'
                }
            },

            {
                name: 'Completed',
                value: 0,
                style: {
                    color: '#d2a800',
                    border_color: '#dcd8c6',
                    bg_color: '#fffae6',
                    value_bg: '#fff4c8'
                }
            }
        ]
    })

    const [ActiveQuotations, setActiveQuotations] = useState({
        heading: 'Active Quotation',
        height: '35vh',
        details: []
    })


    const RenderChartItem = ({ props }) => {


        const { heading, height, options, series, details, has_details, details_direction } = props

        return (

            <div
                className="dashboard-panel-item"
                style={{
                    height: height
                }}
            >
                <div className="dashboard-panel-item-header">{heading}</div>
                <div className={`dashboard-panel-item-content dashboard-item-${details_direction}`}>
                    <div className="dashboard-panel-item-chart"
                        style={{
                            width: has_details ? '300px' : '100%'
                        }}
                    >
                        <Chart
                            options={options}
                            series={series}
                            type={options.chart.type}
                            width={options.chart.width}
                            height={options.chart.height}
                        />
                    </div>
                    {has_details ?

                        details_direction == 'row' ?

                            <div className="dashboard-panel-item-chart-details">
                                {details.map((itm, idx) => (
                                    <div
                                        className="chart-details-item"
                                        style={{
                                            color: itm.style.color,
                                            borderColor: itm.style.border_color,
                                            backgroundColor: itm.style.bg_color
                                        }}
                                    >
                                        <div
                                            className="chart-details-item-label"
                                            style={{
                                                color: itm.style.color,
                                            }}
                                        >{itm.name}</div>
                                        <div
                                            style={{
                                                backgroundColor: itm.style.value_bg
                                            }}
                                            className="chart-details-item-value"
                                        >{itm.value}</div>
                                    </div>
                                ))}
                            </div>
                            :
                            <div className="dashboard-panel-item-chart-details-column">
                                {details.map((itm, idx) => (
                                    <div
                                        className="chart-details-item"
                                    >
                                        <div className="chart-details-item-left">
                                            <div className="chart-details-item-span"
                                                style={{
                                                    background: itm.style.color,
                                                }}
                                            ></div>
                                            <div className="chart-details-item-label" >{itm.name}</div>
                                        </div>
                                        <div className="chart-details-item-value" >{itm.value}</div>
                                    </div>
                                ))}
                            </div>
                        : ''}
                </div>

            </div>
        )
    }

    const RenderLeadsListItem = ({ props }) => {

        const { heading, height, details } = props

        return (
            <div
                className="dashboard-panel-item dashboard-panel-item-list-main"
                style={{
                    height: height
                }}
            >
                <div className="dashboard-panel-item-header">{heading}</div>
                <div className="dashboard-panel-item-content">
                    <div className="dashboard-panel-item-list">
                        {details.map((itm, idx) => (
                            <div
                                className="dashboard-panel-item-list-item"
                            >
                                <div className="list-item-left">
                                    <div className="list-item-name">{itm.name}</div>
                                    <div className="list-item-desc">{itm.phone}</div>
                                </div>
                                <div className="list-item-right">
                                    <div className="list-item-desc"><span className='strong'>Assignee:</span>{itm.assign_site_visit_name}</div>
                                    <div className="list-item-date">{itm.site_visit_date}</div>
                                    <div className="list-item-duration">{itm.duration}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
    const RenderQuotationListItem = ({ props }) => {

        const { heading, height, details } = props

        return (
            <div
                className="dashboard-panel-item dashboard-panel-item-list-main"
                style={{
                    height: height
                }}
            >
                <div className="dashboard-panel-item-header">{heading}</div>
                <div className="dashboard-panel-item-content">
                    <div className="dashboard-panel-item-list">
                        {details.map((itm, idx) => (
                            <div
                                className="dashboard-panel-item-list-item"
                            >
                                <div className="list-item-left">
                                    <div className="list-item-desc">{itm.quotation_no}</div>
                                    <div className="list-item-name">{itm.site_name}</div>
                                    <div className="list-item-desc">{itm.site_location}</div>
                                </div>
                                <div className="list-item-right">
                                    <div className="list-item-items">
                                        <div className="list-item-desc"><span className='strong'>Client:</span>{itm.assign_to_name}</div>
                                        <div className="list-item-desc"><span className='strong'>Assignee:</span>{itm.assign_to_name}</div>
                                    </div>
                                    <div className="list-item-date">₹{itm.amount}</div>
                                    <div className={`list-item-status list-item-status-${itm.status}`}>{itm.status_label}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }


    const getDashboardDetials = async () => {

        let response = await dashboardHandler.getDashboardDetailsHandler()

        if (response && response.success) {

            // quotation section
            {
                let quotation = response.data.quotation

                let quotation_overview_data = [quotation.total, quotation.pending, quotation.completed]
                let quotation_details = [
                    {
                        name: 'Total Quotations',
                        value: quotation.total,
                        style: {
                            color: '#8b5fce',
                            border_color: '#c3bad0',
                            bg_color: '#f2eaff',
                            value_bg: '#dac5fa'
                        }
                    },
                    {
                        name: 'Active Quotations',
                        value: quotation.pending,
                        style: {
                            color: '#4ab25b',
                            border_color: '#c6dccc',
                            bg_color: '#ddffe3',
                            value_bg: '#c3f6d2'
                        }
                    },
                    {
                        name: 'Completed',
                        value: quotation.completed,
                        style: {
                            color: '#d2a800',
                            border_color: '#dcd8c6',
                            bg_color: '#fffae6',
                            value_bg: '#fff4c8'
                        }
                    }
                ]

                let quotation_types_data = []
                let quotation_types_labels = []

                for (const key in quotation.quotation_types) {
                    quotation_types_data.push(quotation.quotation_types[key])

                    let label = String(key).includes(' ') ? String(key).split(' ') : key

                    quotation_types_labels.push(label)
                }

                let quotation_overview_chart_data = { ...QuotationOverviewChart }

                quotation_overview_chart_data.series = quotation_overview_data
                quotation_overview_chart_data.details = quotation_details

                let quotation_types_chart_data = { ...QuotationTypesCharts }

                quotation_types_chart_data.series = [{
                    data: quotation_types_data
                }]

                quotation_types_chart_data.options.xaxis.categories = quotation_types_labels

                let active_quotation_list = { ...ActiveQuotations }

                let active_quotation_data = quotation.active_quotations.map(quote => {

                    return {
                        sno: quote.id,
                        quotation_no: quote.quotation_no,
                        site_name: quote.site_name,
                        site_location: quote.site_location,
                        client_name: quote.client_name,
                        site_type: quote.type,
                        date: quote.date,
                        status: quote.status,
                        status_label: quote.status == 2 ? 'Completed' : 'Pending',
                        assign_to_name: quote.assign_to_name,
                        amount: quote.final_quote_amount,
                    }
                })

                active_quotation_list.details = active_quotation_data

                setQuotationOverviewChart(quotation_overview_chart_data)
                setQuotationTypesCharts(quotation_types_chart_data)
                setActiveQuotations(active_quotation_list)
            }

            //leads section
            {
                let leads = response.data.leads

                let leads_overview_data = [leads.total, leads.site_visited, leads.excetution, leads.completed]
                let leads_overview_details = [
                    {
                        name: 'Total Leads',
                        value: leads.total,
                        style: {
                            color: '#8b5fce',
                            border_color: '#c3bad0',
                            bg_color: '#f2eaff',
                            value_bg: '#dac5fa'
                        }
                    },
                    {
                        name: 'Site Visited',
                        value: leads.site_visited,
                        style: {
                            color: '#4ab25b',
                            border_color: '#c6dccc',
                            bg_color: '#ddffe3',
                            value_bg: '#c3f6d2'
                        }
                    },
                    {
                        name: 'Excetuition',
                        value: leads.excetution,
                        style: {
                            color: '#ff4b4b',
                            border_color: '#d6cfdc',
                            bg_color: '#fff0f0',
                            value_bg: '#ffe1e1'
                        }
                    },
                    {
                        name: 'Completed',
                        value: leads.completed,
                        style: {
                            color: '#d2a800',
                            border_color: '#dcd8c6',
                            bg_color: '#fffae6',
                            value_bg: '#fff4c8'
                        }
                    }
                ]

                let leads_sources_data = []
                let leads_sources_labels = []


                for (const key in leads.lead_sources) {
                    leads_sources_data.push(leads.lead_sources[key])
                    let label = String(key).includes(' ') ? String(key).split(' ') : key
                    leads_sources_labels.push(label)
                }

                let leads_overview_chart_data = { ...LeadsOverviewChart }

                leads_overview_chart_data.series = leads_overview_data
                leads_overview_chart_data.details = leads_overview_details

                let leads_sources_chart_data = { ...LeadsSourcesChart }

                leads_sources_chart_data.series = [{
                    data: leads_sources_data
                }]

                leads_sources_chart_data.options.xaxis.categories = leads_sources_labels

                let site_visits_list = { ...LeadsSiteVisitsList }

                let site_visits_data = leads.upcommig_site_visits.map(lead => {

                    let duration = Utils.getDuration(new Date().getTime(), new Date(lead.site_visit_date).getTime(), 'days')

                    return {
                        sno: lead.id,
                        name: lead.name,
                        phone: lead.phone,
                        site_type: lead.site_type,
                        date: lead.date,
                        site_visit_date: lead.site_visit_date,
                        status: lead.status,
                        assign_site_visit_name: lead.assign_site_visit_name,
                        duration: `${duration} Days`,
                    }
                })

                site_visits_list.details = site_visits_data

                setLeadsOverviewChart(leads_overview_chart_data)
                setLeadsSourcesChart(leads_sources_chart_data)
                setLeadsSiteVisitsList(site_visits_list)

            }
            setDashboardDetials(response.data)
            HandleSmartCardSetup(response.data)


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Dashboard, Please try again!`)
        }
    }

    useEffect(() => {
        getDashboardDetials()
    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Clients",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}




            <div className="project-dashboard-main">
                <div className="dashboard-panel-content">

                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}

                    <div className="dashboard-panel-items">
                        <RenderChartItem props={LeadsOverviewChart} />
                        <RenderChartItem props={LeadsSourcesChart} />
                        <RenderLeadsListItem props={LeadsSiteVisitsList} />

                        <RenderChartItem props={QuotationTypesCharts} />
                        <RenderChartItem props={QuotationOverviewChart} />
                        <RenderQuotationListItem props={ActiveQuotations} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default Dashboard;