import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import StoreHandler from '../../redux/StoreHandler'

import { Link, useNavigate } from 'react-router-dom'
import Image from '../../assets/Images'
import Icons from '../../assets/Icons'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const Header = () => {

    const [isLoading, setIsLoading] = useState(false)

    const [currentMenuPaths, setCurrentMenuPath] = useState([])

    const { isAdmin, roles_permissions, company_features } = useAuth()


    useEffect(() => {

        const currentURL = window.location.pathname;

        let currentMenuPath = currentURL ? currentURL.split(`/app/builder/`)[1] : undefined;

        if (!currentMenuPath) return ''

        currentMenuPath = String(currentMenuPath).split('/')

        if (!Array.isArray(currentMenuPath)) return

        // currentMenuPath.splice(0, 0, 'home')

        setCurrentMenuPath(currentMenuPath)

    }, [window.location.pathname])

    const getSelectedIndexPath = (index) => {

        let path = currentMenuPaths.filter((path, i) => index >= i).join('/')
        return path
    }

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['builder'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const [userDetials, setUserDetials] = useState({})
    const [profileImg_url, setProfileImg_url] = useState('')

    const navigate = useNavigate();

    const LogoutEvent = () => {
        try {

            localStorage.removeItem('authToken');
            localStorage.removeItem('userdetials');
            window.location.href = '/app/signin'


        } catch (error) {
            console.error('Error decoding authToken:', error);
        }
    }

    const makeCustomDropDown = (params) => {

        let { id, icon, isItemsLabel, itemsLabel, callback = () => { }, _classList, activeClass, isIcon, isImg, img, isLable, lablel, isOptions, options = [] } = params

        options = options || []
        _classList = _classList || []

        let active_dropdown_class = activeClass || "custom-dropdown-active"

        let container = document.createElement('div')

        _classList.push('custom-dropdown')

        container.classList.add(..._classList)
        container.id = id

        container.innerHTML = `
            <div class="custom-dropdown-label">
                ${isLable ? `<span class='lablel'>${lablel}</span>` : ''}
                ${isImg ? `<span class='img'><img src='${img}'/></span>` : ''}
                ${isIcon ? `<span class='icon'>${icon}</span>` : ''}
            </div>
            <div class="custom-dropdown-items-main">
                <span class='arrow-up'></span>
                ${isItemsLabel ? `<span class='dropdown-items-label'>${itemsLabel}</span>` : ''}
                <div class="custom-dropdown-items" id='custom-dropdown-items'>
                </div>
            </div>
        `

        let makeItem = (option) => {

            let { icon, isIcon, isLeft, lablel, callback } = option

            let item = document.createElement('div')
            item.classList.add('custom-dropdown-item')

            item.innerHTML = `

                <div class="custom-dropdown-item-label">
                    ${isLeft && isIcon ? `<span class='icon'>${icon}</span>` : ''}
                    <span class='lablel'>${lablel}</span>
                    ${!isLeft && isIcon ? `<span class='icon'>${icon}</span>` : ''}
                </div>

            `

            item.addEventListener('click', (e) => {
                callback(e)
                container.classList.remove(active_dropdown_class)
            })

            return item

        }

        let items_parent = container.querySelector('#custom-dropdown-items')

        if (isOptions) {

            options.forEach((opt, i) => {

                let item = makeItem(opt)
                items_parent.appendChild(item)
            })
        }


        let container_label = container.querySelector('.custom-dropdown-label')


        container_label.addEventListener('click', (e) => {

            if (isOptions) container.classList.toggle(active_dropdown_class)

            else callback(e)



        })

        window.addEventListener('click', (e) => {

            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(items_parent) && !path.includes(container_label))
                container.classList.remove(active_dropdown_class)
        })

        return container
    }

    const CustomDropDown = (dropdown, parentContainer) => {

        let hideContainer = (id, parentContainer) => {

            if (!id || !parentContainer) return false

            if (parentContainer.querySelector(`#${id}`))
                parentContainer.removeChild(parentContainer.querySelector(`#${id}`))

            return true
        }

        hideContainer(dropdown.id, parentContainer)

        let container = makeCustomDropDown(dropdown)
        parentContainer.appendChild(container)
    }

    const HandleDropDownEvents = () => {

        let parentContainer = document.querySelector('#project-header-main')
        let notification_dropdown = parentContainer.querySelector('#notification-select-dropdown')
        let profile_dropdown = parentContainer.querySelector('#profile-select-dropdown')


        let notification_data = {
            id: "header-feedback-dropdown-input",
            lablel: "Feedback",
            itemsLabel: 'SYSTEM SETTINGS',
            isItemsLabel: false,
            _classList: [],
            activeClass: 'custom-button-dropdown-active-norotate',
            isIcon: true,
            isImg: false,
            img: null,
            isLable: false,
            icon: Icons.general.notification,
            isOptions: false,
            isNotification: true,
            callback: () => { navigate('/app/builder/notifications') },
            options: [
            ]
        }
        let profile_data = {
            id: "header-profile-dropdown-input",
            lablel: "Build Mate",
            itemsLabel: 'SYSTEM SETTINGS',
            isItemsLabel: false,
            _classList: ['custom-dropdown-img'],
            activeClass: 'custom-button-dropdown-active',
            isIcon: false,
            isImg: true,
            img: profileImg_url || Image.User,
            isLable: false,
            icon: Icons.general.dropdown_arrow,
            isOptions: true,
            callback: () => { },
            options: [
                {
                    icon: Icons.general.user,
                    isIcon: true,
                    isLeft: true,
                    lablel: "My Profile",
                    callback: () => { navigate('/app/builder/settings/myprofile') }
                },
                {
                    icon: Icons.general.settings,
                    isIcon: true,
                    isLeft: true,
                    lablel: "Settings",
                    callback: () => { navigate('/app/builder/settings/myprofile') }
                },
                {
                    icon: Icons.general.logout,
                    isIcon: true,
                    isLeft: true,
                    lablel: "Log out",
                    callback: () => { LogoutEvent() }
                },
            ]
        }

        CustomDropDown(notification_data, notification_dropdown)
        CustomDropDown(profile_data, profile_dropdown)
    }

    const MobileSideBar_Event = (e) => {
        let sidebar_contanier = document.querySelector('#project-sidebar-main')

        let sidebar_active_class = "project-sidebar-active"

        if (sidebar_contanier) sidebar_contanier.classList.toggle(sidebar_active_class)
    }

    useEffect(() => {

        HandleDropDownEvents()

        LoadStoreData()
    }, [])

    useEffect(() => {
        let user = store.user.builder

        if (user && Object.keys(user).length > 0) {
            setUserDetials(user)

            if (user.profile_img) setProfileImg_url(`data:image/png;base64,${user.profile_img}`)
        }

        HandleDropDownEvents()
    }, [store])

    useEffect(() => {

        HandleDropDownEvents()

    }, [store.user?.roles_permissions])

    return (
        <div className='project-header-main' id='project-header-main'>
            <div className="header-content">
                <div className="header-left">
                    <div className="header-left-path">
                        {currentMenuPaths.map((path, i) => (

                            <span
                                class={`${i == currentMenuPaths.length - 1 ? "header-left-path-active" : ""}`}
                                onClick={() => navigate(`/app/builder/${getSelectedIndexPath(i)}`)}
                            > {i != 0 ? ' / ' : ''}{path}</span>

                        ))}
                    </div>
                    <div className="header-left-item">
                        <Link to={'/app'}><img className='header-logo' src={Image.logo} /></Link>
                    </div>
                    <div
                        className="header-right-item header-mobile-menu-btn header-mobile-menu-disabled"
                        id='header-mobile-menu-btn'
                        dangerouslySetInnerHTML={{ __html: Icons.general.mobile_menu }}
                        onClick={(e) => MobileSideBar_Event(e)}
                    >
                    </div>
                </div>
                <div className="header-right">
                    <div className="header-right-item" id='notification-select-dropdown'></div>
                    <div className="header-right-item" id='profile-select-dropdown'></div>
                </div>
            </div>
        </div>
    )
}

export default Header;
