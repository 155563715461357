
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class TeamManagement {

    constructor() {

    }


    async createTeamUserHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/teamManagement/create-user', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateTeamUserHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/teamManagement/update-user', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async deleteTeamUserHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/teamManagement/delete-user', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async getTeamUsersHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/teamManagement/get-users', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default TeamManagement;