
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Quotation {

    constructor() {

    }




    async getNextCodeHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/quotation/get-next-quoteId', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getQuotationBasicsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/quotation/get-quotation-basics', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getQuotationsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/quotation/get-quotations', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createQuotationHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/quotation/create-quotation', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateQuotationHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/quotation/update-quotation', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteQuotationHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/quotation/delete-quotation', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default Quotation;