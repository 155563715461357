

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams, useFetcher } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CatalogueHandler from '../../Handlers/Catalogue/Catalogue';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import Sections from '../../config/sections'

const AddUnit = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { unitId } = useParams()

    const catalogueHandler = new CatalogueHandler()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Catalogue Units')

    const [CatalogueQualites, setCatalogueQualites] = useState([])

    const [SectionOptions, setSectionOptions] = useState([...Sections])


    const [SNO, setSNO] = useState('')
    const [Name, setName] = useState('')
    const [Code, setCode] = useState('')
    const [Rate, setRate] = useState('')
    const [Description, setDescription] = useState('')
    const [Quality_id, setQuality_id] = useState('')
    const [Quality_name, setQuality_name] = useState('')
    const [Section_id, setSection_id] = useState('')
    const [Section_name, setSection_name] = useState('')

    const navigate = useNavigate()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--clients', feature_key: 'clients' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (type == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })
            if (type == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,

        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,

        },
    ])

    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {

        e.preventDefault()

        let updateta = {
            name: Name,
            code: String(Code || ""),
            rate: String(Rate || 0),
            quality_id: String(Quality_id),
            quality_name: Quality_name,
            section_id: String(Section_id),
            section_name: Section_name,
            description: Description
        }

        let response = { success: false }

        if (type == 'edit') {

            updateta["sno"] = unitId
            response = await catalogueHandler.updateUnitHandler(updateta)

        }

        else response = await catalogueHandler.createUnitHandler(updateta)

        if (response.success) {
            callback()
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Units, Please try again!`)
        }

        close(type)
    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <button
                            className={`action-btn action-${type}`}

                        >
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.save }}></div>
                            <div className="label">Save</div>
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const getCatalougeQualities = async () => {

        setIsLoading(true)
        let response = await catalogueHandler.getQualitiesHandler()

        setIsLoading(false)

        if (response && response.success) {

            let qualities = response.data || []

            qualities = qualities.map((item) => {
                return {
                    value: item.sno,
                    label: item.name
                }
            })

            setCatalogueQualites(qualities)
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Materials, Please try again!`)
        }
    }


    useEffect(() => {

        getCatalougeQualities()

        if (type == 'edit' && unitId) {

            const LoadTemplate = async (unitId) => {

                if (Object.keys(detials).length) {

                    let { sno, code, rate, name, description, quality_id, quality_name, section_id, section_name } = detials

                    setSNO(sno)
                    setName(name)
                    setCode(code || '')
                    setRate(rate)
                    setQuality_id(quality_id)
                    setQuality_name(quality_name)
                    setSection_id(section_id)
                    setSection_name(section_name)
                    setDescription(description)

                }
                else {

                    let response = await catalogueHandler.getUnitsHandler({
                        sno: unitId
                    })

                    if (response && response.success && Array.isArray(response.data) && response.data.length) {

                        let { sno, code, rate, name, description, quality_id, quality_name, section_id, section_name } = response.data[0]

                        setSNO(sno)
                        setName(name)
                        setCode(code || '')
                        setRate(rate)
                        setQuality_id(quality_id)
                        setQuality_name(quality_name)
                        setSection_id(section_id)
                        setSection_name(section_name)
                        setDescription(description)
                    }
                    else {
                        setWarningAlert(true)
                        setWarningAlertType('error')
                        setApiFailedMessage(`Error in Catalogue Units, Please try again!`)
                    }
                }
            }

            LoadTemplate(unitId)
        }
    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Catalogue Units",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <form className="side-popup-container" onSubmit={HandlePopupSubmit}>
                    <Popup_Header />
                    <div className="clients-popup-content">

                        <div className="content-items">
                            <TextInput
                                props={{
                                    id: "catalogue-unit-name",
                                    value: Name,
                                    placeholder: '',
                                    setValue: (value) => setName(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Unit Name",
                                }}
                            />
                        </div>
                        <div className="content-items">
                            <TextInput
                                props={{
                                    id: "catalogue-unit-code",
                                    value: Code,
                                    placeholder: '',
                                    setValue: (value) => setCode(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Code",
                                }}
                            />
                        </div>

                        <div className="content-items">
                            <SelectInput
                                props={{
                                    id: "catalogue-unit-quality",
                                    value: Quality_id,
                                    placeholder: '',
                                    options: CatalogueQualites || [],
                                    setValue: (value, label) => {
                                        setQuality_id(value)
                                        setQuality_name(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Choose Quality",
                                }}
                            />
                        </div>
                        <div className="content-items">
                            <SelectInput
                                props={{
                                    id: "catalogue-unit-section",
                                    value: Section_id,
                                    placeholder: '',
                                    options: SectionOptions || [],
                                    setValue: (value, label) => {
                                        setSection_id(value)
                                        setSection_name(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Choose Section",
                                }}
                            />
                        </div>
                        <div className="content-items">
                            <TextInput
                                props={{
                                    id: "catalogue-unit-rate",
                                    value: Rate,
                                    placeholder: '',
                                    type: 'number',
                                    setValue: (value) => setRate(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Rate (₹)",
                                }}
                            />
                        </div>


                        <div className="content-items">

                            <div className="content-item-address">
                                <textarea
                                    id="clients-address"
                                    value={Description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder='Description'
                                ></textarea>
                            </div>

                        </div>
                    </div>
                    <Popup_Footer />
                </form>
            </div>
        </>
    )
}

const CatalogueUnits = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const catalogueHandler = new CatalogueHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Catalogue Units')

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--clients', feature_key: 'clients' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,

        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,

        },
    ])

    const [Selected_Quality, setSelected_Quality] = useState({})

    const [selectedAction, setSelectedAction] = useState('')

    const [ClientDetials_original, setClientDetials_original] = useState([])
    const [ClientDetials, setClientDetials] = useState([])

    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const getCatalogueUnits = async () => {

        setIsLoading(true)
        let response = await catalogueHandler.getUnitsHandler()

        setIsLoading(false)

        if (response && response.success) {

            let qualities = response.data.map((item, index) => {

                item.index = index + 1 < 10 ? `0${index + 1}` : index + 1
                return item
            })

            setClientDetials_original(qualities)
            setClientDetials(qualities)


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Units, Please try again!`)
        }
    }

    useEffect(() => {
        getCatalogueUnits()
    }, [])


    const tabelHeader = [
        {
            id: 'index',
            label: 'SNo',
            maxWidth: 50,

        },
        {
            id: 'created_at',
            label: 'Date',
            format: (value) => Utils.getLocalFullDateBYFormat(value),
            maxWidth: 100,
        },
        {
            id: 'code',
            label: 'Code',
            format: (value) => value || '-',
        },
        {
            id: 'name',
            label: 'Name',
            minWidth: 400,
        },

        {
            id: 'quality_name',
            label: 'Quality',
        },
        {
            id: 'section_name',
            label: 'Section',
        },
        {
            id: 'rate',
            label: 'Rate',
            format: (value) => `₹${value || '-/-'}`,
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            format: (value) => value,
        },
    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleAddNew = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/create-unit`)
    }
    const HandleEditItem = (item) => {

        if (!has_edit_access) return
        setSelected_Quality(item)

        navigate(`${window.location.pathname}/edit-unit/${item.sno}`)
    }
    const HandleDeleteItem = async (item) => {

        if (!has_edit_access || !has_delete_access) return

        setDeleteItem(item.sno)
        setDeleteItemAlert(true)
    }

    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        if (!confirmation || id == undefined) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }

        let response = await catalogueHandler.deleteUnitHandler({
            sno: String(id)
        })

        if (response && response.success) {

            getCatalogueUnits()


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Material, Please try again!`)

        }


        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }


    const HanelCellAction = (id, row) => {

        console.log(id, 'selectedAction');
        if (id == "name") HandleEditItem(row)
        if (id == "actions") {
            setSelectedAction(row.sno)
            HandleActionDisable(row.sno)
        }
    }

    const HandleActionDisable = (id) => {

        let input = document.getElementById(`clients-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`clients-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    const ClosePopup = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)

    }



    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Catalogue Units",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { icon: Icons.general.btn_delete, label: "Yes, Delete" },
                        heading: "Delete item",
                        message: "Are you sure you want to delete?",
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <Routes >
                <Route exact path='/create-unit' element={
                    <AddUnit props={{
                        type: "create",
                        heading: "Create Unit",
                        callback: () => {
                            getCatalogueUnits()
                        },
                        close: ClosePopup
                    }} />}>

                </Route>
                <Route exact path='/edit-unit/:unitId' element={
                    <AddUnit props={{
                        type: "edit",
                        heading: "Edit Unit",
                        callback: () => {
                            getCatalogueUnits()
                        },
                        close: ClosePopup,
                        detials: Selected_Quality
                    }} />}>

                </Route>

            </Routes>


            <div className="panel-content-header">
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder='Search units here...'
                    />
                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.search }}></div>
                </div>
                <div className="buttons">
                    {has_add_access ?
                        <div
                            className="button add-button"
                            onClick={(e) => HandleAddNew()}
                        >
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                            <div className="label">Add New</div>
                        </div>
                        : ''}
                </div>
            </div>
            <div className="panel-content-sections panel-content-tabel-section">
                <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                    <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                        <Table stickyHeader>
                            <TableHead
                                className='tabel-header'
                            >
                                <TableRow>
                                    {tabelHeader.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            className='tabel-header-items'
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className='tabel-body'>
                                {ClientDetials
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                                                {tabelHeader.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            onClick={(e) => {
                                                                if (column.id != 'actions') HanelCellAction(column.id, row)
                                                            }}
                                                        >
                                                            {
                                                                column.id == 'name' ?
                                                                    <div
                                                                        className="tabel-name"
                                                                    >
                                                                        {column.format ? column.format(value) : value}
                                                                    </div>

                                                                    : column.id == 'actions' && has_edit_access ?

                                                                        <div
                                                                            className="tabel-action-main"
                                                                        >
                                                                            {has_edit_access ?
                                                                                <div
                                                                                    className="action-icon"
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                                                                    onClick={(e) => HandleEditItem(row)}
                                                                                ></div>
                                                                                : ''}
                                                                            {has_delete_access ?
                                                                                <div
                                                                                    className="action-icon"
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                                    onClick={(e) => HandleDeleteItem(row)}
                                                                                ></div>
                                                                                : ''}

                                                                        </div>
                                                                        : column.format ? column.format(value) : value
                                                            }
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        style={{ height: '7%', overflow: 'hidden' }}
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={ClientDetials.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </>
    )
}

export default CatalogueUnits;