
import Images from '../assets/Images'
import Icons from '../assets/Icons'
import { useEffect, useState } from 'react'

const AlertIcons = {
    "warning": Icons.general.warning,
    "success": Icons.general.tick_mark,
    "error": Icons.general.close_mark,
}

const PWAInstallPopup = (e) => {

    const [showPopup, setHidePopup] = useState(true)

    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);


    useEffect(() => {

        let is_hide = localStorage.getItem('hidePWAInstallPopup')

        if (is_hide === 'true') setHidePopup(false)

        const handler = e => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const HandleInstallBtn = (e) => {

        // Logic to prompt user to install PWA
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
        localStorage.setItem('hidePWAInstallPopup', 'true')
        setHidePopup(false)
    }

    const HandleCloseBtn = (e) => {

        localStorage.setItem('hidePWAInstallPopup', 'true')

        setHidePopup(false)
    }


    return (
        <>
            {!showPopup ? '' :
                !supportsPWA ? '' :
                    <div className={`pwa-install-popup-main`} >
                        <div className="pwa-popup-content">
                            <div className="pwa-logo">
                                <img src={Images.logo_small} alt="" />
                            </div>
                            <div className="pwa-buttons">
                                <div
                                    className="pwa-install-button"
                                    onClick={HandleInstallBtn}
                                >Install App</div>
                                <div
                                    className="pwa-close-button"
                                    onClick={HandleCloseBtn}
                                >
                                    <div className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.close_small }}
                                    ></div>
                                </div>
                            </div>

                        </div>
                    </div>
            }
        </>
    )

}

export default PWAInstallPopup;