

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import QuotationHandler from '../../Handlers/Quotation/Quotation';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import CreateQuoteMeasurement from './createQuoteMeasurement'


const CreateQuotation = ({ props }) => {

    const { quoteId } = useParams();

    const { menuType = 'create' } = props

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)


    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--vendors' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (menuType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/projects`, { state: { from: location }, replace: true })
            if (menuType == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const storeHandler = new StoreHandler({
        dataStack: ['clients', 'team_users', 'terms_conditions'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const quotationHandler = new QuotationHandler()


    //Basic Detials

    const [id, setId] = useState('')
    const [QuotationNo, setQuotationNo] = useState('')
    const [SiteName, setSiteName] = useState('')
    const [SiteLocation, setSiteLocation] = useState('')
    const [Date, setDate] = useState(Utils._getLocalDate())
    const [Client_id, setClient_id] = useState('')
    const [Client_name, setClient_name] = useState('')
    const [Assign_to_id, setAssign_to_id] = useState('')
    const [Assign_to_name, setAssign_to_name] = useState('')
    const [Status, setStatus] = useState('')
    const [Type, setType] = useState('')

    const [Client_Options, setClient_Options] = useState([])
    const [Assignee_Options, setAssignee_Options] = useState([])

    const [Status_Options, setStatus_Options] = useState([
        {
            value: '0',
            label: 'Inactive'
        },
        {
            value: '1',
            label: 'Active'
        },
        {
            value: '2',
            label: 'Completed'
        },
        {
            value: '3',
            label: 'Revised'
        },

    ])
    const [Type_Options, setType_Options] = useState([
        {
            value: '0',
            label: 'Commercial Project'
        },
        {
            value: '1',
            label: 'Residential Project'
        },
        {
            value: '2',
            label: 'Industrial Project'
        },
        {
            value: '3',
            label: 'Hospitality Project'
        },
        {
            value: '4',
            label: 'Renovation Project'
        },
        {
            value: "5",
            label: "Turnkey Project"
        },
        {
            value: "6",
            label: "Retail Project"
        },
        {
            value: "7",
            label: "Healthcare Project"
        },
        {
            value: "8",
            label: "Educational Project"
        },
        {
            value: "9",
            label: "Exhibition Project"
        },
        {
            value: "10",
            label: "Landscape Project"
        },
        {
            value: "11",
            label: "Other Project"
        }
    ])


    const HandleBackBtn = (activestepper_idx) => {

        if (activestepper_idx == 0) navigate('/app/builder/quotations')
        else setActiveStepperIdx(activestepper_idx - 1)

    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        if (ActiveStepperIdx == StepperDetails.length - 1) {

            let update_data = {
                quotation_no: QuotationNo,
                site_name: SiteName,
                site_location: SiteLocation,
                date: Date,
                client_id: Client_id,
                client_name: Client_name,
                assign_to_id: Assign_to_id,
                assign_to_name: Assign_to_name,
                type: String(Type),

                measurements: QuotationMeasurements,
                terms_conditions: termsConditionValue,
                work_pattern: WorkPatternValue,
                summary_items: QuotationSummaryItems,
            }

            setIsLoading(true)

            let response = { success: false }

            if (menuType == 'edit') {

                update_data["sno"] = quoteId
                update_data["status"] = String(Status)
                response = await quotationHandler.updateQuotationHandler(update_data)

            }

            else response = await quotationHandler.createQuotationHandler(update_data)

            setIsLoading(false)

            if (response.success) {
                return navigate('/app/builder/quotations')
            }
            else {
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in Quotation, Please try again!`)
            }

        } else {
            setActiveStepperIdx(ActiveStepperIdx + 1)
            return
        }
    }

    const getNextCode = async () => {

        let response = await quotationHandler.getNextCodeHandler()

        if (response && response.success) {

            let { quoteId } = response.data

            if (quoteId) setQuotationNo(quoteId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting next vendor code`)
        }
    }


    useEffect(() => {

        if (menuType == 'create') getNextCode()
        else if (menuType == 'edit' && quoteId) {

            const LoadTemplate = async (quoteId) => {

                let response = await quotationHandler.getQuotationsHandler({
                    sno: quoteId
                })

                if (response && response.success && Array.isArray(response.data) && response.data.length) {

                    let {
                        sno,
                        quotation_no,
                        site_name,
                        site_location,
                        date,
                        status,
                        type,
                        client_id,
                        client_name,
                        assign_to_id,
                        assign_to_name,
                        terms_conditions,
                        work_pattern,
                        measurements,
                        summary_items,
                    } = response.data[0]

                    setQuotationNo(quotation_no)
                    setSiteName(site_name)
                    setSiteLocation(site_location)
                    setDate(date)
                    setStatus(status)
                    setType(type)
                    setClient_id(client_id)
                    setClient_name(client_name)
                    setAssign_to_id(assign_to_id)
                    setAssign_to_name(assign_to_name)
                    setTermsConditionValue(terms_conditions)
                    setWorkPatternValue(work_pattern)
                    setQuotationMeasurements(measurements)
                    setQuotationSummaryItems(summary_items)
                }
                else {
                    setWarningAlert(true)
                    setWarningAlertType('error')
                    setApiFailedMessage(`Error in Quotation, Please try again!`)
                }

            }

            LoadTemplate(quoteId)
        }

        LoadStoreData()
    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { clients, team_users, settings } = store.user


            if (settings && Array.isArray(settings.terms_conditions) && settings.terms_conditions.length) {

                let terms_conditions = settings.terms_conditions.map(tc => {
                    return {
                        id: tc.id,
                        value: tc.id,
                        label: tc.name,
                        body: tc.body,
                        isDefault: false,
                        document: tc.document || []
                    }
                })

                setTermsConditionOptions([
                    {
                        id: "none",
                        value: "none",
                        label: "None",
                        body: "",
                        isDefault: false,
                        document: []
                    },
                    ...terms_conditions
                ])
            }

            if (Array.isArray(clients) && clients.length) {

                clients = clients.map(itm => {
                    return {
                        value: itm.id,
                        label: itm.name,
                    }
                })

                setClient_Options(clients)
            }
            if (Array.isArray(team_users) && team_users.length) {

                team_users = team_users.map(itm => {
                    return {
                        value: itm.id,
                        label: itm.name,
                    }
                })

                setAssignee_Options(team_users)
            }


        }

    }, [store])


    const getStepperIcon = (index) => {

        if (ActiveStepperIdx > index) return Icons.general.check_completed
        else if (ActiveStepperIdx == index) return Icons.general.check_active
        else if (ActiveStepperIdx < index) return Icons.general.check_inactive

    }

    const [ActiveStepperIdx, setActiveStepperIdx] = useState(0)

    const [StepperDetails, setStepperDetails] = useState([
        {
            id: 'basic_detials',
            title: 'Basic Detials',
            status: 'active', // active, inactive, completed
        },
        {
            id: 'measurement',
            title: 'Measurement',
            status: 'inactive',
        },
        {
            id: 'terms_conditions',
            title: 'Terms',
            status: 'inactive',
        }
    ])

    const [QuotationMeasurements, setQuotationMeasurements] = useState([])
    const [CatalogueSelectedMaterial, setCatalogueSelectedMaterial] = useState('')

    const [ShowAddMeasurementPopup, setShowAddMeasurementPopup] = useState(false)
    const [SelectedMeasurementItem, setSelectedMeasurementItem] = useState(undefined)

    const HandleMaterialExpand = (id) => {

        if (CatalogueSelectedMaterial == id) setCatalogueSelectedMaterial('')
        else setCatalogueSelectedMaterial(id)
    }

    const HandleShowAddMeasurementPopup = () => {
        if (!has_add_access) return

        setShowAddMeasurementPopup(true)
    }
    const HandleAddMeasurement = (details, type) => {

        if (type == 'edit') {

            let updated = QuotationMeasurements.map(itm => {
                if (itm.id == details.id) return details
                return itm
            })

            setQuotationMeasurements(updated)
        } else {
            let updated = [...QuotationMeasurements, details]

            console.log(updated);
            setQuotationMeasurements(updated)
        }
    }
    const HandleEditItem = (item) => {

        if (!has_edit_access) return

        setSelectedMeasurementItem(item)
        setShowAddMeasurementPopup(true)

    }

    const HandleDeleteItem = async (item) => {

        if (!has_edit_access || !has_delete_access) return


        setDeleteItem(item.id)
        setDeleteItemAlert(true)

    }

    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        if (!confirmation || id == undefined) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }

        let updated = QuotationMeasurements.filter(itm => itm.id != id)
        setQuotationMeasurements(updated)


        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }


    const CloseAddMeasurementPopup = () => {

        setShowAddMeasurementPopup(false)
        setSelectedMeasurementItem(undefined)
    }

    const [termsConditionOptions, setTermsConditionOptions] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [termsConditionValue, setTermsConditionValue] = useState("")

    const [WorkPatternOptions, setWorkPatternOptions] = useState([])
    const [WorkPatternId, setWorkPatternId] = useState("")
    const [WorkPatternValue, setWorkPatternValue] = useState("")

    const HandleTermsConditions = (value, label) => {

        setTermsConditionId(value)

        let selected_TC = termsConditionOptions.filter(tc => tc.value == value)[0]
        if (selected_TC) setTermsConditionValue(selected_TC.body)
    }
    const HandleWorkPattern = (value, label) => {

        setWorkPatternId(value)

        let selected_TC = WorkPatternOptions.filter(tc => tc.value == value)[0]
        if (selected_TC) setWorkPatternValue(selected_TC.body)
    }


    const [QuotationSummaryItems, setQuotationSummaryItems] = useState([
        {
            id: Utils.getUniqueId(),
            name: "",
            amount: "",
            change: "",
        }
    ])

    const HandleQuotationSummaryItemInputChange = (value, index, key) => {

        let QuotationSummaryItems_ = [...QuotationSummaryItems]
        QuotationSummaryItems_[index][key] = value

        setQuotationSummaryItems(QuotationSummaryItems_)
    }
    const HandleQuotationSummaryItemRemove = (index) => {

        if (index == 0) return
        let QuotationSummaryItems_ = [...QuotationSummaryItems]
        QuotationSummaryItems_.splice(index, 1)
        setQuotationSummaryItems(QuotationSummaryItems_)
    }
    const HandleQuotationSummaryItemAddNew = () => {

        let QuotationSummaryItems_ = [...QuotationSummaryItems]
        QuotationSummaryItems_.push({
            id: Utils.getUniqueId(),
            name: "",
            amount: "",
            change: "",
        })
        setQuotationSummaryItems(QuotationSummaryItems_)
    }


    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Vendors",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { icon: Icons.general.btn_delete, label: "Yes, Delete" },
                        heading: "Delete item",
                        message: "Are you sure you want to delete?",
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}


            {ShowAddMeasurementPopup ?

                <CreateQuoteMeasurement props={{
                    type: SelectedMeasurementItem ? "edit" : 'create',
                    callback: HandleAddMeasurement,
                    close: CloseAddMeasurementPopup,
                    detials: SelectedMeasurementItem
                }} />

                : null}



            <div className="project-projects-main">
                <div className="projects-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleBackBtn(0)}
                            ></div>
                            <div className="title">{menuType == 'create' ? 'Add New' : 'Edit'} Quotation</div>
                        </div>
                    </div>
                    <form className="panel-content-sections" onSubmit={HandleSubmit}>

                        <div className="quotation-stepper-main">
                            <div className="stepper-items">
                                {StepperDetails.map((item, index) => (
                                    <div
                                        className={`stepper-item stepper-item-${item.status}`}
                                        key={`quotation-stepper-item-${item.id}`}
                                    >
                                        <div className="label">{item.title}</div>
                                        <div className="icon"
                                            dangerouslySetInnerHTML={{
                                                __html: getStepperIcon(index)
                                            }}
                                        ></div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="create-project-content-main">
                            {
                                ActiveStepperIdx == 0 ?

                                    <div className="create-project-sections">
                                        <div className="create-project-section">
                                            <div className="section-input-items">

                                                <div className="section-input-item">
                                                    <TextInput
                                                        props={{
                                                            id: "create-quotation-no",
                                                            value: QuotationNo,
                                                            placeholder: '',
                                                            setValue: (value) => setQuotationNo(value),
                                                            isIcon: false,
                                                            isLabel: true,
                                                            isRequired: true,
                                                            readOnly: true,
                                                            label: "Quotation No",
                                                        }}
                                                    />
                                                </div>
                                                <div className="section-input-item">
                                                    <TextInput
                                                        props={{
                                                            id: "create-quotation-site_name",
                                                            value: SiteName,
                                                            placeholder: '',
                                                            setValue: (value) => setSiteName(value),
                                                            isIcon: false,
                                                            isLabel: true,
                                                            isRequired: true,
                                                            label: "Site Name",
                                                        }}
                                                    />
                                                </div>
                                                <div className="section-input-item">
                                                    <TextInput
                                                        props={{
                                                            id: "create-quotation-site_location",
                                                            value: SiteLocation,
                                                            placeholder: '',
                                                            setValue: (value) => setSiteLocation(value),
                                                            isIcon: false,
                                                            isLabel: true,
                                                            isRequired: false,
                                                            label: "Site Location",
                                                        }}
                                                    />
                                                </div>
                                                <div className="section-input-item">
                                                    <DateTimeInput
                                                        props={{
                                                            id: "create-quotation-date",
                                                            value: Date,
                                                            placeholder: '',
                                                            setValue: (value) => setDate(value),
                                                            isIcon: true,
                                                            isLabel: true,
                                                            icon: Icons.general.schedule,
                                                            isRequired: true,
                                                            label: "Date",
                                                        }}
                                                    />
                                                </div>
                                                <div className="section-input-item">
                                                    <SelectInput
                                                        props={{
                                                            id: "create-quotation-type",
                                                            value: Type,
                                                            placeholder: '',
                                                            readOnly: false,
                                                            options: Type_Options || [],
                                                            setValue: (value, label) => setType(value),
                                                            isStatus: false,
                                                            isIcon: false,
                                                            isLabel: true,
                                                            isRequired: true,
                                                            label: "Site Type",
                                                        }}
                                                    />
                                                </div>
                                                {menuType != 'create' ?
                                                    <div className="section-input-item">
                                                        <SelectInput
                                                            props={{
                                                                id: "create-quotation-status",
                                                                value: Status,
                                                                placeholder: '',
                                                                readOnly: false,
                                                                options: Status_Options || [],
                                                                setValue: (value, label) => setStatus(value),
                                                                isStatus: false,
                                                                isIcon: false,
                                                                isLabel: true,
                                                                isRequired: true,
                                                                label: "Status",
                                                            }}
                                                        />
                                                    </div>
                                                    : ''}

                                                <div className="section-input-item">
                                                    <SelectInput
                                                        props={{
                                                            id: "create-quotation-client",
                                                            value: Client_id,
                                                            placeholder: '',
                                                            readOnly: false,
                                                            options: Client_Options || [],
                                                            setValue: (value, label) => {
                                                                setClient_id(value)
                                                                setClient_name(label)
                                                            },
                                                            isStatus: false,
                                                            isIcon: false,
                                                            isLabel: true,
                                                            isRequired: true,
                                                            label: "Client",
                                                        }}
                                                    />
                                                </div>
                                                <div className="section-input-item">
                                                    <SelectInput
                                                        props={{
                                                            id: "create-quotation-assign_to",
                                                            value: Assign_to_id,
                                                            placeholder: '',
                                                            readOnly: false,
                                                            options: Assignee_Options || [],
                                                            setValue: (value, label) => {
                                                                setAssign_to_id(value)
                                                                setAssign_to_name(label)
                                                            },
                                                            isStatus: false,
                                                            isIcon: false,
                                                            isLabel: true,
                                                            isRequired: false,
                                                            label: "Assign to",
                                                        }}
                                                    />
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    : ActiveStepperIdx == 1 ?
                                        <div className="create-project-sections">

                                            <div className="panel-content-header">
                                                <div className="search-bar"></div>
                                                <div className="buttons">
                                                    {has_add_access ?
                                                        <div
                                                            className="button add-button"
                                                            onClick={(e) => HandleShowAddMeasurementPopup()}
                                                        >
                                                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                                            <div className="label">Add New</div>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>

                                            <div className="catalogue-materials-main">
                                                <div className="catalogue-materials-items">

                                                    {QuotationMeasurements.map((measurement) => (

                                                        <div
                                                            className={`catalogue-materials-item ${measurement.id == CatalogueSelectedMaterial ? 'catalogue-materials-item-active' : ''}`}
                                                            key={`catalogue-materials-item-${measurement.id}`}
                                                        >
                                                            <div
                                                                className="catalogue-materials-item-header"
                                                            >
                                                                <div
                                                                    className="header-items"
                                                                    onClick={(e) => HandleMaterialExpand(measurement.id)}
                                                                >
                                                                    <div className="header-left">
                                                                        <div className={`header-dropdown-icon`}
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                                                        ></div>
                                                                        <div className="header-label"> {measurement.section_name}</div>
                                                                    </div>


                                                                </div>
                                                                <div className="header-right">
                                                                    <div className="header-spec-items">
                                                                        <div className={`header-quality`}>{measurement.quality_name}</div>
                                                                        <div className={`header-price`}>₹{measurement.total_amount}</div>
                                                                    </div>
                                                                    <div className="header-action-items">
                                                                        <div
                                                                            className="header-icon"
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                                                            onClick={(e) => HandleEditItem(measurement)}
                                                                        ></div>
                                                                        <div
                                                                            className="header-icon"
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                            onClick={(e) => HandleDeleteItem(measurement)}
                                                                        ></div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                            <div
                                                                className={`catalogue-materials-item-details quotation-measurement-details-main`}
                                                            >
                                                                {measurement.section_id == "accessories" ?
                                                                    <table className="quotation-measurement-item-table">
                                                                        <thead className='tabel-header'>
                                                                            <tr>
                                                                                <th>SI</th>
                                                                                <th>Item Image</th>
                                                                                <th>Item Name</th>
                                                                                <th>Brand</th>
                                                                                <th>MRP(₹)</th>
                                                                                <th>Quandity</th>
                                                                                <th>Discount(%)</th>
                                                                                <th>Amount</th>
                                                                            </tr>
                                                                        </thead >
                                                                        <tbody className='tabel-body'>
                                                                            {measurement.section_accessories.map((acc, idx) => (
                                                                                <tr key={`quotation-measurement-acc-${acc.id}`}>
                                                                                    <td>{idx + 1}</td>
                                                                                    <td>
                                                                                        <div className="tabel-td-image">
                                                                                            <img src={Utils.loadBase64_Image(acc.attachment)} />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{`${acc.code}-` || ""}{acc.name}</td>
                                                                                    <td>{acc.brand}</td>
                                                                                    <td>₹{acc.price}</td>
                                                                                    <td>{acc.quandity}</td>
                                                                                    <td>{acc.discount || 0}%</td>
                                                                                    <td>₹{acc.amount}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>

                                                                    :
                                                                    <table className="quotation-measurement-item-table">
                                                                        <thead className='tabel-header'>
                                                                            <tr>
                                                                                <th>SI</th>
                                                                                <th>Particular</th>
                                                                                <th>Length</th>
                                                                                <th>Height</th>
                                                                                <th>Depth</th>
                                                                                <th>Area</th>
                                                                                <th>Rate(₹)</th>
                                                                                <th>Amount(₹)</th>
                                                                            </tr>
                                                                        </thead >
                                                                        <tbody className='tabel-body'>
                                                                            {measurement.section_units.map((unit, idx) => (
                                                                                <tr key={`quotation-measurement-unit-${unit.id}`}>
                                                                                    <td>{idx + 1}</td>
                                                                                    <td>{unit.unit_name}</td>
                                                                                    <td>{unit.length}</td>
                                                                                    <td>{unit.height}</td>
                                                                                    <td>{unit.depth}</td>
                                                                                    <td>{unit.area}</td>
                                                                                    <td>₹{unit.rate}</td>
                                                                                    <td>₹{unit.amount}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                }

                                                            </div>
                                                        </div>

                                                    ))}


                                                </div>
                                            </div>


                                        </div>
                                        : ActiveStepperIdx == 2 ?
                                            <div className="create-project-sections">

                                                <div className="quotation-terms-section-main">
                                                    <div className="quotation-terms-summary-main">

                                                        <div className="quotation-terms-summary-header">FEW OTHER OPTIONS TO EXCECUTE INTERIOR</div>

                                                        <div className="payment-splits-main quotation-summary-spec-main">
                                                            <div className="payment-splits-header">
                                                                <div className="header-item-sno">SI</div>
                                                                <div className="header-item-name">Other Options</div>
                                                                <div className="header-item-milestone">Amount</div>
                                                                <div className="header-item-milestone">% Change</div>
                                                                <div className="header-item-action"></div>
                                                            </div>
                                                            <div className="payment-splits-content">
                                                                {QuotationSummaryItems.map((item, index) => (
                                                                    <div className="payment-content-items">
                                                                        <div className="payment-content-sno">{index + 1}</div>
                                                                        <div className="payment-content-name">
                                                                            <TextInput
                                                                                props={{
                                                                                    id: `catalogue-material-spec-name-${item.id}`,
                                                                                    value: item.name,
                                                                                    placeholder: 'Enter Label',
                                                                                    setValue: (value) => HandleQuotationSummaryItemInputChange(value, index, 'name'),
                                                                                    isIcon: false,
                                                                                    isLabel: false,
                                                                                    isRequired: true,
                                                                                    label: "",
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="payment-content-milestone">
                                                                            <TextInput
                                                                                props={{
                                                                                    id: `catalogue-material-spec-amount-${item.id}`,
                                                                                    value: item.amount,
                                                                                    type: 'number',
                                                                                    placeholder: 'Enter Amount',
                                                                                    setValue: (value) => HandleQuotationSummaryItemInputChange(value, index, 'amount'),
                                                                                    isIcon: false,
                                                                                    isLabel: false,
                                                                                    isRequired: true,
                                                                                    label: "",
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="payment-content-milestone">
                                                                            <TextInput
                                                                                props={{
                                                                                    id: `catalogue-material-spec-change-${item.id}`,
                                                                                    value: item.change,
                                                                                    placeholder: 'Enter Change %',
                                                                                    type: 'number',
                                                                                    setValue: (value) => HandleQuotationSummaryItemInputChange(value, index, 'change'),
                                                                                    isIcon: false,
                                                                                    isLabel: false,
                                                                                    isRequired: false,
                                                                                    label: "",
                                                                                }}
                                                                            />
                                                                        </div>


                                                                        <div className="payment-content-action">
                                                                            <div
                                                                                className="action-remove"
                                                                                onClick={(e) => HandleQuotationSummaryItemRemove(index)}
                                                                            >
                                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div
                                                                className="payment-splits-action-buttons"
                                                            >
                                                                <div
                                                                    className="add-button"
                                                                    onClick={(e) => HandleQuotationSummaryItemAddNew()}
                                                                >
                                                                    <div
                                                                        className="icon"
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_active }}
                                                                    ></div>
                                                                    <div className="label">Add new</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="clients-popup-content" style={{
                                                        padding: '1rem 0',
                                                    }}>

                                                        <div className={`createproposal-description-main`}>
                                                            <div className="head">
                                                                <div className="title">
                                                                    <div className="label">General Work Pattern</div>
                                                                </div>
                                                            </div>
                                                            <div className="select-input">
                                                                <div className="section-input-items" style={{ paddingTop: 0 }}>
                                                                    <div className="section-input-item">
                                                                        <SelectInput
                                                                            props={{
                                                                                id: "create-quotation-work_pattern-input",
                                                                                value: WorkPatternId,
                                                                                placeholder: '',
                                                                                readOnly: false,
                                                                                options: WorkPatternOptions || [],
                                                                                setValue: (value, label) => HandleWorkPattern(value, label),
                                                                                isStatus: false,
                                                                                isIcon: false,
                                                                                isLabel: false,
                                                                                isRequired: false,
                                                                                label: "",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="terms-condition-result" style={{
                                                                height: '200px',
                                                            }}>
                                                                <ReactQuill
                                                                    theme="snow"
                                                                    value={WorkPatternValue}
                                                                    onChange={(value) => setWorkPatternValue(value)}
                                                                    style={{
                                                                        height: '150px',
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={`createproposal-description-main`}>
                                                            <div className="head">
                                                                <div className="title">
                                                                    <div className="label">Terms & conditions</div>
                                                                </div>
                                                            </div>
                                                            <div className="select-input">
                                                                <div className="section-input-items" style={{ paddingTop: 0 }}>
                                                                    <div className="section-input-item">
                                                                        <SelectInput
                                                                            props={{
                                                                                id: "create-client_invoiceterms_conditions-input",
                                                                                value: termsConditionId,
                                                                                placeholder: '',
                                                                                readOnly: false,
                                                                                options: termsConditionOptions || [],
                                                                                setValue: (value, label) => HandleTermsConditions(value, label),
                                                                                isStatus: false,
                                                                                isIcon: false,
                                                                                isLabel: false,
                                                                                isRequired: false,
                                                                                label: "",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="terms-condition-result" style={{
                                                                height: '200px',
                                                            }}>
                                                                <ReactQuill
                                                                    theme="snow"
                                                                    value={termsConditionValue}
                                                                    onChange={(value) => setTermsConditionValue(value)}
                                                                    style={{
                                                                        height: '150px',
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>




                                            </div>
                                            : ''
                            }
                            <div className="create-project-footer">
                                <div className="footer-item action-items">

                                    <div className="action-cancel" onClick={(e) => HandleBackBtn(ActiveStepperIdx)}>
                                        {ActiveStepperIdx == 0 ? 'Cancel' : 'Back'}
                                    </div>
                                    <button
                                        className={`action-btn`}
                                        type='submit'
                                    >
                                        <div className="label">
                                            {ActiveStepperIdx == StepperDetails.length - 1 ? 'Save' : 'Next'}
                                        </div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </>
    )

}

export default CreateQuotation;