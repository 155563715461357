

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import QuotationHandler from '../../Handlers/Quotation/Quotation';
import CatalogueHandler from '../../Handlers/Catalogue/Catalogue';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import SmarkTopCards from '../../components/SmarkTopCards'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const Quotations = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const quotationHandler = new QuotationHandler()
    const catalogueHandler = new CatalogueHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Clients')

    const [ClientDetials_original, setClientDetials_original] = useState([])
    const [ClientDetials, setClientDetials] = useState([])
    const [selectedAction, setSelectedAction] = useState('')

    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--clients', feature_key: 'clients' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)



    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,

        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,

        },
    ])


    const tabelHeader = [
        {
            id: 'idx',
            label: 'S:NO',

        },
        {
            id: 'date',
            label: 'Date',
        },
        {
            id: 'site_name',
            label: 'Site Name',
        },
        {
            id: 'site_location',
            label: 'Location',
        },
        {
            id: 'client_name',
            label: 'Client',
        },
        {
            id: 'assign_to_name',
            label: 'Assignee',

        },
        {
            id: 'status',
            label: 'Status',
            format: (value) => {
                if (value == "0") return "Pending"
                if (value == "1") return "In Progress"
                if (value == "2") return "Completed"
                if (value == "3") return "Revised"
            },

        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            format: (value) => value,
        },
    ];

    const getQuotations = async () => {

        setIsLoading(true)

        let response = await quotationHandler.getQuotationBasicsHandler()

        setIsLoading(false)

        if (response && response.success) {

            let quotations = response.data || []

            quotations = quotations.map((itm, idx) => {
                itm.idx = idx + 1

                return itm
            })

            setClientDetials_original(quotations)
            setClientDetials(quotations)

            HandleSmartCardSetup(quotations)


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Clients, Please try again!`)
        }
    }



    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleAddNewItem = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/create`)
    }
    const HandlePreviewItem = (item) => {

        navigate(`${window.location.pathname}/preview/${item.sno}`)
    }
    const HandleEditItem = (item) => {
        if (!has_edit_access) return

        navigate(`${window.location.pathname}/edit/${item.sno}`)
    }
    const HandleDeleteItem = async (item) => {


        if (!has_edit_access || !has_delete_access) return

        setDeleteItem(item.sno)
        setDeleteItemAlert(true)

    }

    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        if (!confirmation || id == undefined) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }

        let response = await quotationHandler.deleteQuotationHandler({
            sno: String(id)
        })

        if (response && response.success) {

            getQuotations()


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Material, Please try again!`)

        }


        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }


    const HanelCellAction = (id, row) => {
        if (id == "name") HandleEditItem(row)

    }




    useEffect(() => {
        getQuotations()
    }, [])

    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (items) => {

        items = items || []

        let total = items.length
        let total_inprogress = items.filter(itm => itm.status == "1").length
        let total_completed = items.filter(itm => itm.status == "2").length

        let smark_cards = [
            {
                id: 'lm-smart-top-cards-total',
                label: 'All Quotations',
                value: total || "0",
                icon: Icons.general.quotation,
                unit_name: '',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },
            {
                id: 'lm-smart-top-cards-pending',
                label: 'Pending Quotations',
                value: total_inprogress || "0",
                icon: Icons.general.quotation,
                unit_name: '',
                style: {
                    color: '#ff5b5b',
                    bg_linear_colors: {
                        start: '#ffeded',
                        end: '#fff9f9'
                    },
                    border_color: '#dcd1d1',
                },
            },
            {
                id: 'lm-smart-top-cards-converted',
                label: 'Completed Quotations',
                value: total_completed || "0",
                icon: Icons.general.quotation,
                unit_name: '',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
        ]

        setSmarkCard_Items(smark_cards)

    }

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Clients",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { icon: Icons.general.btn_delete, label: "Yes, Delete" },
                        heading: "Delete item",
                        message: "Are you sure you want to delete?",
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <div className="project-clients-main">
                <div className="clients-panel-content">

                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}
                    <div className="smart-panel-content-main">
                        <div className="panel-content-header">
                            <div className="search-bar">
                                <input
                                    type="text"
                                    placeholder='Search clients here...'
                                />
                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.search }}></div>
                            </div>
                            <div className="buttons">
                                {has_add_access ?
                                    <div
                                        className="button add-button"
                                        onClick={(e) => HandleAddNewItem()}
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                        <div className="label">Add New</div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        <div className="panel-content-sections panel-content-tabel-section">
                            <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                    <Table stickyHeader>
                                        <TableHead
                                            className='tabel-header'
                                        >
                                            <TableRow>
                                                {tabelHeader.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                        className='tabel-header-items'
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='tabel-body'>
                                            {ClientDetials
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                                                            {tabelHeader.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        onClick={(e) => {
                                                                            if (column.id != 'actions') HanelCellAction(column.id, row)
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.id == 'quotation_no' ?
                                                                                <div
                                                                                    className="tabel-name"
                                                                                >
                                                                                    {column.format ? column.format(value) : value}
                                                                                </div>

                                                                                : column.id == 'status' ?
                                                                                    <div
                                                                                        className={`tabel-status tabel-status-${value}`}
                                                                                    >
                                                                                        {column.format ? column.format(value) : value}
                                                                                    </div>

                                                                                    : column.id == 'actions' && has_edit_access ?

                                                                                        <div
                                                                                            className="tabel-action-main"
                                                                                        >
                                                                                            {has_view_access ?
                                                                                                <div
                                                                                                    className="action-icon"
                                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.view_btn }}
                                                                                                    onClick={(e) => HandlePreviewItem(row)}
                                                                                                ></div>
                                                                                                : ''}
                                                                                            {has_edit_access ?
                                                                                                <div
                                                                                                    className="action-icon"
                                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                                                                                    onClick={(e) => HandleEditItem(row)}
                                                                                                ></div>
                                                                                                : ''}
                                                                                            {has_delete_access ?
                                                                                                <div
                                                                                                    className="action-icon"
                                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                                                    onClick={(e) => HandleDeleteItem(row)}
                                                                                                ></div>
                                                                                                : ''}

                                                                                        </div>
                                                                                        : column.format ? column.format(value) : value
                                                                        }
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    style={{ height: '7%', overflow: 'hidden' }}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={ClientDetials.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Quotations;