
import Logo from './images/logo.png'
import Logo_light from './images/logo1.png'
import Logo_Small from './images/logo_small.png'
import Logo_Large from './images/logo_large.png'
import Login_banner from './images/banner.png'
import Login_bg from './images/background_1.svg'
import User from './images/user.png'
import LandingTopBanner from './images/landing_top_banner.png'
import Estimate_feature from './images/estimate_feature.mp4'
import Schedule_feature from './images/schedule_feature.mp4'
import Takeoff_feature from './images/illustration_takeoff_feature.webp'
import Proposal_feature from './images/illustration_proposal_feature.webp'
import Workdemo from './images/workdemo.webm'
import Workdemo_Png from './images/workdemo.png'
import AnyDevicesAccess from './images/all_devices.png'
import AppBanner from './images/app_banner.png'
import NoIssues from './images/noIssues.png'
import NoPhotos from './images/no_photos.png'

import Request_demo_banner_1 from './images/request-demo-banner-1.jpg'
import Request_demo_banner_2 from './images/request-demo-banner-2.jpg'
import Request_demo_banner_3 from './images/request-demo-banner-3.jpg'
import Request_demo_banner_4 from './images/request-demo-banner-4.jpg'

import Company_Owner from './images/company-ownder.jpg'
import Project_Manager from './images/project-manager.jpg'
import Site_Engineer from './images/site-engineer.jpg'

import Benefits from './images/benefits.jpg'

import Customer1 from './images/aristo.webp'
import Customer2 from './images/design-code.webp'
import Customer3 from './images/dnova-logo.webp'

import Kitchen from './images/sections/kitchen.png'
import Accessories from './images/sections/accessories.png'
import Living_hall from './images/sections/living_hall.png'
import Bed_room from './images/sections/bed_room.png'
import Dining_hall from './images/sections/dining_hall.png'
import False_ceiling from './images/sections/false_ceiling.png'
import Sitout from './images/sections/sitout.png'
import Utility from './images/sections/utility.png'

const Images = {
    logo: Logo,
    logo_light: Logo_light,
    logo_small: Logo_Small,
    logo_large: Logo_Large,
    login_banner: Login_banner,
    Login_bg: Login_bg,
    User: User,
    LandingTopBanner: LandingTopBanner,
    Estimate_feature: Estimate_feature,
    Schedule_feature: Schedule_feature,
    Takeoff_feature: Takeoff_feature,
    Proposal_feature: Proposal_feature,
    Workdemo: Workdemo,
    _Workdemo: Workdemo_Png,
    AnyDevicesAccess: AnyDevicesAccess,
    AppBanner: AppBanner,
    NoIssues: NoIssues,
    NoPhotos: NoPhotos,

    Company_Owner: Company_Owner,
    Project_Manager: Project_Manager,
    Site_Engineer: Site_Engineer,

    Benefits: Benefits,

    request_demo_banners: {
        b1: Request_demo_banner_1,
        b2: Request_demo_banner_2,
        b3: Request_demo_banner_3,
        b4: Request_demo_banner_4
    },

    customers: {
        customer1: Customer1,
        customer2: Customer2,
        customer3: Customer3
    },

    sections: {
        kitchen: Kitchen,
        accessories: Accessories,
        living_hall: Living_hall,
        bed_room: Bed_room,
        dining_hall: Dining_hall,
        false_ceiling: False_ceiling,
        sitout: Sitout,
        utility: Utility,
    }
}

export default Images