

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import StoreHandler from '../../redux/StoreHandler'

import ReverseProxy from '../../config/reverseProxy'
import CompanyHandler from '../../Handlers/Company/Company';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'


import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'
const Company = () => {

    const companyHandler = new CompanyHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [companyName, setCompanyName] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [companyPhone, setCompanyPhone] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [companyCity, setCompanyCity] = useState('')
    const [companyPINCode, setCompanyPINCode] = useState('')
    const [companyWebsite, setCompanyWebsite] = useState('')
    const [companyTimezone, setCompanyTimezone] = useState('1')

    const [statutoryDetials, setStatutoryDetials] = useState({
        PAN: '',
        LST: '',
        GST: '',
        CST: '',
        TAN: '',
        TIN: '',
        ST: '',
    })
    const [socialLinks, setSocialLinks] = useState({
        facebook: '',
        instagram: '',
        linkedin: '',
        twitter: '',
        googlebusiness: '',
    })



    const [stateOptions, setCityOptions] = useState([

        {
            id: 'Tamil-Nadu',
            label: 'Tamil Nadu',
            value: 'Tamil Nadu'
        },
        {
            id: 'Andhra-Pradesh',
            label: 'Andhra Pradesh',
            value: 'Andhra Pradesh'
        },
        {
            id: 'Arunachal-Pradesh',
            label: 'Arunachal Pradesh',
            value: 'Arunachal Pradesh'
        },
        {
            id: 'Assam',
            label: 'Assam',
            value: 'Assam'
        },
        {
            id: 'Bihar',
            label: 'Bihar',
            value: 'Bihar'
        },
        {
            id: 'Chhattisgarh',
            label: 'Chhattisgarh',
            value: 'Chhattisgarh'
        },
        {
            id: 'Goa',
            label: 'Goa',
            value: 'Goa'
        },
        {
            id: 'Gujarat',
            label: 'Gujarat',
            value: 'Gujarat'
        },
        {
            id: 'Haryana',
            label: 'Haryana',
            value: 'Haryana'
        },
        {
            id: 'Himachal-Pradesh',
            label: 'Himachal Pradesh',
            value: 'Himachal Pradesh'
        },
        {
            id: 'Jharkhand',
            label: 'Jharkhand',
            value: 'Jharkhand'
        },
        {
            id: 'Karnataka',
            label: 'Karnataka',
            value: 'Karnataka'
        },
        {
            id: 'Kerala',
            label: 'Kerala',
            value: 'Kerala'
        },
        {
            id: 'Madhya-Pradesh',
            label: 'Madhya Pradesh',
            value: 'Madhya Pradesh'
        },
        {
            id: 'Maharashtra',
            label: 'Maharashtra',
            value: 'Maharashtra'
        },
        {
            id: 'Manipur',
            label: 'Manipur',
            value: 'Manipur'
        },
        {
            id: 'Meghalaya',
            label: 'Meghalaya',
            value: 'Meghalaya'
        },
        {
            id: 'Mizoram',
            label: 'Mizoram',
            value: 'Mizoram'
        },
        {
            id: 'Nagaland',
            label: 'Nagaland',
            value: 'Nagaland'
        },
        {
            id: 'Odisha',
            label: 'Odisha',
            value: 'Odisha'
        },
        {
            id: 'Punjab',
            label: 'Punjab',
            value: 'Punjab'
        },
        {
            id: 'Rajasthan',
            label: 'Rajasthan',
            value: 'Rajasthan'
        },
        {
            id: 'Sikkim',
            label: 'Sikkim',
            value: 'Sikkim'
        },

        {
            id: 'Telangana',
            label: 'Telangana',
            value: 'Telangana'
        },
        {
            id: 'Tripura',
            label: 'Tripura',
            value: 'Tripura'
        },
        {
            id: 'Uttar-Pradesh',
            label: 'Uttar Pradesh',
            value: 'Uttar Pradesh'
        },
        {
            id: 'Uttarakhand',
            label: 'Uttarakhand',
            value: 'Uttarakhand'
        },
        {
            id: 'West-Bengal',
            label: 'West Bengal',
            value: 'West Bengal'
        },
        {
            id: 'Andaman-and-Nicobar-Islands',
            label: 'Andaman and Nicobar Islands',
            value: 'Andaman and Nicobar Islands'
        },
        {
            id: 'Chandigarh',
            label: 'Chandigarh',
            value: 'Chandigarh'
        },
        {
            id: 'Dadra-and-Nagar-Haveli-and-Daman-and-Diu',
            label: 'Dadra and Nagar Haveli and Daman and Diu',
            value: 'Dadra and Nagar Haveli and Daman and Diu'
        },
        {
            id: 'Lakshadweep',
            label: 'Lakshadweep',
            value: 'Lakshadweep'
        },
        {
            id: 'Delhi',
            label: 'Delhi',
            value: 'Delhi'
        },
        {
            id: 'Puducherry',
            label: 'Puducherry',
            value: 'Puducherry'
        }
    ])



    const [companyTimezoneOptions, setCompanyTimezoneOptions] = useState([{ value: '1', label: '1' }])

    const [CompanyLogoImg_url, setCompanyLogoImg_url] = useState('')

    const [CompanyLogo, setCompanyLogo] = useState({})
    const [companyDetials, setCompanyDetials] = useState({})

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })
    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const navigate = useNavigate()
    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'settings--company_profile' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const SetStatutoryDetials = (type, value) => {
        setStatutoryDetials((prevSocialLinks) => ({
            ...prevSocialLinks,
            [type]: value,
        }));
    }
    const SetSocialLink = (type, value) => {
        setSocialLinks((prevSocialLinks) => ({
            ...prevSocialLinks,
            [type]: value,
        }));
    }

    const HandleformSubmit = async (e) => {
        e.preventDefault()

        if (!has_edit_access) return

        const formData = new FormData();

        formData.append('name', companyName)
        formData.append('phone', companyPhone)
        formData.append('email', companyEmail)
        formData.append('address', companyAddress)
        formData.append('city', companyCity)
        formData.append('pincode', companyPINCode)
        formData.append('timezone', companyTimezone)
        formData.append('website', companyWebsite)
        formData.append('social_facebook', socialLinks.facebook)
        formData.append('social_linkedin', socialLinks.linkedin)
        formData.append('social_instagram', socialLinks.instagram)
        formData.append('social_twitter', socialLinks.twitter)
        formData.append('social_googlebusiness', socialLinks.googlebusiness)

        formData.append('PAN_no', statutoryDetials.PAN)
        formData.append('CST_no', statutoryDetials.CST)
        formData.append('TIN_no', statutoryDetials.TIN)
        formData.append('LST_no', statutoryDetials.LST)
        formData.append('TAN_no', statutoryDetials.TAN)
        formData.append('GST_no', statutoryDetials.GST)
        formData.append('ST_no', statutoryDetials.ST)

        formData.append('logo_img', CompanyLogo)

        setIsLoading(true)

        let response = await companyHandler.updateCompanyProfileHandler(formData)

        if (response.success) {
            LoadStoreData()
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating company profile`)
        }
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (!file) return

        setCompanyLogo(file);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setCompanyLogoImg_url(reader.result)
        };


    };
    const uploadImage = (event) => {

        let input = document.getElementById('userprofile-profile-image')
        input.click()
    };

    const isFile = (variable) => {
        return variable !== null && typeof variable === 'object' && variable.constructor === File;
    }

    useEffect(() => {

        let loadImg = () => {

            if (!isFile(CompanyLogo)) return

            let input = document.getElementById('userprofile-profile-image')
            const fileList = new DataTransfer();
            fileList.items.add(CompanyLogo);

            input.files = fileList.files;
        }

        loadImg()

    }, [CompanyLogo])


    useEffect(() => {

        if (store.user.company) setCompanyDetials(store.user.company)

    }, [store])

    useEffect(() => {

        if (companyDetials) {

            if (companyDetials.name) setCompanyName(companyDetials.name)
            if (companyDetials.phone) setCompanyPhone(companyDetials.phone)
            if (companyDetials.email) setCompanyEmail(companyDetials.email)
            if (companyDetials.address) setCompanyAddress(companyDetials.address)
            if (companyDetials.website) setCompanyWebsite(companyDetials.website)
            if (companyDetials.timezone) setCompanyTimezone(companyDetials.timezone)
            if (companyDetials.city) setCompanyCity(companyDetials.city)
            if (companyDetials.pincode) setCompanyPINCode(companyDetials.pincode)
            if (companyDetials) setSocialLinks({
                facebook: companyDetials.social_facebook || '',
                instagram: companyDetials.social_instagram || '',
                linkedin: companyDetials.social_linkedin || '',
                twitter: companyDetials.social_twitter || '',
                googlebusiness: companyDetials.social_googlebusiness || '',
            })
            if (companyDetials) setStatutoryDetials({
                PAN: companyDetials.PAN_no || "",
                LST: companyDetials.LST_no || "",
                GST: companyDetials.GST_no || "",
                CST: companyDetials.CST_no || "",
                TAN: companyDetials.TAN_no || "",
                TIN: companyDetials.TIN_no || "",
                ST: companyDetials.ST_no || "",
            })

            if (companyDetials.logo_img) {
                setCompanyLogo(Utils.dataURLtoFile(`data:image/png;base64,${companyDetials.logo_img}`, companyDetials.name))
                setCompanyLogoImg_url(`data:image/png;base64,${companyDetials.logo_img}`)
            }
        }



    }, [companyDetials])

    return (

        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Company Profile",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <form className="settings-panel-content" onSubmit={HandleformSubmit}>
                <div className="panel-content-main">

                    <div className="panel-content-header">
                        <div className="title">Company information</div>
                    </div>
                    <div className="panel-content-sections">


                        <div className="profile-image-main">
                            <div className="profile-image">
                                <img src={CompanyLogoImg_url || Images.User} />
                            </div>
                            <div className="profile-image-btn">

                                <span className='label'>Company Logo</span>
                                <span className='sm-label'>PNG or JPG (max 1MB)</span>
                                <input className='upload-input' accept="image/jpg, image/jpeg, image/png" onChange={(e) => handleImageChange(e)} type="file" id='userprofile-profile-image' />
                                <span className='upload-button' onClick={uploadImage}>Choose Image</span>
                            </div>
                        </div>

                        <div className="panel-content-section">
                            <div className="content-items content-sub-items">
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-companyName",
                                            value: companyName,
                                            placeholder: '',
                                            setValue: (value) => setCompanyName(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Name",
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-companyEmail",
                                            value: companyEmail,
                                            readOnly: false,
                                            placeholder: '',
                                            setValue: (value) => setCompanyEmail(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Email",
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-companyPhone",
                                            value: companyPhone,
                                            placeholder: '',
                                            setValue: (value) => setCompanyPhone(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Phone",
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-companyWebsite",
                                            value: companyWebsite,
                                            readOnly: false,
                                            placeholder: '',
                                            setValue: (value) => setCompanyWebsite(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "Company Website",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="content-items content-sub-items">
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-companyAddress",
                                            value: companyAddress,
                                            placeholder: '',
                                            setValue: (value) => setCompanyAddress(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Address",
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "createclient-city",
                                            value: companyCity,
                                            placeholder: '',
                                            options: stateOptions || [],
                                            setValue: (value, label) => {
                                                setCompanyCity(value)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "State",
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-companyPINCode",
                                            value: companyPINCode,
                                            placeholder: '',
                                            setValue: (value) => setCompanyPINCode(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "PIN Code",
                                        }}
                                    />
                                </div>

                                <div className="content-item" >
                                    {/* <SelectInput
                                        props={{
                                            id: "createclient-companyTimezone",
                                            value: companyTimezone,
                                            placeholder: '',
                                            options: companyTimezoneOptions || [],
                                            setValue: (value, label) => {
                                                setCompanyTimezone(value)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "Time zone",
                                        }}
                                    /> */}
                                </div>

                            </div>
                        </div>
                        <div className="panel-content-section">
                            <div className="section-title">Statutory Detials</div>
                            <div className="content-items content-sub-items">
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-social-PAN",
                                            value: statutoryDetials.PAN,
                                            placeholder: '',
                                            setValue: (value) => SetStatutoryDetials('PAN', value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "PAN No",
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-social-GST",
                                            value: statutoryDetials.GST,
                                            placeholder: '',
                                            setValue: (value) => SetStatutoryDetials('GST', value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "GST No",
                                        }}
                                    />
                                </div>
                            </div>


                        </div>
                        <div className="panel-content-section" style={{ display: 'none' }}>
                            <div className="section-title">Social media</div>
                            <div className="content-items content-sub-items">
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-social-facebook",
                                            value: socialLinks.facebook,
                                            placeholder: '',
                                            setValue: (value) => SetSocialLink('facebook', value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "Facebook",
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-social-linkedin",
                                            value: socialLinks.linkedin,
                                            placeholder: '',
                                            setValue: (value) => SetSocialLink('linkedin', value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "Linkedin",
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-social-instagram",
                                            value: socialLinks.instagram,
                                            placeholder: '',
                                            setValue: (value) => SetSocialLink('instagram', value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "Instagram",
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="content-items content-sub-items">
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-social-twitter",
                                            value: socialLinks.twitter,
                                            placeholder: '',
                                            setValue: (value) => SetSocialLink('twitter', value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "Twitter",
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-social-googlebusiness",
                                            value: socialLinks.googlebusiness,
                                            placeholder: '',
                                            setValue: (value) => SetSocialLink('googlebusiness', value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "Google my business",
                                        }}
                                    />
                                </div>
                                <div className="content-item"></div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="panel-content-footer">
                    <div className="content-footer-items">
                        <button className="footer-button">
                            <div className="label">Cancel</div>
                        </button>
                        <button type='submit' className="footer-button footer-button-active">
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.save }}></div>
                            <div className="label">Save Changes</div>
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Company;
