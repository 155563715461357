
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Vendors {

    constructor() {

    }


    async getVendorsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/vendors/get-vendors', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async getVendorBasicsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/vendors/get-vendor-basics', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getNextVendorCodeHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/vendors/get-next-vendorId', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createVendorHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/vendors/create-vendor', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateVendorHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/vendors/update-vendor', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteVendorHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/vendors/delete-vendor', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default Vendors;