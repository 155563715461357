

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams, useFetcher } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CatalogueHandler from '../../Handlers/Catalogue/Catalogue';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import Sections from '../../config/sections'

const AddMaterial = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { materialId } = useParams()

    const catalogueHandler = new CatalogueHandler()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Catalogue Materials')

    const [CatalogueQualites, setCatalogueQualites] = useState([])

    const [SectionOptions, setSectionOptions] = useState([...Sections])

    const [SNO, setSNO] = useState('')
    const [Name, setName] = useState('')
    const [Description, setDescription] = useState('')
    const [Quality_id, setQuality_id] = useState('')
    const [Quality_name, setQuality_name] = useState('')
    const [Section_id, setSection_id] = useState('')
    const [Section_name, setSection_name] = useState('')

    const navigate = useNavigate()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--clients', feature_key: 'clients' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (type == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })
            if (type == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,

        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,

        },
    ])

    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {

        e.preventDefault()

        let updateta = {
            name: Name,
            description: Description,

            quality_id: String(Quality_id),
            quality_name: Quality_name,

            section_id: String(Section_id),
            section_name: Section_name,

            material_specifications: MaterialSpecifications
        }

        let response = { success: false }

        if (type == 'edit') {

            updateta["sno"] = materialId
            response = await catalogueHandler.updateMaterialHandler(updateta)

        }

        else response = await catalogueHandler.createMaterialHandler(updateta)

        if (response.success) {
            callback()
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Materials, Please try again!`)
        }

        close(type)
    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <button
                            className={`action-btn action-${type}`}

                        >
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.save }}></div>
                            <div className="label">Save</div>
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const [MaterialSpecifications, setMaterialSpecifications] = useState([
        {
            id: Utils.getUniqueId(),
            label: "",
            value: ""
        }
    ])

    const HandleMaterialSpecInputChange = (value, index, key) => {

        let MaterialSpecifications_ = [...MaterialSpecifications]
        MaterialSpecifications_[index][key] = value

        setMaterialSpecifications(MaterialSpecifications_)
    }
    const HandleMaterialSpecRemove = (index) => {

        if (index == 0) return
        let MaterialSpecifications_ = [...MaterialSpecifications]
        MaterialSpecifications_.splice(index, 1)
        setMaterialSpecifications(MaterialSpecifications_)
    }
    const HandleMaterialSpecAddNew = () => {

        let MaterialSpecifications_ = [...MaterialSpecifications]
        MaterialSpecifications_.push({
            id: Utils.getUniqueId(),
            label: "",
            value: ""
        })
        setMaterialSpecifications(MaterialSpecifications_)
    }

    const getCatalougeQualities = async () => {

        setIsLoading(true)

        let response = await catalogueHandler.getQualitiesHandler()

        setIsLoading(false)

        if (response && response.success) {

            let qualities = response.data || []

            qualities = qualities.map((item) => {
                return {
                    value: item.sno,
                    label: item.name
                }
            })

            setCatalogueQualites(qualities)
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Materials, Please try again!`)
        }
    }

    useEffect(() => {

        getCatalougeQualities()

        if (type == 'edit' && materialId) {

            const LoadTemplate = async (materialId) => {

                if (Object.keys(detials).length) {

                    let { sno, name, description, quality_id, quality_name, section_id, section_name, material_specifications } = detials

                    setSNO(sno)
                    setName(name)
                    setQuality_id(quality_id)
                    setQuality_name(quality_name)
                    setSection_id(section_id)
                    setSection_name(section_name)
                    setMaterialSpecifications(material_specifications)
                    setDescription(description)

                }
                else {

                    let response = await catalogueHandler.getQualitiesHandler({
                        sno: materialId
                    })

                    if (response && response.success && Array.isArray(response.data) && response.data.length) {

                        let { sno, name, description, quality_id, quality_name, section_id, section_name, material_specifications } = response.data[0]

                        setSNO(sno)
                        setName(name)
                        setQuality_id(quality_id)
                        setQuality_name(quality_name)
                        setSection_id(section_id)
                        setSection_name(section_name)
                        setMaterialSpecifications(material_specifications)
                        setDescription(description)
                    }
                    else {
                        setWarningAlert(true)
                        setWarningAlertType('error')
                        setApiFailedMessage(`Error in Catalogue Materials, Please try again!`)
                    }
                }
            }

            LoadTemplate(materialId)
        }
    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Catalogue Materials",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main ">
                <div className="popup-block-ui"></div>
                <form className="side-popup-container" onSubmit={HandlePopupSubmit}>
                    <Popup_Header />
                    <div className="clients-popup-content">

                        <div className="content-items">
                            <TextInput
                                props={{
                                    id: "clients-name",
                                    value: Name,
                                    placeholder: '',
                                    setValue: (value) => setName(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>
                        <div className="content-items">
                            <SelectInput
                                props={{
                                    id: "catalogue-material-quality",
                                    value: Quality_id,
                                    placeholder: '',
                                    options: CatalogueQualites || [],
                                    setValue: (value, label) => {
                                        setQuality_id(value)
                                        setQuality_name(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Choose Quality",
                                }}
                            />
                        </div>
                        <div className="content-items">
                            <SelectInput
                                props={{
                                    id: "catalogue-material-section",
                                    value: Section_id,
                                    placeholder: '',
                                    options: SectionOptions || [],
                                    setValue: (value, label) => {
                                        setSection_id(value)
                                        setSection_name(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Choose Section",
                                }}
                            />
                        </div>
                        <div className="content-items">

                            <div className="content-item-address">
                                <textarea
                                    id="clients-address"
                                    value={Description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder='Description'
                                ></textarea>
                            </div>

                        </div>
                        <div className="content-items">

                            <div className="payment-splits-main catalogue-material-spec-main">
                                <div className="payment-splits-header">
                                    <div className="header-item-name">Specifications</div>
                                    <div className="header-item-milestone"></div>
                                    <div className="header-item-action"></div>
                                </div>
                                <div className="payment-splits-content">
                                    {MaterialSpecifications.map((item, index) => (
                                        <div className="payment-content-items">
                                            <div className="payment-content-name">
                                                <TextInput
                                                    props={{
                                                        id: `catalogue-material-spec-name-${item.id}`,
                                                        value: item.label,
                                                        placeholder: 'Enter Spec Label',
                                                        setValue: (value) => HandleMaterialSpecInputChange(value, index, 'label'),
                                                        isIcon: false,
                                                        isLabel: false,
                                                        isRequired: true,
                                                        label: "",
                                                    }}
                                                />
                                            </div>
                                            <div className="payment-content-milestone">
                                                <TextInput
                                                    props={{
                                                        id: `catalogue-material-spec-value-${item.id}`,
                                                        value: item.value,
                                                        placeholder: 'Enter Spec Value',
                                                        setValue: (value) => HandleMaterialSpecInputChange(value, index, 'value'),
                                                        isIcon: false,
                                                        isLabel: false,
                                                        isRequired: true,
                                                        label: "",
                                                    }}
                                                />
                                            </div>


                                            <div className="payment-content-action">
                                                <div
                                                    className="action-remove"
                                                    onClick={(e) => HandleMaterialSpecRemove(index)}
                                                >
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className="payment-splits-action-buttons"
                                >
                                    <div
                                        className="add-button"
                                        onClick={(e) => HandleMaterialSpecAddNew()}
                                    >
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.add_active }}
                                        ></div>
                                        <div className="label">Add new</div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>
                    <Popup_Footer />
                </form>
            </div>
        </>
    )
}

const CatalogueMaterials = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const catalogueHandler = new CatalogueHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Catalogue Materials')

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--clients', feature_key: 'clients' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [Selected_Material, setSelected_Material] = useState({})

    const [selectedAction, setSelectedAction] = useState('')

    const [CatalogueSelectedMaterial, setCatalogueSelectedMaterial] = useState('')

    const [CatalogueMaterials, setCatalogueMaterials] = useState([])

    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const HandleMaterialExpand = (id) => {

        if (CatalogueSelectedMaterial == id) setCatalogueSelectedMaterial('')
        else setCatalogueSelectedMaterial(id)
    }

    const HandleAddNew = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/create-material`)
    }
    const HandleEditItem = (item) => {

        if (!has_edit_access) return
        setSelected_Material(item)

        navigate(`${window.location.pathname}/edit-material/${item.sno}`)
    }

    const HandleDeleteItem = async (item) => {

        if (!has_edit_access || !has_delete_access) return

        setDeleteItem(item.sno)
        setDeleteItemAlert(true)
    }

    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        if (!confirmation || id == undefined) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }

        let response = await catalogueHandler.deleteMaterialHandler({
            sno: String(id)
        })

        if (response && response.success) {

            getCatalogueMaterials()


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Material, Please try again!`)

        }


        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }

    const getCatalogueMaterials = async () => {

        setIsLoading(true)
        let response = await catalogueHandler.getMaterialsHandler()

        setIsLoading(false)

        if (response && response.success) {

            let qualities = response.data || []

            setCatalogueMaterials(qualities)


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Materials, Please try again!`)
        }
    }

    useEffect(() => {
        getCatalogueMaterials()
    }, [])

    const ClosePopup = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)

    }




    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Catalogue Materials",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { icon: Icons.general.btn_delete, label: "Yes, Delete" },
                        heading: "Delete item",
                        message: "Are you sure you want to delete?",
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <Routes >
                <Route exact path='/create-material' element={
                    <AddMaterial props={{
                        type: "create",
                        heading: "Create Material",
                        callback: () => {
                            getCatalogueMaterials()
                        },
                        close: ClosePopup
                    }} />}>

                </Route>
                <Route exact path='/edit-material/:materialId' element={
                    <AddMaterial props={{
                        type: "edit",
                        heading: "Edit Material",
                        callback: () => {
                            getCatalogueMaterials()
                        },
                        close: ClosePopup,
                        detials: Selected_Material
                    }} />}>

                </Route>


            </Routes>

            <div className="panel-content-header">
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder='Search materials here...'
                    />
                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.search }}></div>
                </div>
                <div className="buttons">
                    {has_add_access ?
                        <div
                            className="button add-button"
                            onClick={(e) => HandleAddNew()}
                        >
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                            <div className="label">Add New</div>
                        </div>
                        : ''}
                </div>
            </div>
            <div className="catalogue-materials-main">
                <div className="catalogue-materials-items">

                    {CatalogueMaterials.map((material) => (

                        <div
                            className={`catalogue-materials-item ${material.sno == CatalogueSelectedMaterial ? 'catalogue-materials-item-active' : ''}`}
                            key={`catalogue-materials-item-${material.sno}`}
                        >
                            <div
                                className="catalogue-materials-item-header"
                            >
                                <div
                                    className="header-items"
                                    onClick={(e) => HandleMaterialExpand(material.sno)}
                                >
                                    <div className="header-left">
                                        <div className={`header-dropdown-icon`}
                                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                        ></div>
                                        <div className="header-label"> {material.name}</div>
                                    </div>


                                </div>
                                <div className="header-right">
                                    <div className="header-spec-items">
                                        <div className="header-section">{material.section_name}</div>
                                        <div className={`header-quality quality-basic`}>{material.quality_name}</div>
                                    </div>
                                    <div className="header-action-items">
                                        <div
                                            className="header-icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                            onClick={(e) => HandleEditItem(material)}
                                        ></div>
                                        <div
                                            className="header-icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                            onClick={(e) => HandleDeleteItem(material)}
                                        ></div>
                                    </div>
                                </div>


                            </div>
                            <div
                                className={`catalogue-materials-item-details`}
                            >
                                <div className="catalogue-materials-specifications">
                                    <div className="material-header">Material Specification</div>
                                    <div className="material-spec-items">
                                        {material.material_specifications.map((spec, idx) => (
                                            <div
                                                className="material-spec-item"
                                                key={`material-spec-item-${spec.id}`}
                                            >
                                                <div className="sno">{idx + 1}.</div>
                                                <div className="label">{spec.label}</div>
                                                <div className="value">{spec.value}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                    ))}


                </div>
            </div>
        </>
    )
}


export default CatalogueMaterials;