import BuilderHandler from '../Handlers/Builder/Builder'
import CompanyHandler from '../Handlers/Company/Company'
import SettingsHandler from '../Handlers/Settings/Settings'
import ClientsHandler from '../Handlers/Clients/Clients'
import VendorsHandler from '../Handlers/Vendors/Vendors'
import TeamManagementHandler from '../Handlers/TeamManagement/TeamManagement'

class StoreHandler {

    constructor({ dataStack, store, dispatch, updateState }) {

        this.store = store || {}
        this.dataStack = dataStack || []
        this.dispatch = dispatch
        this.updateState = updateState

        this.builderHandler = new BuilderHandler()
        this.companyHandler = new CompanyHandler()
        this.settingsHandler = new SettingsHandler()
        this.clientsHandler = new ClientsHandler()
        this.vendorsHandler = new VendorsHandler()
        this.teamManagementHandler = new TeamManagementHandler()

        // this.init()
    }

    async init() {

        return new Promise((resolve, reject) => {


            this.dataStack.forEach(async (item, i) => {

                if (item == 'builder') return resolve(await this.loadUserData())
                if (item == 'company') return resolve(await this.loadCompanyData())
                if (item == 'terms_conditions') return resolve(await this.loadTermsConditionsData())
                if (item == 'field_settings') return resolve(await this.loadFieldSettingsData())
                if (item == 'finance_settings') return resolve(await this.loadFinanceSettingsData())
                if (item == 'clients') return resolve(await this.loadClientsData())
                if (item == 'team_users') return resolve(await this.loadUsersData())
            })

        })


    }

    async loadUserData() {

        let response = await this.builderHandler.getProfileHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_USER",
                payload: { builder: response.data }
            }))

        }

        return true
    }
    async loadCompanyData() {


        let response = await this.companyHandler.getCompanyProfileHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_COMPANY",
                payload: { company: response.data }
            }))

        }

        return true
    }
    async loadTermsConditionsData() {


        let response = await this.settingsHandler.getTermsConditionsHandler()

        if (response && response.success) {

            let settings = this.store && this.store.user.settings ? this.store.user.settings : {}

            settings = { ...settings, terms_conditions: response.data }

            this.dispatch(this.updateState({
                type: "SET_TERMS_CONDITIONS",
                payload: {
                    settings
                }
            }))

        }

        return true
    }
    async loadFieldSettingsData() {


        let response = await this.settingsHandler.getFieldsHandler()

        if (response && response.success) {

            let settings = this.store && this.store.user.settings ? this.store.user.settings : {}

            settings = { ...settings, field_settings: response.data }

            this.dispatch(this.updateState({
                type: "SET_SETTINGS",
                payload: {
                    settings
                }
            }))

        }

        return true
    }
    async loadFinanceSettingsData() {


        let response = await this.settingsHandler.getFinanceHandler()

        if (response && response.success) {

            let settings = this.store && this.store.user.settings ? this.store.user.settings : {}

            settings = { ...settings, finance_settings: response.data }

            this.dispatch(this.updateState({
                type: "SET_SETTINGS",
                payload: {
                    settings
                }
            }))

        }

        return true
    }
    async loadVendorsData() {


        let response = await this.vendorsHandler.getVendorBasicsHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_VENDORS",
                payload: {
                    vendors: response.data
                }
            }))

        }

        return true
    }

    async loadClientsData() {


        let response = await this.clientsHandler.getClientsHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_CLIENTS",
                payload: {
                    clients: response.data
                }
            }))

        }

        return true
    }

    async loadUsersData() {


        let response = await this.teamManagementHandler.getTeamUsersHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_USERS",
                payload: {
                    team_users: response.data
                }
            }))

        }

        return true
    }


}

export default StoreHandler;