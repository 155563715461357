
import Images from '../assets/Images'
import Icons from '../assets/Icons'
import { useEffect, useState } from 'react'

const SmarkTopCards = ({ props }) => {

    const { id, cards, callback } = props

    const [SmartCards, setSmartCards] = useState([

    ])
    const [SmartCardsLoading, setSmartCardsLoading] = useState(false)

    const HandleSetup = () => {

        setSmartCardsLoading(true)
        setSmartCards(cards)
        setSmartCardsLoading(false)
    }

    useEffect(() => {

        HandleSetup()
    }, [])

    return (
        <div className="smart-top-cards-main" id={id}>
            <div className="smart-top-cards-items">
                {
                    SmartCards.map((item, index) => (

                        <div
                            className="smart-top-card"
                            key={item.id}
                            style={{
                                color: item.style.color,
                                borderColor: item.style.border_color,
                                backgroundImage: `linear-gradient(to right, ${item.style.bg_linear_colors.start}, ${item.style.bg_linear_colors.end})`
                            }}
                            onClick={() => callback(item.id)}
                        >

                            <div className="card-detials">
                                <div className="card-label">{item.label}</div>
                                <div className="card-value">{item.unit_name}{item.value}</div>
                            </div>
                            <div
                                className="card-icon"
                                style={{ fill: item.style.color }}
                                dangerouslySetInnerHTML={{ __html: item.icon || Icons.general.invoice_total }}
                            ></div>

                        </div>


                    ))
                }


            </div>

        </div>
    )

}

export default SmarkTopCards;