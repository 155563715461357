

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import LeadManagementHandler from '../../Handlers/LeadManagement/LeadManagement';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, DateTimeInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import SmarkTopCards from '../../components/SmarkTopCards'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const Add_Template = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { leadId } = useParams()

    const leadManagementHandler = new LeadManagementHandler()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['clients', 'team_users'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }


    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Leads')

    const [Title, setTitle] = useState('')
    const [Name, setName] = useState('')
    const [Date, setDate] = useState('')
    const [SiteVisitDate, setSiteVisitDate] = useState('')
    const [Email, setEmail] = useState('')
    const [Phone, setPhone] = useState('')
    const [Assign_SiteVisit_id, setAssign_SiteVisit_id] = useState('')
    const [Assign_SiteVisit_name, setAssign_SiteVisit_name] = useState('')
    const [Lead_Source, setLead_Source] = useState('')
    const [Lead_SiteType, setLead_SiteType] = useState('')
    const [Lead_Status, setLead_Status] = useState('')
    const [Street, setStreet] = useState('')
    const [City, setCity] = useState('')
    const [State, setState] = useState('')
    const [Pincode, setPincode] = useState('')
    const [Country, setCountry] = useState('')

    const [Description, setDescription] = useState('')

    const [Client_Options, setClient_Options] = useState([])
    const [Assignee_Options, setAssignee_Options] = useState([])

    const [LeadSource_Options, setLeadSource_Options] = useState([
        {
            value: 'Office Visit',
            label: 'Office Visit'
        },
        {
            value: 'Employee Referral',
            label: 'Employee Referral'
        },
        {
            value: 'Old Customer Referral',
            label: 'Old Customer Referral'
        },
        {
            value: 'Business Forum Referral',
            label: 'Business Forum Referral'
        },
        {
            value: 'Website',
            label: 'Website'
        },
        {
            value: 'JustDail Website',
            label: 'JustDail Website'
        },
        {
            value: 'Call to Office',
            label: 'Call to Office'
        },
        {
            value: 'Call to Personal',
            label: 'Call to Personal'
        },
        {
            value: 'Advertisement',
            label: 'Advertisement'
        },
        {
            value: 'Partner',
            label: 'Partner'
        },
        {
            value: 'Trade Show',
            label: 'Trade Show'
        },
        {
            value: 'Instagram',
            label: 'Instagram'
        },
        {
            value: 'Facebook',
            label: 'Facebook'
        },
        {
            value: 'Twitter',
            label: 'Twitter'
        },
        {
            value: 'LinkedIn',
            label: 'LinkedIn'
        },
        {
            value: 'Other',
            label: 'Other'
        },
    ])
    const [LeadSiteType_Options, setLeadSiteType_Options] = useState([
        {
            value: '0',
            label: 'Commercial Project'
        },
        {
            value: '1',
            label: 'Residential Project'
        },
        {
            value: '2',
            label: 'Industrial Project'
        },
        {
            value: '3',
            label: 'Hospitality Project'
        },
        {
            value: '4',
            label: 'Renovation Project'
        },
        {
            value: "5",
            label: "Turnkey Project"
        },
        {
            value: "6",
            label: "Retail Project"
        },
        {
            value: "7",
            label: "Healthcare Project"
        },
        {
            value: "8",
            label: "Educational Project"
        },
        {
            value: "9",
            label: "Exhibition Project"
        },
        {
            value: "10",
            label: "Landscape Project"
        },
        {
            value: "11",
            label: "Other Project"
        }


    ])
    const [LeadStatus_Options, setLeadStatus_Options] = useState([
        {
            value: 'Attempted to Contact',
            label: 'Attempted to Contact'
        },
        {
            value: 'Contact in Future',
            label: 'Contact in Future'
        },
        {
            value: 'Contacted',
            label: 'Contacted'
        },
        {
            value: 'Site Visit Done',
            label: 'Site Visit Done'
        },
        {
            value: 'Customer Office Visited',
            label: 'Customer Office Visited'
        },
        {
            value: 'Designing Stage',
            label: 'Designing Stage'
        },
        {
            value: 'Quotation Sent',
            label: 'Quotation Sent'
        },
        {
            value: 'Excetution Stage',
            label: 'Excetution Stage'
        },
        {
            value: 'Project Completed',
            label: 'Project Completed'
        },
    ])

    const navigate = useNavigate()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--clients', feature_key: 'clients' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (type == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })
            if (type == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {

        let update_data = {
            name: Name,
            date: Date,
            site_visit_date: SiteVisitDate,
            assign_site_visit_id: Assign_SiteVisit_id,
            assign_site_visit_name: Assign_SiteVisit_name,
            site_type: Lead_SiteType,
            email: Email,
            phone: Phone,
            source: Lead_Source,
            status: Lead_Status,
            street: Street,
            city: City,
            state: State,
            pincode: Pincode,
            country: Country,
            description: Description
        }

        let response = { success: false }

        if (type == 'edit') {

            update_data["id"] = leadId
            response = await leadManagementHandler.updateLeadHandler(update_data)

        }

        else response = await leadManagementHandler.createLeadHandler(update_data)

        if (response.success) {
            callback()
            setIsLoading(false)
            close(type)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Lead, Please try again!`)
        }
    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <div
                            className={`action-btn action-${type}`}
                            onClick={(e) => {
                                HandlePopupSubmit(e)
                            }}
                        >
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.save }}></div>
                            <div className="label">Save</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const LoadTemplate = async (leadId) => {

        if (Object.keys(detials).length) {

            let {
                id,
                date,
                name,
                site_type,
                assign_site_visit_id,
                assign_site_visit_name,
                site_visit_date,
                email,
                phone,
                source,
                status,
                street,
                city,
                state,
                pincode,
                country,
                description,
                created_at,
                document,
                updated_at,
                updated_by
            } = detials

            setDate(date)
            setSiteVisitDate(site_visit_date)
            setAssign_SiteVisit_id(assign_site_visit_id)
            setAssign_SiteVisit_name(assign_site_visit_name)
            setLead_SiteType(site_type)
            setName(name)
            setEmail(email)
            setPhone(phone)
            setLead_Source(source)
            setLead_Status(status)
            setStreet(street)
            setCity(city)
            setState(state)
            setPincode(pincode)
            setCountry(country)
            setDescription(description)

        }
        else {

            let response = await leadManagementHandler.getLeadsHandler({
                id: leadId
            })

            if (response && response.success && Array.isArray(response.data) && response.data.length) {

                let {
                    id,
                    date,
                    name,
                    site_type,
                    assign_site_visit_id,
                    assign_site_visit_name,
                    site_visit_date,
                    email,
                    phone,
                    source,
                    status,
                    street,
                    city,
                    state,
                    pincode,
                    country,
                    description,
                    created_at,
                    document,
                    updated_at,
                    updated_by
                } = response.data[0]


                setDate(date)
                setSiteVisitDate(site_visit_date)
                setAssign_SiteVisit_id(assign_site_visit_id)
                setAssign_SiteVisit_name(assign_site_visit_name)
                setLead_SiteType(site_type)
                setName(name)
                setEmail(email)
                setPhone(phone)
                setLead_Source(source)
                setLead_Status(status)
                setStreet(street)
                setCity(city)
                setState(state)
                setPincode(pincode)
                setCountry(country)
                setDescription(description)
            }
            else {
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in Lead, Please try again!`)
            }
        }
    }


    useEffect(() => {
        if (type == 'edit' && leadId) LoadTemplate(leadId)

        LoadStoreData()
    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { clients, team_users } = store.user

            if (Array.isArray(clients) && clients.length) {

                clients = clients.map(itm => {
                    return {
                        value: itm.id,
                        label: itm.name,
                    }
                })

                setClient_Options(clients)
            }
            if (Array.isArray(team_users) && team_users.length) {

                team_users = team_users.map(itm => {
                    return {
                        value: itm.id,
                        label: itm.name,
                    }
                })

                setAssignee_Options(team_users)
            }


        }

    }, [store])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in clients",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container center-popup-container">
                    <Popup_Header />
                    <div className="clients-popup-content">

                        <div className="content-items">

                            <div className="content-item">

                                <DateTimeInput
                                    props={{
                                        id: "lead-date",
                                        value: Date,
                                        placeholder: '',
                                        setValue: (value) => setDate(value),
                                        isIcon: true,
                                        isLabel: true,
                                        icon: Icons.general.schedule,
                                        isRequired: true,
                                        label: "Enquiry Date",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "lead-source",
                                        value: Lead_Source,
                                        placeholder: '',
                                        options: LeadSource_Options || [],
                                        setValue: (value, label) => {
                                            setLead_Source(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Lead Source",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "lead-site_type",
                                        value: Lead_SiteType,
                                        placeholder: '',
                                        options: LeadSiteType_Options || [],
                                        setValue: (value, label) => {
                                            setLead_SiteType(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Site Type",
                                    }}
                                />
                            </div>


                        </div>
                        <div className="content-items">
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "lead-name",
                                        value: Name,
                                        placeholder: '',
                                        setValue: (value) => setName(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Lead Name",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "lead-phone",
                                        value: Phone,
                                        placeholder: '',
                                        setValue: (value) => setPhone(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "Phone",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "lead-email",
                                        value: Email,
                                        placeholder: '',
                                        setValue: (value) => setEmail(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "Email",
                                    }}
                                />
                            </div>

                        </div>
                        <div className="content-items">

                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "lead-street",
                                        value: Street,
                                        placeholder: '',
                                        setValue: (value) => setStreet(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "Street",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "lead-city",
                                        value: City,
                                        placeholder: '',
                                        setValue: (value) => setCity(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "City",
                                    }}
                                />
                            </div>

                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "lead-pincode",
                                        value: Pincode,
                                        placeholder: '',
                                        setValue: (value) => setPincode(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "Zip Code / Pincode",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="content-items">

                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "lead-state",
                                        value: State,
                                        placeholder: '',
                                        setValue: (value) => setState(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "State",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "lead-country",
                                        value: Country,
                                        placeholder: '',
                                        setValue: (value) => setCountry(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "Country",
                                    }}
                                />
                            </div>
                            <div className="content-item"></div>

                        </div>

                        <div className="content-items">

                            <div className="content-item">

                                <DateTimeInput
                                    props={{
                                        id: "lead-site_visit_date",
                                        value: SiteVisitDate,
                                        placeholder: '',
                                        setValue: (value) => setSiteVisitDate(value),
                                        isIcon: true,
                                        isLabel: true,
                                        icon: Icons.general.schedule,
                                        isRequired: false,
                                        label: "Site Visit Date",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "lead-assign_site_visit",
                                        value: Assign_SiteVisit_id,
                                        placeholder: '',
                                        options: Assignee_Options || [],
                                        setValue: (value, label) => {
                                            setAssign_SiteVisit_id(value)
                                            setAssign_SiteVisit_name(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "Assign Site Visit",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "lead-status",
                                        value: Lead_Status,
                                        placeholder: '',
                                        options: LeadStatus_Options || [],
                                        setValue: (value, label) => {
                                            setLead_Status(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "Lead Status",
                                    }}
                                />
                            </div>

                        </div>

                        <div className="content-items">

                            <div className="content-item-address">
                                <textarea
                                    id="lead-description"
                                    value={Description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder='Description'
                                ></textarea>
                            </div>

                        </div>
                    </div>
                    <Popup_Footer />
                </div>
            </div>
        </>
    )
}

const LeadManagement = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const leadManagementHandler = new LeadManagementHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Leads')

    const [ClientDetials_original, setClientDetials_original] = useState([])
    const [ClientDetials, setClientDetials] = useState([])
    const [selected_termsCondition, setSelected_termsCondition] = useState({})
    const [selectedAction, setSelectedAction] = useState('')

    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--clients', feature_key: 'clients' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,

        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,

        },
    ])


    const tabelHeader = [
        {
            id: 'sno',
            label: 'S:No',

        },
        {
            id: 'name',
            label: 'Lead Name',

        },
        {
            id: 'title',
            label: 'Title',

        },
        {
            id: 'email',
            label: 'Email',

        },
        {
            id: 'phone',
            label: 'Phone',
        },
        {
            id: 'source',
            label: 'Lead Source',
        },
        {
            id: 'status',
            label: 'Lead Status',
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            format: (value) => value,
        },
    ];

    const getClientDetials = async () => {

        let response = await leadManagementHandler.getLeadsHandler()

        if (response && response.success) {

            setClientDetials_original(response.data)
            setClientDetials(response.data)

            HandleSmartCardSetup(response.data)

            dispatch(updateState({
                type: "SET_CLIENTS",
                payload: { clients: response.data }
            }))

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Leads, Please try again!`)
        }
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleAddTemplate = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/add-new`)
    }
    const HandleEditTemplate = (template) => {
        if (!has_edit_access) return
        setSelected_termsCondition(template)

        navigate(`${window.location.pathname}/edit/${template.sno}`)
    }

    const HandleDeleteTemplate = async (item) => {

        if (!has_edit_access || !has_delete_access) return

        setDeleteItem(item.sno)
        setDeleteItemAlert(true)
    }

    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        if (!confirmation || id == undefined) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }

        let response = await leadManagementHandler.deleteLeadHandler({
            id: String(id)
        })

        if (response && response.success) {

            getClientDetials()


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Leads, Please try again!`)

        }


        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }

    const CloseAddTemplate = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)
    }

    const HanelCellAction = (id, row) => {

        if (id == "name") HandleEditTemplate(row)

    }

    useEffect(() => {
        getClientDetials()
    }, [])

    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (items) => {

        items = items || []

        let total = items.length
        let total_converted = items.filter(itm => itm.status == "Converted").length
        let total_contacted = items.filter(itm => itm.status == "Contacted").length

        let smark_cards = [
            {
                id: 'lm-smart-top-cards-total',
                label: 'Total Leads',
                value: total || "0",
                icon: Icons.general.leads,
                unit_name: '',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },
            {
                id: 'lm-smart-top-cards-total_converted',
                label: 'Converted Leads',
                value: total_converted || "0",
                icon: Icons.general.leads,
                unit_name: '',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
            {
                id: 'lm-smart-top-cards-outstanding_balance',
                label: 'Pending Leads',
                value: total_contacted || "0",
                icon: Icons.general.leads,
                unit_name: '',
                style: {
                    color: '#ff5b5b',
                    bg_linear_colors: {
                        start: '#ffeded',
                        end: '#fff9f9'
                    },
                    border_color: '#dcd1d1',
                },
            },
        ]

        setSmarkCard_Items(smark_cards)

    }

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Leads",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}
            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { icon: Icons.general.btn_delete, label: "Yes, Delete" },
                        heading: "Delete item",
                        message: "Are you sure you want to delete?",
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <Routes >
                <Route exact path='/add-new' element={
                    <Add_Template props={{
                        type: "create",
                        heading: "Create Lead",
                        callback: getClientDetials,
                        close: CloseAddTemplate
                    }} />}></Route>
                <Route exact path='/edit/:leadId' element={
                    <Add_Template props={{
                        type: "edit",
                        heading: "Update Lead",
                        callback: getClientDetials,
                        close: CloseAddTemplate,
                        detials: selected_termsCondition
                    }} />}></Route>
            </Routes>

            <div className="project-clients-main">
                <div className="clients-panel-content">

                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}
                    <div className="smart-panel-content-main">
                        <div className="panel-content-header">
                            <div className="search-bar">
                                <input
                                    type="text"
                                    placeholder='Search leads here...'
                                />
                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.search }}></div>
                            </div>
                            <div className="buttons">
                                {has_add_access ?
                                    <div
                                        className="button add-button"
                                        onClick={(e) => HandleAddTemplate()}
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                        <div className="label">Add New</div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        <div className="panel-content-sections panel-content-tabel-section">
                            <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                    <Table stickyHeader>
                                        <TableHead
                                            className='tabel-header'
                                        >
                                            <TableRow>
                                                {tabelHeader.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                        className='tabel-header-items'
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='tabel-body'>
                                            {ClientDetials
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, idx) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                                                            {tabelHeader.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        onClick={(e) => {
                                                                            if (column.id != 'actions') HanelCellAction(column.id, row)
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.id == 'sno' ?
                                                                                idx + 1 + page * rowsPerPage
                                                                                :
                                                                                column.id == 'name' ?
                                                                                    <div
                                                                                        className="tabel-name"
                                                                                    >
                                                                                        {column.format ? column.format(value) : value}
                                                                                    </div>
                                                                                    : column.id == 'status' ?
                                                                                        <div
                                                                                            className={`tabel-status tabel-status-${value}`}
                                                                                        >
                                                                                            {column.format ? column.format(value) : value}
                                                                                        </div>

                                                                                        : column.id == 'actions' && has_edit_access ?

                                                                                            <div
                                                                                                className="tabel-action-main"
                                                                                            >
                                                                                                {has_edit_access ?
                                                                                                    <div
                                                                                                        className="action-icon"
                                                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                                                                                        onClick={(e) => HandleEditTemplate(row)}
                                                                                                        title='Edit'
                                                                                                    ></div>
                                                                                                    : ''}
                                                                                                {has_delete_access ?
                                                                                                    <div
                                                                                                        className="action-icon"
                                                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                                                        onClick={(e) => HandleDeleteTemplate(row)}
                                                                                                        title='Delete'
                                                                                                    ></div>
                                                                                                    : ''}

                                                                                            </div>
                                                                                            : column.format ? column.format(value) : value
                                                                        }
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    style={{ height: '7%', overflow: 'hidden' }}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={ClientDetials.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default LeadManagement;