

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams, useFetcher } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CatalogueHandler from '../../Handlers/Catalogue/Catalogue';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { useAuth, useMenuAuth } from '../../hooks/useAuth'



const AddQuality = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { qualityId } = useParams()

    const catalogueHandler = new CatalogueHandler()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Catalogue Qualities')

    const [Name, setName] = useState('')
    const [Description, setDescription] = useState('')
    const navigate = useNavigate()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--clients', feature_key: 'clients' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (type == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })
            if (type == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,

        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,

        },
    ])

    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {

        e.preventDefault()

        let updateta = {
            name: Name,
            description: Description
        }

        let response = { success: false }

        if (type == 'edit') {

            updateta["sno"] = qualityId
            response = await catalogueHandler.updateQualityHandler(updateta)

        }

        else response = await catalogueHandler.createQualityHandler(updateta)

        if (response.success) {
            callback()
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Qualities, Please try again!`)
        }

        close(type)
    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <button
                            className={`action-btn action-${type}`}

                        >
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.save }}></div>
                            <div className="label">Save</div>
                        </button>
                    </div>
                </div>
            </div>
        );
    };



    useEffect(() => {
        if (type == 'edit' && qualityId) {

            const LoadTemplate = async (qualityId) => {

                if (Object.keys(detials).length) {

                    let { sno, name, description, created_at, document, updated_at, updated_by } = detials

                    setName(name)
                    setDescription(description)

                }
                else {

                    let response = await catalogueHandler.getQualitiesHandler({
                        sno: qualityId
                    })

                    if (response && response.success && Array.isArray(response.data) && response.data.length) {

                        let { sno, name, description, created_at, document, updated_at, updated_by } = response.data[0]

                        setName(name)
                        setDescription(description)
                    }
                    else {
                        setWarningAlert(true)
                        setWarningAlertType('error')
                        setApiFailedMessage(`Error in Catalogue Qualities, Please try again!`)
                    }
                }
            }

            LoadTemplate(qualityId)
        }
    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Catalogue Qualities",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <form className="side-popup-container" onSubmit={HandlePopupSubmit}>
                    <Popup_Header />
                    <div className="clients-popup-content">
                        <div className="content-items">
                            <TextInput
                                props={{
                                    id: "clients-name",
                                    value: Name,
                                    placeholder: '',
                                    setValue: (value) => setName(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>
                        <div className="content-items">

                            <div className="content-item-address">
                                <textarea
                                    id="clients-address"
                                    value={Description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder='Description'
                                ></textarea>
                            </div>

                        </div>
                    </div>
                    <Popup_Footer />
                </form>
            </div>
        </>
    )
}

const CatalogueQuality = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const catalogueHandler = new CatalogueHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Catalogue Qualities')

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--clients', feature_key: 'clients' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,

        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,

        },
    ])

    const [Selected_Quality, setSelected_Quality] = useState({})

    const [selectedAction, setSelectedAction] = useState('')

    const [ClientDetials_original, setClientDetials_original] = useState([])
    const [ClientDetials, setClientDetials] = useState([])

    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const getCatalogueQualities = async () => {

        setIsLoading(true)

        let response = await catalogueHandler.getQualitiesHandler()

        setIsLoading(false)

        if (response && response.success) {

            let qualities = response.data.map((item, index) => {
                item.index = index + 1 < 10 ? `0${index + 1}` : index + 1
                return item
            })

            setClientDetials_original(qualities)
            setClientDetials(qualities)


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Qualities, Please try again!`)
        }
    }

    useEffect(() => {
        getCatalogueQualities()
    }, [])


    const tabelHeader = [
        {
            id: 'index',
            label: 'SNo',
            maxWidth: 50,

        },
        {
            id: 'created_at',
            label: 'Date',
            format: (value) => Utils.getLocalFullDateBYFormat(value),
            maxWidth: 100,
        },
        {
            id: 'name',
            label: 'Name',
            minWidth: 500,
        },
        {
            id: 'status',
            label: 'Status',
            format: (value) => {
                if (value == "0") return "Inactive"
                if (value == "1") return "Active"
            },

        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            format: (value) => value,
        },
    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleAddNew = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/create-quality`)
    }
    const HandleEditItem = (item) => {

        if (!has_edit_access) return
        setSelected_Quality(item)

        navigate(`${window.location.pathname}/edit-quality/${item.sno}`)
    }
    const HandleDeleteItem = async (item) => {

        if (!has_edit_access || !has_delete_access) return

        setDeleteItem(item.sno)
        setDeleteItemAlert(true)
    }

    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        if (!confirmation || id == undefined) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }

        let response = await catalogueHandler.deleteQualityHandler({
            sno: String(id)
        })

        if (response && response.success) {

            getCatalogueQualities()


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Material, Please try again!`)

        }


        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }


    const HanelCellAction = (id, row) => {

        console.log(id, 'selectedAction');
        if (id == "name") HandleEditItem(row)
    }


    const ClosePopup = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)

    }

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Catalogue Qualities",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { icon: Icons.general.btn_delete, label: "Yes, Delete" },
                        heading: "Delete item",
                        message: "Are you sure you want to delete?",
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <Routes >
                <Route exact path='/create-quality' element={
                    <AddQuality props={{
                        type: "create",
                        heading: "Create Quality",
                        callback: () => {
                            getCatalogueQualities()
                        },
                        close: ClosePopup
                    }} />}>

                </Route>
                <Route exact path='/edit-quality/:qualityId' element={
                    <AddQuality props={{
                        type: "edit",
                        heading: "Edit Quality",
                        callback: () => {
                            getCatalogueQualities()
                        },
                        close: ClosePopup,
                        detials: Selected_Quality
                    }} />}>

                </Route>

            </Routes>


            <div className="panel-content-header">
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder='Search qualities here...'
                    />
                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.search }}></div>
                </div>
                <div className="buttons">
                    {has_add_access ?
                        <div
                            className="button add-button"
                            onClick={(e) => HandleAddNew()}
                        >
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                            <div className="label">Add New</div>
                        </div>
                        : ''}
                </div>
            </div>
            <div className="panel-content-sections panel-content-tabel-section">
                <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                    <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                        <Table stickyHeader>
                            <TableHead
                                className='tabel-header'
                            >
                                <TableRow>
                                    {tabelHeader.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            className='tabel-header-items'
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className='tabel-body'>
                                {ClientDetials
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                                                {tabelHeader.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            onClick={(e) => {
                                                                if (column.id != 'actions') HanelCellAction(column.id, row)
                                                            }}
                                                        >
                                                            {
                                                                column.id == 'name' ?
                                                                    <div
                                                                        className="tabel-name"
                                                                    >
                                                                        {column.format ? column.format(value) : value}
                                                                    </div>
                                                                    : column.id == 'status' ?
                                                                        <div
                                                                            className={`tabel-status tabel-status-${value}`}
                                                                        >
                                                                            {column.format ? column.format(value) : value}
                                                                        </div>
                                                                        : column.id == 'actions' && has_edit_access ?

                                                                            <div
                                                                                className="tabel-action-main"
                                                                            >
                                                                                {has_edit_access ?
                                                                                    <div
                                                                                        className="action-icon"
                                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                                                                        onClick={(e) => HandleEditItem(row)}
                                                                                    ></div>
                                                                                    : ''}
                                                                                {has_delete_access ?
                                                                                    <div
                                                                                        className="action-icon"
                                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                                        onClick={(e) => HandleDeleteItem(row)}
                                                                                    ></div>
                                                                                    : ''}

                                                                            </div>
                                                                            : column.format ? column.format(value) : value
                                                            }
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        style={{ height: '7%', overflow: 'hidden' }}
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={ClientDetials.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </>
    )
}

export default CatalogueQuality;