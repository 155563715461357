

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CompanyHandler from '../../Handlers/Company/Company';
import SettingsHandler from '../../Handlers/Settings/Settings';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'


import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const Field_settings = () => {

    const store = useSelector((store) => store)
    const currency_type = store.user && store.user.company ? store.user.company.currency_setting || "₹" : "₹"

    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const settingsHandler = new SettingsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'settings--field_settings' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [activeMenu, setActiveMenu] = useState('')
    const [fieldMenus, setFieldMenus] = useState([
        {
            id: 'units',
            label: "Units",
        },
        {
            id: 'project_statuses',
            label: "Project statuses",
        },
        {
            id: 'project_types',
            label: "Project types",
        },
        {
            id: 'payment_methods',
            label: "Payment methods",
        },
    ])

    const Field_default_values = {
        units: [
            {
                id: Utils.getUniqueId(),
                isActive: true,
                isDefault: true,
                name: "",
            }
        ],
        project_statuses: [
            {
                id: Utils.getUniqueId(),
                isActive: true,
                isDefault: false,
                name: "",
            }
        ],
        project_types: [
            {
                id: Utils.getUniqueId(),
                isActive: true,
                isDefault: false,
                name: "",
            }
        ],
        payment_methods: [
            {
                id: Utils.getUniqueId(),
                isActive: true,
                isDefault: false,
                name: "",
            }
        ],
    }
    /* field enem: 1-units, 2-project_statuses,3-project_types,4-payment_methods*/
    const [FieldSetting_Id, setFieldSetting_Id] = useState('')
    const [FieldSetting_Options, setFieldSetting_Options] = useState(Field_default_values)

    const HandleToggleEvent = (menu) => {
        setActiveMenu(menu.id)
    }

    const HandleAddNew = () => {

        if (!has_add_access || !activeMenu) return

        let FieldSetting_Options_ = { ...FieldSetting_Options }

        FieldSetting_Options_[activeMenu].push({
            id: Utils.getUniqueId(),
            name: '',
            isActive: true,
            isDefault: false,
        })
        setFieldSetting_Options(FieldSetting_Options_)
    }

    const HandleInputChange = (value, index, key) => {

        if (!has_edit_access || !activeMenu) return

        let FieldSetting_Options_ = { ...FieldSetting_Options }
        FieldSetting_Options_[activeMenu][index][key] = value

        setFieldSetting_Options(FieldSetting_Options_)
    }
    const HandleRemove = (index) => {

        if (!has_delete_access) return

        if (index == 0 || !activeMenu) return
        let FieldSetting_Options_ = { ...FieldSetting_Options }
        FieldSetting_Options_[activeMenu].splice(index, 1)
        setFieldSetting_Options(FieldSetting_Options_)
    }
    const getEnemforField = (field) => {
        if (field == 'units') return 1
        else if (field == 'project_statuses') return 2
        else if (field == 'project_types') return 3
        else if (field == 'payment_methods') return 4
    }
    const getFieldsHandler = async () => {

        let response = await settingsHandler.getFieldsHandler()

        if (response && response.success) {


            if (Array.isArray(response.data)) {

                if (!Array.isArray(response.data) || !response.data.length) {



                    setFieldSetting_Options(Field_default_values)
                }
                else {

                    let field_data = response.data[0]

                    console.log(field_data, 'field_data');

                    let field_settings = {
                        units: field_data.units.length ? field_data.units : Field_default_values.units,
                        project_statuses: field_data.project_statuses.length ? field_data.project_statuses : Field_default_values.project_statuses,
                        project_types: field_data.project_types.length ? field_data.project_types : Field_default_values.project_types,
                        payment_methods: field_data.payment_methods.length ? field_data.payment_methods : Field_default_values.payment_methods,
                    }
                    setFieldSetting_Id(field_data.id)
                    setFieldSetting_Options(field_settings)
                }

            }

            if (store && store.user.settings) {

                let settings = store.user.settings

                settings = { ...settings, field_settings: response.data }

                dispatch(updateState({
                    type: "SET_SETTINGS",
                    payload: { settings }
                }))

            }

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting fields`)
        }
    }

    const HandleFieldSubmit = async (e) => {
        e.preventDefault()

        if (!has_edit_access || !FieldSetting_Id) return

        let field_settings = {
            id: FieldSetting_Id,
            units: [],
            project_statuses: [],
            project_types: [],
            payment_methods: []
        }

        for (const key in FieldSetting_Options) {
            field_settings[key] = FieldSetting_Options[key].filter(field => field.name != "")
        }


        setIsLoading(true)

        let response = await settingsHandler.updateFieldsHandler(field_settings)

        if (response.success) {
            getFieldsHandler()
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating fields`)
        }
    }

    useEffect(() => {
        HandleToggleEvent(fieldMenus[0])

        getFieldsHandler()
    }, [])




    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Field Settings",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}



            <form className="settings-panel-content" onSubmit={HandleFieldSubmit}>
                <div className="panel-content-main">

                    <div className="panel-content-header">
                        <div className="title">Default values</div>
                    </div>
                    <div className="panel-content-sections panel-content-tabel-section">
                        <div className="field_settings-content-main">
                            <div className="toggle-items">
                                {fieldMenus.map((menu, i) => (

                                    <div
                                        className={`toggle-item ${activeMenu == menu.id ? 'toggle-item-active' : ''}`}
                                        key={`field_settings-toggle-${menu.id}`}
                                        onClick={(e) => HandleToggleEvent(menu)}
                                    >
                                        <div className="label">{menu.label}</div>
                                    </div>

                                ))}
                            </div>
                            <div className="field_settings-result-main">

                                <div className="field_settings-splits-main">
                                    <div className="field_settings-splits-header">
                                        <div className="header-item-status">Active</div>
                                        <div className="header-item-name">Name</div>
                                        <div className="header-item-default">Default</div>
                                        {has_delete_access ? <div className="header-item-action">Action</div> : ''}
                                    </div>
                                    <div className="field_settings-splits-content">
                                        {FieldSetting_Options[activeMenu]?.map((item, index) => (
                                            <div className="field_settings-content-items">
                                                <div className="field_settings-content-status">
                                                    {index != 0 ?
                                                        <RadioInput props={{
                                                            value: item.isActive,
                                                            isIcon: false,
                                                            icon: "",
                                                            inputType: "checkbox",
                                                            name: "table-default-radio",
                                                            setValue: (value) => HandleInputChange(value, index, 'isActive')
                                                        }} />
                                                        :
                                                        <span
                                                            className='checkboxinput-main'
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.checkbox_disabled }}
                                                        >
                                                        </span>
                                                    }

                                                </div>
                                                <div className="field_settings-content-name">
                                                    <TextInput
                                                        props={{
                                                            id: "field_settings-name",
                                                            value: item.name,
                                                            placeholder: '',
                                                            setValue: (value) => HandleInputChange(value, index, 'name'),
                                                            isIcon: false,
                                                            isLabel: false,
                                                            isRequired: true,
                                                            label: "Name",
                                                        }}
                                                    />
                                                </div>
                                                <div className="field_settings-content-default">
                                                    <RadioInput props={{
                                                        id: "field_settings-default",
                                                        value: item.isDefault,
                                                        isIcon: false,
                                                        icon: "",
                                                        name: "table-default-radio",
                                                        setValue: (value) => HandleInputChange(value, index, 'isDefault')
                                                    }} />
                                                </div>

                                                {has_delete_access ?

                                                    <div className="field_settings-content-action">
                                                        <div
                                                            className="action-remove"
                                                            onClick={(e) => HandleRemove(index)}
                                                        >
                                                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.close }}></div>
                                                        </div>
                                                    </div>
                                                    : ''}
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        className="field_settings-splits-action-buttons"
                                    >
                                        {has_add_access ?
                                            <div
                                                className="add-button"
                                                onClick={(e) => HandleAddNew()}
                                            >
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.add_active }}
                                                ></div>
                                                <div className="label">Add new option</div>
                                            </div>
                                            : ''}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="panel-content-footer">
                    <div className="content-footer-items">
                        <div className="footer-button">
                            <div className="label">Cancel</div>
                        </div>
                        <button type='submit' className="footer-button footer-button-active">
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.save }}></div>
                            <div className="label">Save Changes</div>
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Field_settings;
