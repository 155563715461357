import React, { useEffect } from 'react'

/* Redux Setup*/
import { Route, Routes, BrowserRouter, useNavigate, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ReverseProxy from '../config/reverseProxy'

const useAuth = () => {

    const store = useSelector((store) => store)


    const userdetials = store.user?.builder || undefined
    const roles_permissions = store.user?.roles_permissions || undefined
    const company_features = store.user?.company_features || []

    return {
        username: userdetials?.name,
        isAuthenticated: store.user?.isAuthenticated,
        isAdmin: userdetials?.user_type == '1' ? true : false,
        company_features: company_features ? company_features : [],
        roles_permissions: roles_permissions ? roles_permissions.role_access : [],

    }

}

const useMenuAuth = ({ menu, feature_key = undefined }) => {

    const navigate = useNavigate()

    const store = useSelector((store) => store)

    const userdetials = store.user?.builder || undefined
    const roles_permissions = store.user?.roles_permissions || undefined
    const isAdmin = userdetials?.user_type == '1' ? true : false
    const company_features = store.user?.company_features || []


    if (feature_key && Array.isArray(company_features)) {



        let is_feature_subscribed = company_features?.some((item) => item.feature_key == menu)

        if (!is_feature_subscribed) {
            navigate(`${ReverseProxy['proxyUrl']}/app`)

            return {
                isAuthenticated: false,
                roles_permissions: []
            }
        }
    }

    if (store.user?.builder) {

        if (isAdmin) {

            return {
                username: userdetials?.name,
                isAuthenticated: store.user?.isAuthenticated,
                isAdmin: true,
                menu_permission: {
                    _view: 1,
                    _create: 1,
                    _edit: 1,
                    _delete: 1,
                },

            }
        }

    }


    if (!menu) return { isAuthenticated: false, roles_permissions: [] }

    let menu_permission = roles_permissions?.role_access?.filter((item) => item.feature_key == menu)[0]

    if (!menu_permission) {
        navigate(`${ReverseProxy['proxyUrl']}/app`)
        return {
            isAuthenticated: true,
            roles_permissions: []
        }
    }

    return {
        username: userdetials?.name,
        isAuthenticated: store.user?.isAuthenticated,
        isAdmin: userdetials?.user_type == '1' ? true : false,
        menu_permission: menu_permission || undefined,

    }

}

export { useAuth, useMenuAuth }