

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import VendorsHandler from '../../Handlers/Vendors/Vendors';
import CatalogueHandler from '../../Handlers/Catalogue/Catalogue';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import Sections from '../../config/sections'

const CreateQuoteMeasurement = ({ props }) => {

    const { type, close, callback, detials } = props

    const { materialId } = useParams()

    const catalogueHandler = new CatalogueHandler()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Catalogue Materials')

    const [CatalogueQualites, setCatalogueQualites] = useState([])
    const [CatalogueUnits_Orginal, setCatalogueUnits_Orginal] = useState([])
    const [CatalogueUnits, setCatalogueUnits] = useState([])

    const [CatalogueAccessories_Orginal, setCatalogueAccessories_Orginal] = useState([])
    const [CatalogueAccessories, setCatalogueAccessories] = useState([])

    const [SectionOptions, setSectionOptions] = useState([...Sections])

    const [Id, setId] = useState('')
    const [Heading, setHeading] = useState('Select Section')

    const [Quality_id, setQuality_id] = useState('')
    const [Quality_name, setQuality_name] = useState('')
    const [Section_id, setSection_id] = useState('')
    const [Section_name, setSection_name] = useState('')

    const [Notes, setNotes] = useState('')
    const [WorkPattern, setWorkPattern] = useState('')

    const navigate = useNavigate()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--clients', feature_key: 'clients' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app`, { state: { from: location }, replace: true })
            }

            if (type == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })
            if (type == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const HandleCancel = async (e) => {
        close()
    }
    const HandlePopupSubmit = async (e) => {

        e.preventDefault()

        if (ActiveStepperIdx == 0) {
            setHeading('Add Measurements')
            setActiveStepperIdx(1)
            return
        }
        else if (ActiveStepperIdx == 1) {
            setHeading('Add Terms & Conditions')
            setActiveStepperIdx(2)
            return
        }
        else if (ActiveStepperIdx == 2) {

            let section_accessories = CatalogueAccessories.filter((item) => item.selected)

            let total_amount = [...SectionUnits, ...section_accessories].reduce((acc, item) => {
                return acc + Number(item.amount || 0)
            }, 0)

            console.log([SectionUnits, ...section_accessories], total_amount, 'total_amount');

            let updateta = {
                section_id: Section_id,
                section_name: Section_name,
                quality_id: Quality_id,
                quality_name: Quality_name,
                section_units: SectionUnits,
                section_accessories: section_accessories,
                notes: Notes || "",
                work_pattern: WorkPattern || "",
                total_amount: total_amount || 0

            }

            if (type == 'edit') updateta['id'] = Id
            else updateta['id'] = Utils.getUniqueId()

            callback(updateta, type)
            close()
        }


    }

    const HandleBackBtn = (activestepper_idx) => {

        if (activestepper_idx == 0) close()
        else if (activestepper_idx == 1) {
            setActiveStepperIdx(activestepper_idx - 1)
            setHeading('Select Section')
        }
        else if (activestepper_idx == 2) {
            setActiveStepperIdx(activestepper_idx - 1)
            setHeading('Add Measurements')
        }

    }



    const [SectionUnits, setSectionUnits] = useState([])

    const HandlSectionUnitInputChange = (value, index, key) => {

        let SectionUnits_ = [...SectionUnits]

        if (key == 'unit_id') {

            let select_unit = CatalogueUnits.find((item) => item.value == value)

            SectionUnits_[index]['unit_name'] = select_unit?.name || value

            if (select_unit) {

                SectionUnits_[index]['unit_id'] = select_unit.sno
                SectionUnits_[index]['rate'] = select_unit.rate
                SectionUnits_[index]['description'] = select_unit.description
            }


        } else if (key == 'length') {

            SectionUnits_[index][key] = value
            SectionUnits_[index]['area'] = Number(value) * Number(SectionUnits_[index]['height'] || 0)
            SectionUnits_[index]['amount'] = Number(SectionUnits_[index]['area'] || 0) * Number(SectionUnits_[index]['rate'] || 0)

        }
        else if (key == 'height') {

            SectionUnits_[index][key] = value
            SectionUnits_[index]['area'] = Number(value) * Number(SectionUnits_[index]['length'] || 0)
            SectionUnits_[index]['amount'] = Number(SectionUnits_[index]['area'] || 0) * Number(SectionUnits_[index]['rate'] || 0)

        }
        else if (key == 'rate') {

            SectionUnits_[index][key] = value
            SectionUnits_[index]['amount'] = Number(SectionUnits_[index]['area'] || 0) * Number(value || 0)

        } else SectionUnits_[index][key] = value

        setSectionUnits(SectionUnits_)
    }
    const HandlSectionUnitRemove = (index) => {

        if (index == 0) return
        let SectionUnits_ = [...SectionUnits]
        SectionUnits_.splice(index, 1)
        setSectionUnits(SectionUnits_)
    }
    const HandlSectionUnitAddNew = () => {

        let SectionUnits_ = [...SectionUnits]
        SectionUnits_.push({
            id: Utils.getUniqueId(),
            label: "",
            value: ""
        })
        setSectionUnits(SectionUnits_)
    }

    const [ActiveStepperIdx, setActiveStepperIdx] = useState(0)
    const HandeSelectSection = (item) => {


        setSection_id(item.value)
        setSection_name(item.label)

        let _CatalogueUnits = [...CatalogueUnits_Orginal]
        _CatalogueUnits = _CatalogueUnits.filter((unit) => unit.section_id == item.value)
        setCatalogueUnits(_CatalogueUnits)

    }

    const getCatalougeQualities = async () => {

        let response = await catalogueHandler.getQualitiesHandler()

        if (response && response.success) {

            let qualities = response.data || []

            qualities = qualities.map((item) => {
                return {
                    value: item.sno,
                    label: item.name
                }
            })

            setCatalogueQualites(qualities)
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Materials, Please try again!`)
        }
    }
    const getCatalougeUnits = async () => {

        let response = await catalogueHandler.getUnitsHandler()

        if (response && response.success) {

            let units = response.data || []

            units = units.map((item) => {
                return {
                    value: item.sno,
                    label: item.name,
                    ...item
                }
            })

            setCatalogueUnits(units)
            setCatalogueUnits_Orginal(units)
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Materials, Please try again!`)
        }
    }
    const getCatalougeAccessories = async () => {

        let response = await catalogueHandler.getAccessoriesHandler()

        if (response && response.success) {

            let items = response.data || []

            items = items.map((item) => {
                return {
                    value: item.sno,
                    label: item.name,
                    orginal_rate: item.rate,
                    price: item.rate,
                    ...item
                }
            })

            if (detials && detials.section_accessories) {

                let { section_accessories } = detials

                items = items.map((item) => {
                    let _item = section_accessories.find((accessory) => accessory.sno == item.sno)
                    if (_item) {
                        item.selected = true
                        item.quandity = _item.quandity
                        item.price = _item.price
                        item.discount = _item.discount
                        item.amount = _item.amount
                    }
                    return item
                })
            }


            setCatalogueAccessories(items)
            setCatalogueAccessories_Orginal(items)
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Catalogue Materials, Please try again!`)
        }
    }

    const HandlSectionAccesoriesInputChange = (value, index, key) => {

        let _CatalogueAccessories = [...CatalogueAccessories]

        let calculate_amount = (quandity, price, discount) => {

            let amount = Number(quandity || 0) * Number(price || 0)
            let discount_amount = (amount * Number(discount || 0)) / 100
            return amount - discount_amount
        }

        _CatalogueAccessories[index][key] = value
        _CatalogueAccessories[index]['amount'] = calculate_amount(_CatalogueAccessories[index].quandity, _CatalogueAccessories[index].price, _CatalogueAccessories[index].discount)

        setCatalogueAccessories(_CatalogueAccessories)
    }

    useEffect(() => {

        if (type == 'edit' && detials) {

            console.log(detials, 'detials');

            let { id, section_id, section_name, quality_id, quality_name, section_units, notes, work_pattern } = detials

            setId(id)
            setSection_id(section_id)
            setSection_name(section_name)
            setQuality_id(quality_id)
            setQuality_name(quality_name)
            setNotes(notes)
            setWorkPattern(work_pattern)
            setSectionUnits(section_units)



        }

        getCatalougeQualities()
        getCatalougeUnits()
        getCatalougeAccessories()
    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Catalogue Materials",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <form
                    className={`side-popup-container ${ActiveStepperIdx != 0 ? 'side-task-popup-container' : 'create-payment_history-main'}`}
                    onSubmit={HandlePopupSubmit}>
                    <div className="side-popup-header">
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.work }}
                                ></div>
                                <div className="label">{Heading}</div>
                            </div>
                        </div>
                        <div
                            className="header-item-close"
                            onClick={(e) => HandleCancel(e)}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>
                    <div className="clients-popup-content">

                        {ActiveStepperIdx == 0 ?
                            <div className="quotation-section-select-main">
                                <div className="quotation-section-items">
                                    {SectionOptions.map((item, index) => (
                                        <div
                                            key={`quotation-section-item-${index}`}
                                            className={`quotation-section-item ${Section_id == item.value ? 'quotation-section-item-active' : ''}`}
                                            onClick={() => HandeSelectSection(item)}
                                        >
                                            <img src={item.image} className="image" />
                                            <div className="label">{item.label}</div>
                                        </div>
                                    ))}
                                </div>

                            </div>

                            : ''}
                        {ActiveStepperIdx == 1 ?
                            <div className="quotation-section-inputs-main">

                                <div className="quotation-section-inputs">

                                    <div className="content-items">
                                        <div className="quotation-section-input">
                                            <TextInput
                                                props={{
                                                    id: "quotation-inpus-section_name",
                                                    value: Section_name,
                                                    placeholder: '',
                                                    setValue: (value) => setSection_name(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Selected Section",
                                                }}
                                            />
                                        </div>
                                        <div className="quotation-section-input">
                                            <SelectInput
                                                props={{
                                                    id: "quotation-inpus-quality",
                                                    value: Quality_id,
                                                    placeholder: '',
                                                    options: CatalogueQualites || [],
                                                    setValue: (value, label) => {
                                                        setQuality_id(value)
                                                        setQuality_name(label)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Choose Quality",
                                                }}
                                            />
                                        </div>
                                    </div>


                                </div>

                                {Section_id == 'accessories' ?
                                    <div className="measurement-accessories-container-main">
                                        <div className="measurement-accessories-container-items">
                                            {CatalogueAccessories.map((item, index) => (
                                                <div className={`measurement-accessories-item ${item.selected ? 'accesories-item-selected' : ''}`}>
                                                    <div className="accesories-item-left">
                                                        <div className="accesories-item-selection">
                                                            <RadioInput props={{
                                                                value: item.selected,
                                                                isIcon: false,
                                                                icon: "",
                                                                inputType: "checkbox",
                                                                name: "accesories-inputs-select-input",
                                                                setValue: (value) => HandlSectionAccesoriesInputChange(value, index, 'selected'),
                                                            }} />
                                                        </div>
                                                        <div className="accesories-item-image">
                                                            <img src={Utils.loadBase64_Image(item.attachment)} />
                                                        </div>
                                                        <div className="accesories-details">
                                                            <div className="accesories-item-name">{item.name}</div>
                                                            <div className="accesories-item-desc">{item.brand}</div>
                                                        </div>
                                                    </div>
                                                    <div className="accesories-item-right">
                                                        <div className="accesories-item-quantity">{item.brand}</div>
                                                        <div className="accesories-item-price">₹{item.rate}</div>
                                                        {item.selected ?
                                                            <div className="accesories-inputs">
                                                                <div className="accesories-inputs-item">
                                                                    <TextInput
                                                                        props={{
                                                                            id: "accesories-inputs-quandity",
                                                                            value: item.quandity,
                                                                            placeholder: '',
                                                                            setValue: (value) => HandlSectionAccesoriesInputChange(value, index, 'quandity'),
                                                                            isIcon: false,
                                                                            isLabel: true,
                                                                            isRequired: true,
                                                                            label: "Quandity",
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="accesories-inputs-item">
                                                                    <TextInput
                                                                        props={{
                                                                            id: "accesories-inputs-mrp",
                                                                            value: item.price,
                                                                            placeholder: '',
                                                                            setValue: (value) => HandlSectionAccesoriesInputChange(value, index, 'price'),
                                                                            isIcon: false,
                                                                            isLabel: true,
                                                                            isRequired: true,
                                                                            label: "MRP(₹)",
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="accesories-inputs-item">
                                                                    <TextInput
                                                                        props={{
                                                                            id: "accesories-inputs-discount",
                                                                            value: item.discount,
                                                                            placeholder: '',
                                                                            setValue: (value) => HandlSectionAccesoriesInputChange(value, index, 'discount'),
                                                                            isIcon: false,
                                                                            isLabel: true,
                                                                            isRequired: false,
                                                                            label: "Discount (%)",
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="accesories-inputs-item">
                                                                    <TextInput
                                                                        props={{
                                                                            id: "accesories-inputs-amount",
                                                                            value: item.amount,
                                                                            placeholder: '',
                                                                            setValue: (value) => { },
                                                                            isIcon: false,
                                                                            isLabel: true,
                                                                            readOnly: true,
                                                                            isRequired: true,
                                                                            label: "Amount (₹)",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    :
                                    <div className="quotation-units-main">
                                        <div className="payment-splits-main quotation-section-units-main">
                                            <div className="payment-splits-header">
                                                <div className="header-item-name">Unit</div>
                                                <div className="header-item-milestone">Length</div>
                                                <div className="header-item-milestone">Height</div>
                                                <div className="header-item-milestone">Depth</div>
                                                <div className="header-item-milestone">Area</div>
                                                <div className="header-item-milestone">Rate</div>
                                                <div className="header-item-milestone">Amount</div>
                                                <div className="header-item-action">Action</div>
                                            </div>
                                            <div className="payment-splits-content">
                                                {SectionUnits.map((item, index) => (
                                                    <div className="payment-content-items">
                                                        <div className="payment-content-name">

                                                            <SelectInput
                                                                props={{
                                                                    id: `quotation-input-unit-${item.id}`,
                                                                    value: item.unit_id,
                                                                    placeholder: '',
                                                                    options: CatalogueUnits || [],
                                                                    setValue: (value, label) => {
                                                                        HandlSectionUnitInputChange(value, index, 'unit_id')
                                                                    },
                                                                    isIcon: false,
                                                                    isLabel: false,
                                                                    isRequired: true,
                                                                    alsoTypeValue: true,
                                                                    label: "",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="payment-content-milestone">
                                                            <TextInput
                                                                props={{
                                                                    id: `quotation-input-length-${item.id}`,
                                                                    value: item.length,
                                                                    placeholder: '',
                                                                    setValue: (value) => HandlSectionUnitInputChange(value, index, 'length'),
                                                                    isIcon: false,
                                                                    type: 'number',
                                                                    isLabel: false,
                                                                    isRequired: true,
                                                                    label: "",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="payment-content-milestone">
                                                            <TextInput
                                                                props={{
                                                                    id: `quotation-input-height-${item.id}`,
                                                                    value: item.height,
                                                                    placeholder: '',
                                                                    setValue: (value) => HandlSectionUnitInputChange(value, index, 'height'),
                                                                    isIcon: false,
                                                                    type: 'number',
                                                                    isLabel: false,
                                                                    isRequired: true,
                                                                    label: "",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="payment-content-milestone">
                                                            <TextInput
                                                                props={{
                                                                    id: `quotation-input-depth-${item.id}`,
                                                                    value: item.depth,
                                                                    placeholder: '',
                                                                    setValue: (value) => HandlSectionUnitInputChange(value, index, 'depth'),
                                                                    isIcon: false,
                                                                    type: 'number',
                                                                    isLabel: false,
                                                                    isRequired: false,
                                                                    label: "",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="payment-content-milestone">
                                                            <TextInput
                                                                props={{
                                                                    id: `quotation-input-area-${item.id}`,
                                                                    value: item.area,
                                                                    placeholder: '',
                                                                    setValue: (value) => { },
                                                                    isIcon: false,
                                                                    readOnly: true,
                                                                    type: 'number',
                                                                    isLabel: false,
                                                                    isRequired: true,
                                                                    label: "",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="payment-content-milestone">
                                                            <TextInput
                                                                props={{
                                                                    id: `quotation-input-rate-${item.id}`,
                                                                    value: item.rate,
                                                                    placeholder: '',
                                                                    setValue: (value) => HandlSectionUnitInputChange(value, index, 'rate'),
                                                                    isIcon: false,
                                                                    type: 'number',
                                                                    isLabel: false,
                                                                    isRequired: true,
                                                                    label: "",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="payment-content-milestone">
                                                            <TextInput
                                                                props={{
                                                                    id: `quotation-input-amount-${item.id}`,
                                                                    value: item.amount,
                                                                    placeholder: '',
                                                                    setValue: (value) => { },
                                                                    isIcon: false,
                                                                    readOnly: true,
                                                                    type: 'number',
                                                                    isLabel: false,
                                                                    isRequired: true,
                                                                    label: "",
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="payment-content-action">
                                                            <div
                                                                className="action-remove"
                                                                onClick={(e) => HandlSectionUnitRemove(index)}
                                                            >
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div
                                                className="payment-splits-action-buttons"
                                            >
                                                <div
                                                    className="add-button"
                                                    onClick={(e) => HandlSectionUnitAddNew()}
                                                >
                                                    <div
                                                        className="icon"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_active }}
                                                    ></div>
                                                    <div className="label">Add new</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                            : ''}
                        {ActiveStepperIdx == 2 ?
                            <div className="quotation-section-terms-main">
                                <div className="quotation-section-term-item">

                                    <div className="content-item-body">
                                        <div className="head">
                                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                            <div className="label">Notes</div>
                                        </div>
                                        <div
                                            className="item-expanded"
                                            style={{
                                                minHeight: '200px'
                                            }}
                                        >
                                            <ReactQuill
                                                theme="snow"
                                                value={Notes}
                                                onChange={(value) => setNotes(value)}
                                                style={{
                                                    height: '150px',
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="quotation-section-term-item">

                                    <div className="content-item-body">
                                        <div className="head">
                                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                            <div className="label">Work Pattern</div>
                                        </div>
                                        <div
                                            className="item-expanded"
                                            style={{
                                                minHeight: '200px'
                                            }}
                                        >
                                            <ReactQuill
                                                theme="snow"
                                                value={WorkPattern}
                                                onChange={(value) => setWorkPattern(value)}
                                                style={{
                                                    height: '150px',
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>

                            : ''}
                    </div>
                    <div className="sidebar-popup-footer">
                        <div className="footer-item action-items">
                            <div className="action-preview">
                            </div>
                            <div className='action-btns'>
                                <div className="action-cancel" onClick={(e) => HandleBackBtn(ActiveStepperIdx)}>
                                    {ActiveStepperIdx == 0 ? 'Cancel' : 'Back'}
                                </div>
                                <button
                                    className={`action-btn`}
                                >
                                    {ActiveStepperIdx == 2 ? 'Save' : 'Next'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}


export default CreateQuoteMeasurement;