import React, { useEffect, useState } from 'react'

/* Redux Setup*/
import { Link, useNavigate, Route, Routes, BrowserRouter, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import StoreHandler from './redux/StoreHandler'
import UserAction from './redux/action/userAction'

import './assets/css/index.css'

import ReverseProxy from './config/reverseProxy'
import Signup from './Pages/login/signup'
import VerifyRegisteration from './Pages/login/verifyregisteration'
import Login from './Pages/login/login'
import Forgetpassword from './Pages/login/forgetpassword'
import ResetPassword from './Pages/login/resetpassword'
import VerifyResetPassword from './Pages/login/verifyresetpassword'

import AuthTokenValidation from './Handlers/Authendication/AuthTokenValidation'

import Icons from './assets/Icons'
import Images from './assets/Images'
import Builder from './Pages/builder'

import PWAInstallPopup from './components/PWAInstallPopup'


const App = () => {

  const store = useSelector((store) => store)
  const dispatch = useDispatch()
  const { updateState } = new UserAction

  const navigate = useNavigate()
  const authToken = localStorage.getItem('authToken')
  const [preloaderStatus, setPreloaderStatus] = useState(true)

  const storeHandler = new StoreHandler({
    dataStack: ['builder'],
    store: store,
    dispatch: dispatch,
    updateState: updateState,
  })

  const LoadStoreData = async () => {
    let resolved = await storeHandler.init()
  }

  useEffect(() => {

    const builder_detials = store.user.builder
    const currentURL = window.location.pathname;
    let currentPath = String(currentURL).split('/')
    currentPath = currentPath[currentPath.length - 1]

    if (builder_detials && Object.keys(builder_detials).length > 0) {

      if (currentPath == 'app') navigate(`${ReverseProxy['proxyUrl']}/app/builder/dashboard`)
    }
  }, [store])


  useEffect(() => {
    LoadStoreData()
  }, [])

  return (

    <div className='project-container-main'>
      <Routes >
        <Route exact path='/signin' element={<Login />}></Route>
        <Route exact path='/signup' element={<Signup />}></Route>
        <Route exact path='/verify-registeration' element={<VerifyRegisteration />}></Route>
        <Route exact path='/forget-password' element={<Forgetpassword />}></Route>
        <Route exact path='/verify-reset-password' element={<VerifyResetPassword />}></Route>
        <Route exact path='/reset-password' element={<ResetPassword />}></Route>
      </Routes>

      <AuthTokenValidation />

      {store.user.isAuthenticated ?
        <div className='project-main-container'>
          <PWAInstallPopup />
          <Routes >
            <Route exact path='/builder/*' element={<Builder />}></Route>
          </Routes>
        </div>
        : ''}

    </div>
  )
}

export default App;
